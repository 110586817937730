import React, { useEffect, useState } from "react";
import TimeService from "../../services/time";
import FormatService from "../../services/format";

const EmploySchedule = (props) => {
  const { schedule, customerInfo } = props;

  const [appointmentsToday, setAppointmentsToday] = useState([]);
  const [appointmentsTomorrow, setAppointmentsTomorrow] = useState([]);

  // console.log("customerInfo", customerInfo);

  useEffect(() => {
    let todaysDate = new Date();
    let tomorrowsDate = TimeService.addTime(new Date(), 1);
    let today = [];
    schedule.sort((a, b) => TimeService.isBefore(b.dateTime, a.dateTime));
    let tomorrow = [];
    for (let i in schedule) {
      let cur = schedule[i];
      if (!cur.canceled && !cur.completed) {
        let curDate = new Date(cur.dateTime);
        if (TimeService.isSameDay(curDate, todaysDate)) {
          today.push(cur);
        }
        if (TimeService.isSameDay(curDate, tomorrowsDate)) {
          tomorrow.push(cur);
        }
      }
    }
    // console.log("today", today, "tomorrow", tomorrow);
    setAppointmentsToday(today);
    setAppointmentsTomorrow(tomorrow);
  }, [schedule]);

  // console.log(appointmentsTomorrow);

  return (
    <div className="font-inter pb-10">
      <p className="text-lg font-semibold ml-8">Today's Schedule</p>
      <ol className="space-y-2">
        {appointmentsToday.map((val) => {
          let curProp;

          if (val.clientId in customerInfo) {
            for (let i in customerInfo[val.clientId].property) {
              let cur = customerInfo[val.clientId].property[i];
              if (cur._id === val.propId) {
                curProp = cur;
              }
            }
          } else {
            return null
          }
          

          return (
            <li className="rounded flex flex-col sm:flex-row justify-between items-center border-2 border-solid border-gray-200 p-1 mx-5 sm:mx-10">
              <div>
                <p className="text-sm">
                  {FormatService.formatAddress(curProp)},{" "}
                  {customerInfo[val.clientId].name}
                </p>
                <p> {FormatService.getTimefromDate(val.dateTime)}</p>
              </div>
            </li>
          );
        })}
      </ol>
      {appointmentsToday.length === 0 ? (
        <p className="m-3 mx-5 sm:mx-10">No Appointments</p>
      ) : null}
      <p className="text-lg font-semibold ml-8">Tomorrow's Schedule</p>
      <ol className="space-y-2">
        {appointmentsTomorrow.filter((val) => {
          if (val.clientId in customerInfo) {
            return true
          } else {
            return false
          }

        }).map((val) => {
          let curProp;
          
          for (let i in customerInfo[val.clientId].property) {
            let cur = customerInfo[val.clientId].property[i];
            if (cur._id === val.propId) {
              curProp = cur;
            }
          }

          return (
            <li className="rounded flex flex-col sm:flex-row justify-between items-center border-2 border-solid border-gray-200 p-1 mx-5 sm:mx-10">
              <div>
                <p className="text-sm">
                  {FormatService.formatAddress(curProp)},{" "}
                  {customerInfo[val.clientId].name}
                </p>
                <p> {FormatService.getTimefromDate(val.dateTime)}</p>
              </div>
            </li>
          );
        })}
      </ol>
      {appointmentsTomorrow.length === 0 ? (
        <p className="m-3 mx-5 sm:mx-10">No Appointments</p>
      ) : null}
    </div>
  );
};

export default EmploySchedule;
