import React, { useState, useEffect } from "react";
import ProfileCalendar from "../Profile/ProfileCalendar";
import AppointmentViewer from "../Profile/AppointmentViewer";
import Scheduler from "../Scheduler/Scheduler";
import AppointmentEditor from "../Profile/AppointmentEditor";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { CalendarIcon, ClipboardListIcon  } from "@heroicons/react/outline";
// import {  } from "@heroicons/react/solid";

const RightPanel = ({
  switcherRight,
  currentProperty,
  currentAppointment,
  user,
  setSwitcherRight,
}) => {
  return (
    <div className="flex flex-col sm:w-3/5 sm:mx-0 mx-7 text-left bg-white rounded shadow-lg my-8 pb-12">
      <div className="flex flex-row justify-around">
        <button
          onClick={() => setSwitcherRight("scheduler")}
          className={
            (switcherRight === "scheduler" ? "bg-white" : "bg-gray-200") +
            " focus:outline-none w-1/2 rounded-t-lg py-3 font-bold text-xl font-inter text-dark"
          }
        >
          Scheduler
        </button>
        <button
          onClick={() => setSwitcherRight("editor")}
          className={
            (switcherRight === "editor" ? "bg-white" : "bg-gray-200") +
            " focus:outline-none w-1/2 py-3 rounded-t-lg font-bold text-xl font-inter text-dark"
          }
        >
          Appointment View
        </button>
      </div>

      <div className="p-4 lg:p-6">
        {switcherRight === "scheduler" ? (
          <Scheduler user={user} selected={currentProperty} type="customer"/>
        ) : (
          <AppointmentEditor
            currentAppointment={currentAppointment}
            user={user}
            currentProperty={currentProperty.value}
            type="customer"
          />
        )}
      </div>
    </div>
  );
};

const LeftPanel = ({
  switcherLeft,
  user,
  currentProperty,
  setSwitcherLeft,
  appointments,
  selectAppointment,
  currentAppointment,
}) => {
  return (
    <div className="flex flex-col sm:w-2/5 sm:text-left sm:mx-0 mx-7">
      <div className="">
        <div className="bg-white rounded shadow-lg my-8 flex flex-col justify-center">
          <div className="flex flex-row bg-white rounded-t-md">
            <button
              onClick={() => setSwitcherLeft("calendar")}
              className={
                (switcherLeft === "calendar" ? "bg-white" : "bg-gray-200") +
                " focus:outline-none py-2 px-5 w-1/2 rounded-t-lg font-bold text-xl font-inter text-dark flex justify-center items-center space-x-2"
              }
            >
              <CalendarIcon className="h-8 text-dark" />
              <p>Calendar</p>
            </button>
            <button
              onClick={() => setSwitcherLeft("viewer")}
              className={
                (switcherLeft === "viewer" ? "bg-white" : "bg-gray-200") +
                " focus:outline-none py-2 px-5 w-1/2 rounded-t-lg font-bold text-xl font-inter text-dark flex justify-center items-center sm:space-x-2"
              }
            >
              <ClipboardListIcon className="h-8 text-dark" />
              <p>Appointments</p>
            </button>
          </div>

          {switcherLeft === "calendar" ? (
            <ProfileCalendar
              selected={appointments}
              setCurrent={selectAppointment}
              current={currentAppointment}
            />
          ) : (
            <div className="mt-5">
              <AppointmentViewer
                user={user}
                currentProperty={currentProperty}
                appointments={appointments}
                setCurrent={selectAppointment}
                currentSelected={currentAppointment}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AppointmentWrapper = (props) => {
  const { user, currentProperty, appointments } = props;

  const [currentAppointment, setCurrentAppointment] = useState();

  const [switcherRight, setSwitcherRight] = useState("scheduler");
  const [switcherLeft, setSwitcherLeft] = useState("viewer");

  const { propertyId, appointmentId } = useParams();

  useEffect(() => {
    if (appointmentId && propertyId) {
      for (let i in appointments) {
        if (appointments[i]._id == appointmentId) {
          setCurrentAppointment(appointments[i]);
        }
      }
      setSwitcherRight("editor");
    }
  }, []);

  const history = useHistory();

  const selectAppointment = (appt) => {
    setCurrentAppointment(appt);
    setSwitcherRight("editor");
    let appointmentString = appt._id;
    history.push({ pathname: `/overview/${propertyId}/${appointmentString}` });
  };

  return (
    <div className="container flex min-w-full">
      <div className="flex sm:justify-between sm:flex-row flex-col-reverse w-full sm:mx-7 sm:space-x-3">
        <LeftPanel
          switcherLeft={switcherLeft}
          currentAppointment={currentAppointment}
          selectAppointment={selectAppointment}
          user={user}
          currentProperty={currentProperty}
          setSwitcherLeft={setSwitcherLeft}
          appointments={appointments}
        />

        <RightPanel
          switcherRight={switcherRight}
          currentAppointment={currentAppointment}
          currentProperty={currentProperty}
          user={user}
          setSwitcherRight={setSwitcherRight}
        />
      </div>
    </div>
  );
};

export default AppointmentWrapper;
