import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import ReportList from "../../components/ReportList/ReportList";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import logo from "../../assets/logo.png";
import FormatService from "../../services/format";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import ReportTaskDisplay from "../../components/ReportTaskDisplay/ReportTaskDisplay";
import PropertyDataService from "../../services/property";

const createTaskSummary = (taskList) => {
  let comp = 0;
  let notComp = 0;
  let needsAtt = 0;
  for (let i in taskList) {
    switch (taskList[i].result) {
      case "completed":
        comp += 1;
        break;
      case "unable":
        notComp += 1;
        break;
      case "attention":
        needsAtt += 1;
        break;
    }
  }
  if (notComp === 0 && needsAtt === 0) {
    return (
      <p className="flex flex-row items-center">
        {comp} <CheckCircleIcon className="h-5 text-green-500 ml-2" />{" "}
      </p>
    );
  } else {
    let final;
    if (notComp > 0 && needsAtt > 0) {
      return (
        <div className="flex flex-row space-x-3">
          <p className="flex flex-row items-center">
            {notComp}
            <XCircleIcon className="h-5 text-red ml-2" />{" "}
          </p>
          <p className="flex flex-row items-center">
            {needsAtt}
            <ExclamationCircleIcon className="h-5 text-yellow-500 ml-2" />{" "}
          </p>
        </div>
      );
    }
    if (needsAtt > 0) {
      return (
        <div>
          <p className="flex flex-row items-center">
            {needsAtt}
            <ExclamationCircleIcon className="h-5 text-yellow-500 ml-2" />{" "}
          </p>
        </div>
      );
    }
    return (
      <div>
        <p className="flex flex-row items-center">
          {notComp}
          <XCircleIcon className="h-5 text-red ml-2" />{" "}
        </p>
      </div>
    );
  }
};

const PropertyCheckConfirmation = (props) => {
  const { user } = props;
  const { appointmentId, customerId } = useParams();
  const [report, setReport] = useState([]);
  const [property, setProperty] = useState({});

  const location = useLocation();
  const { reportData, propertyData } = location.state;

  const history = useHistory();

  console.log(propertyData);

  useEffect(() => {
    if (reportData) {
      setReport(reportData);
      setProperty(propertyData);
    }
  }, [reportData]);

  const goBack = () => {
    history.push({ pathname: `/home` });
  };

  const details = (
    <>
      <p className="ml-10 sm:ml-20 mb-2 sm:mb-5 font-bold text-xl text-dark font-inter">
        Details
      </p>
      <div className="hidden ml-10 sm:ml-20 sm:flex flex-row space-x-2 sm:space-x-5">
        <div className="text-right text-gray-600">
          <p className="font-inter sm:text-lg font-semibold">Address: </p>
          <p className="font-inter sm:text-lg font-semibold">Completed by: </p>
          <p className="font-inter sm:text-lg font-semibold">Completed at: </p>
        </div>
        <div className="text-left font-inter text-lg font-semibold">
          <p className="">{FormatService.formatAddress(property)} </p>
          <p className="">{report.completedBy} </p>
          <p className="">
            {FormatService.getFormatedDateTime(report.completedDateTime)}
          </p>
        </div>
      </div>
      <div className=" ml-10 sm:hidden space-y-3">
        <div>
          <p className="font-inter text-gray-500 text-sm sm:text-lg font-semibold">
            Address
          </p>
          <p className="font-inter text-dark text-lg">
            {FormatService.formatAddress(property)}
          </p>
        </div>
        <div>
          <p className="font-inter text-gray-500 text-sm sm:text-lg font-semibold">
            Completed by
          </p>
          <p className="font-inter text-dark text-lg">{report.completedBy} </p>
        </div>
        <div>
          <p className="font-inter text-gray-500 text-sm sm:text-lg font-semibold">
            Completed at
          </p>
          <p className="font-inter text-dark text-lg">
            {FormatService.getFormatedDateTime(report.completedDateTime)}
          </p>
        </div>
      </div>
    </>
  );

  const summary = (
    <>
      <p className="ml-10 sm:ml-20 mb-2 mt-5 sm:mt-0 sm:mb-5 font-bold text-xl text-dark font-inter">
        Task Summary
      </p>
      <div className="hidden ml-20 sm:flex flex-row space-x-5">
        <div className="text-right text-gray-600">
          <p className="font-inter text-lg font-semibold">General: </p>
          <p className="font-inter text-lg font-semibold">Property: </p>
          <p className="font-inter text-lg font-semibold">Appointment: </p>
        </div>
        {report.tasks ? (
          <div className="text-left font-inter text-lg font-semibold">
            <p className="">{createTaskSummary(report.tasks.general)} </p>
            <p className="">{createTaskSummary(report.tasks.property)}</p>
            <p className="">{createTaskSummary(report.tasks.appointment)}</p>
          </div>
        ) : null}
      </div>
      {report.tasks ? (
        <div className=" ml-10 sm:hidden space-y-3">
          {report.tasks.general.length > 0 ? (
            <div>
              <p className="font-inter text-gray-500 text-sm font-semibold">
                General
              </p>
              <p className="font-inter text-dark text-lg">
                {createTaskSummary(report.tasks.general)}
              </p>
            </div>
          ) : null}
          {report.tasks.property.length > 0 ? (
            <div>
              <p className="font-inter text-gray-500 text-sm font-semibold">
                Property
              </p>
              <p className="font-inter text-dark text-lg">
                {createTaskSummary(report.tasks.property)}
              </p>
            </div>
          ) : null}
          {report.tasks.appointment.length > 0 ? (
            <div>
              <p className="font-inter text-gray-500 text-sm font-semibold">
                Appointment
              </p>
              <p className="font-inter text-dark text-lg">
                {createTaskSummary(report.tasks.appointment)}
              </p>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex sm:justify-center ml-5">
        <div className="sm:w-3/4 mt-10">
          <button
            onClick={() => goBack()}
            className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold flex flex-row items-center"
          >
            <ArrowCircleLeftIcon className="h-5 mr-2" />
            Back to Home
          </button>
        </div>
      </div>

      <div className="flex sm:justify-center">
          <p className="font-inter font-semibold sm:text-xl text-lg text-dark ml-5 sm:ml-0">Customer View</p>
      </div>

      <div className="flex justify-center mx-5">
        <div className="flex flex-col sm:w-3/4 max-w-full text-left bg-white rounded shadow-lg my-8 pb-12">
          <div className="flex justify-center pt-5">
            <img src={logo} alt={"logo"} className=" h-20 relative" />
          </div>
          <p className="m-10 mb-5 sm:mb-10 sm:ml-20 font-bold text-2xl text-dark font-inter">
            Property Report
          </p>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:w-1/2">{details}</div>
            <div className="sm:w-1/2">{summary}</div>
          </div>
          <div className="mt-10 sm:space-y-8">
            <ReportTaskDisplay
              title="Unable to Complete"
              tasks={report.tasks}
              type="unable"
            />
            <ReportTaskDisplay
              title="Needs Attention"
              tasks={report.tasks}
              type="attention"
            />
            <ReportTaskDisplay
              title="Completed"
              tasks={report.tasks}
              type="completed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCheckConfirmation;
