import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/outline";

const PaymentError = (props) => {
  const { details, user } = props;
  const { errorCardDescription, error } = details;
  console.log(details);

  return (
    <div className="bg-white mt-10 rounded-lg p-10">
      <div className="m-5 flex flex-row space-x-3 items-center">
        <ExclamationIcon className="h-8 text-yellow-500" />
        <h2 className="text-2xl font-semibold">Payment Error</h2>
      </div>

      <div className="m-5 text-lg">
        <p className="text-lg">
          Please call{" "}
          <span className="text-xl font-semibold tracking-wide">
            +1(928)920-4635
          </span>{" "}
          to resolve.
        </p>

        <div className="mt-5 text-base">
          <p>
            Error: <span>{error}</span>,{' '}
            <span className="text-dark font-semibold text-lg">{errorCardDescription.brand}</span> ending in{" "}
            <span className="shadow-sm p-1 bg-gray-100 text-dark tracking-wide font-semibold text-lg">{errorCardDescription.last4}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentError;
