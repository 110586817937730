import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import ReportList from "../../components/ReportList/ReportList";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import logo from "../../assets/logo.png";
import FormatService from "../../services/format";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import ReportTaskDisplay from "../../components/ReportTaskDisplay/ReportTaskDisplay";
import Images from "../../components/Images/Images";
import PropertyDataService from "../../services/property";

/*
This component is not in use, it is for editing the address of a property but 
we have changed and the property is defined by its address and therefore cannot change.
*/

const PropertyDetails = ({ property, user }) => {
  const { street, city, state, zip } = property;

  const [editStreet, setEditStreet] = useState("");
  const [editCity, setEditCity] = useState("");
  const [editState, setEditState] = useState("");
  const [editZip, setEditZip] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (street) {
      setEditStreet(street);
    }
    if (city) {
      setEditCity(city);
    }
    if (state) {
      setEditState(state);
    }
    if (zip) {
      setEditZip(zip);
    }
  }, [street, city, state, zip]);

  const [editable, setEditable] = useState(false);

  const hasChanged = () => {
    if (
      street !== editStreet ||
      city !== editCity ||
      state !== editState ||
      zip !== editZip
    ) {
      return true;
    }
    return false;
  };

  const saveDetails = () => {
    if (!hasChanged()) {
      setEditable(false);
      return;
    }

    const details = {
      street: editStreet,
      city: editCity,
      state: editState,
      zip: editZip,
    };

    let updated = new Date();
    const data = {
      email: user.email,
      propertyId: property._id,
      updated: updated,
      type: "details",
      details: details,
    };

    setLoading(true);
    PropertyDataService.editProperty(data)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const cancel = () => {
    if (street) {
      setEditStreet(street);
    }
    if (city) {
      setEditCity(city);
    }
    if (state) {
      setEditState(state);
    }
    if (zip) {
      setEditZip(zip);
    }
    setEditable(false);
  };

  return (
    <div className="">
      <div className="p-2 m-5 ml-20 flex flex-row space-x-5 items-center">
        <p className="font-inter text-2xl text-dark font-semibold">
          Customer Details
        </p>
      </div>
      <div className="flex flex-col lg:flex-row lg:space-x-5 ml-20 space-y-3 lg:space-y-0 ">
        <div className="flex flex-row space-x-2">
          <p className="font-inter text-gray-500 text-right text-xl">Name:</p>
          <p className="font-inter text-dark text-lg">{user.name}</p>
        </div>
        <div className="flex flex-row space-x-2">
          <p className="font-inter text-gray-500 text-right text-xl">Email:</p>
          <p className="font-inter text-dark text-lg">{user.email}</p>
        </div>
        <div className="flex flex-row space-x-2">
          <p className="font-inter text-gray-500 text-right text-xl">Phone:</p>
          <p className="font-inter text-dark text-lg">{user.phone}</p>
        </div>
      </div>
      <div className="p-2 m-5 ml-20 flex flex-row space-x-5 items-center">
        <p className="font-inter text-2xl text-dark font-semibold">
          Property Details
        </p>
        {editable ? (
          <>
            <button
              className="flex flex-row items-center bg-green-500 text-white font-semibold text-md
                                    px-1 rounded-md font-inter border-solid border-2 border-green-500 hover:bg-white hover:text-green-500 
                                    "
              onClick={() => saveDetails()}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
              ) : null}
              Save
            </button>
            <button
              className=" bg-red text-white font-semibold text-md
                                    px-1 rounded-md font-inter border-solid border-2 border-red hover:bg-white hover:text-red 
                                    "
              onClick={() => cancel()}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            className=" bg-white text-dark font-semibold text-md
                                      px-1 rounded-md font-inter border-solid border-2 border-dark hover:bg-dark hover:text-white 
                                      "
            onClick={() => setEditable(true)}
          >
            Edit
          </button>
        )}
      </div>
      <div className="flex flex-col lg:flex-row lg:space-x-5 ml-20 space-y-3 lg:space-y-0 ">
        <div className="flex flex-row space-x-2 items-center">
          <p className="font-inter text-gray-500 text-right text-xl">Street:</p>
          {editable ? (
            <textarea
              className="font-inter text-dark text-lg border-solid border-2 border-gray-200 resize-none p-1 rounded-md"
              onChange={(e) => setEditStreet(e.target.value)}
              rows={1}
              cols={18}
              placeholder="street"
              value={editStreet}
            />
          ) : (
            <p className="font-inter text-dark text-lg">{editStreet}</p>
          )}
        </div>
        <div className="flex flex-row space-x-2">
          <p className="font-inter text-gray-500 text-right text-xl">City:</p>
          {editable ? (
            <textarea
              className="font-inter text-dark text-lg border-solid border-2 border-gray-200 resize-none p-1 rounded-md"
              onChange={(e) => setEditCity(e.target.value)}
              rows={1}
              cols={18}
              placeholder="street"
              value={editCity}
            />
          ) : (
            <p className="font-inter text-dark text-lg">{editCity}</p>
          )}
        </div>
        <div className="flex flex-row space-x-2">
          <p className="font-inter text-gray-500 text-right text-xl">State:</p>
          {editable ? (
            <textarea
              className="font-inter text-dark text-lg border-solid border-2 border-gray-200 resize-none p-1 rounded-md"
              onChange={(e) => setEditState(e.target.value)}
              rows={1}
              cols={18}
              placeholder="street"
              value={editState}
            />
          ) : (
            <p className="font-inter text-dark text-lg">{editState}</p>
          )}
        </div>
        <div className="flex flex-row space-x-2">
          <p className="font-inter text-gray-500 text-right text-xl">Zip:</p>
          {editable ? (
            <textarea
              className="font-inter text-dark text-lg border-solid border-2 border-gray-200 resize-none p-1 rounded-md"
              onChange={(e) => setEditZip(e.target.value)}
              rows={1}
              cols={18}
              placeholder="street"
              value={editZip}
            />
          ) : (
            <p className="font-inter text-dark text-lg">{editZip}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
