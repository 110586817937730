import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import TaskCheck from "../TaskCheck/TaskCheck";

const TaskCheckList = (props) => {
  const { title, taskList, taskListName, onValueChange } = props;

  // const [completed, setCompleted] = useState(false);
  const [listDetails, setListDetails] = useState([]);
  const [showTasks, setShowTasks] = useState(true);
  let name = taskListName || "taskListName";

  useEffect(() => {
    let detailTracker = [];
    for (let i in taskList) {
      let taskDetails = {
        task: taskList[i],
        result: "",
        notes: "",
        time: "",
      };
      detailTracker.push(taskDetails);
    }
    setListDetails(detailTracker);
  }, [taskList]);

  const changeVal = (index, val) => {
    let temp = [...listDetails];
    temp[index] = val;
    setListDetails(temp);
    let completed = checkCompleted(temp);
    onValueChange(taskListName, { completed: completed, details: temp });
  };

  const checkCompleted = (list) => {
    for (let i in list) {
      let cur = list[i];
      if (!cur.result) {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <div className="flex flex-row items-center mb-5">
        <p className="font-bold text-dark text-lg sm:text-2xl ">
          {title || "Title"}
        </p>
        <button
          className="focus:outline-none"
          onClick={() => setShowTasks(!showTasks)}
        >
          {showTasks ? (
            <ChevronUpIcon className="h-10 text-gray-500 ml-2 hover:text-gray-700 " />
          ) : (
            <ChevronDownIcon className="h-10 text-gray-500 ml-2 hover:text-gray-700 " />
          )}
        </button>
      </div>

      {showTasks ? (
        <div className="flex flex-col space-y-5">
          {listDetails && listDetails.length > 0 ? (
            <>
              {listDetails.map((val, index) => {
                if (index + 1 === listDetails.length) {
                  return (
                    <TaskCheck
                      task={val.task}
                      index={index}
                      key={index}
                      listName={name}
                      changeVal={changeVal}
                    />
                  );
                } else {
                  return (
                    <>
                      <TaskCheck
                        task={val.task}
                        index={index}
                        key={index}
                        listName={name}
                        changeVal={changeVal}
                      />

                      <div className="flex sm:hidden justify-center mt-10 mb-5 mx-5 border-b-2 border-solid border-gray-300"></div>
                    </>
                  );
                }
              })}
            </>
          ) : (
            <p> Task List Empty </p>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TaskCheckList;
