const PricingSection = (props) => {
  return (
    <div className="mt-32">
      {/* Title */}
      <div className="flex justify-center text-center">
        <p className="font-semibold text-5xl font-inter  space-y-5">
          Simple, straight forward pricing.
        </p>
      </div>

      {/* Pricing Information */}
      <div className="bg-background font-inter">
        <div className="flex justify-center items-center flex-col text-background-dark py-16 my-10">

          {/*  */}
          <div className="p-3 rounded-lg">
            <p className="text-7xl md:text-9xl font-inter font-bold">
              <span className="text-6xl">$</span>40
              <span className="text-5xl">/visit</span>
            </p>
          </div>

          {/*  */}
          <div className="mt-4 text-lg mx-4 md:mx-0">
            <p className="mt-1 text-3xl font-inter font-bold">
              Charged after each visit.
            </p>
            <p>Other services booked are subject to their pricing.</p>
            <p>Property Watchers will negotiate for lowest price.</p>
          </div>


        </div>
      </div>
    </div>
  );
};

export default PricingSection;
