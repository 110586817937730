import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import ReportList from "../../components/ReportList/ReportList";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import logo from "../../assets/logo.png";
import FormatService from "../../services/format";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  PencilAltIcon,
  XIcon,
} from "@heroicons/react/outline";
import ReportTaskDisplay from "../../components/ReportTaskDisplay/ReportTaskDisplay";
import Images from "../../components/Images/Images";
import PropertyDataService from "../../services/property";
import PropertyDetails from "../PropertyProfile/PropertyDetails";

const PropertyTaskDisplay = ({ details, editable, remove, select }) => {
  const { task, taskNote, images } = details;

  return (
    <div className="flex flex-row border-solid border-2 border-gray-500 rounded-lg  p-2">
      <div className="w-2/3 flex flex-col">
        <div>
          <p className="font-inter text-gray-500 text-sm font-semibold">Task</p>
          <p className="font-inter text-dark text-lg">{task}</p>
        </div>
        {taskNote ? (
          <div>
            <p className="font-inter text-gray-500 text-sm font-semibold">Detail</p>
            <p className="font-inter text-dark text-lg">{taskNote}</p>
          </div>
        ) : null}
      </div>
      <div className="flex justify-center w-1/3">
        {images.length > 0 ? (
          <Images editable={false} presetImages={images} height={24} />
        ) : null}
      </div>
      {editable ? (
        <div className="flex flex-row">
          <button className="focus:outline-none">
            <PencilAltIcon
              className="h-8 text-dark hover:text-gray-400"
              onClick={() => select()}
            />
          </button>
          <button className="focus:outline-none" onClick={() => remove()}>
            <XIcon className="h-8 text-red hover:text-red-700" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

const Editor = ({ currentDetails, setCurrent, addTask }) => {
  const [newTask, setNewTask] = useState("");
  const [newTaskNote, setNewTaskNote] = useState("");

  const [newTaskImages, setNewTaskImages] = useState([]);
  const [readyToSave, setReadyToSave] = useState(true);

  const [taskError, setTaskError] = useState("");

  const discard = () => {
    setNewTaskImages([]);
    setNewTask("");
    setNewTaskNote("");
    setTaskError("");
    setCurrent({
      task: "",
      taskNote: "",
      images: [],
    });
  };

  useEffect(() => {
    if (currentDetails) {
      setNewTaskImages(currentDetails.images);
      setNewTask(currentDetails.task);
      setNewTaskNote(currentDetails.taskNote);
    }
  }, [currentDetails]);

  const saveTask = () => {
    if (!readyToSave) {
      setTaskError("Upload or discard unuploaded images before adding task");
      return;
    }
    if (newTask === "") {
      setTaskError("Must have a task name before adding");
      return;
    }

    let task = { task: newTask, taskNote: newTaskNote, images: newTaskImages };
    addTask(task);
    discard();
  };

  return (
    <div className="m-3 sm:mt-5 ">
      <p className="text-xl font-bold font-inter mb-2">Create task</p>
      <textarea
        rows={2}
        // cols={5}
        className="p-2 tracking-wide font-medium w-full rounded-md text-lg resize-none border-solid border-2 border-blue-400"
        onChange={(e) => setNewTask(e.target.value)}
        placeholder="Task Name"
        value={newTask}
      />
      <textarea
        rows={3}
        // cols={5}
        className="p-2 tracking-wide font-medium w-full rounded-md text-lg resize-none border-solid border-2 border-blue-400"
        onChange={(e) => setNewTaskNote(e.target.value)}
        placeholder="Task Details (optional)"
        value={newTaskNote}
      />
      <Images
        setUploadedImages={setNewTaskImages}
        changeReadiness={setReadyToSave}
        presetImages={newTaskImages}
        editable={true}
      />
      <div className="w-max-full flex flex-col justify-center">
        <div>
          {taskError ? (
            <div className=" my-5 mx-5 flex flex-row bg-white items-center w-2/3 justify-center rounded-lg">
              <ExclamationCircleIcon className="text-red-400 h-8" />
              <p className="p-2 text-red-400 font-inter font-semibold">
                {taskError}
              </p>
            </div>
          ) : null}
        </div>

        <div className="flex flex-row">
          <button
            className="flex flex-row bg-blue-400 text-white mt-3 font-bold text-lg 
                                  py-1 px-3 rounded-md items-center border-solid border-2 border-white hover:bg-white hover:text-blue-400 
                                  "
            onClick={saveTask}
          >
            Add Task
          </button>
          {newTask || newTaskNote || newTaskImages.length > 0 ? (
            <button
              className="flex flex-row bg-dark text-white mt-3 font-bold text-lg 
                                  py-1 px-3 rounded-md items-center border-solid border-2 border-white hover:bg-white hover:text-dark hover:border-dark 
                                  "
              onClick={() => discard()}
            >
              Discard
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const PropertyTaskEditor = ({ property, user }) => {
  const { tasks } = property;

  const [editable, setEditable] = useState(false);

  const [editTasks, setEditTasks] = useState([]);

  const [currentTask, setCurrentTask] = useState({
    task: "",
    taskNote: "",
    images: [],
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (tasks) {
      let temp = [...tasks];
      setEditTasks(temp);
    }
  }, [tasks]);

  const removeTask = (index) => {
    let temp = [...editTasks];
    temp.splice(index, 1);
    setEditTasks(temp);
    // saveTasks(temp);
  };

  const addTask = (val) => {
    let temp = [...editTasks];
    temp.push(val);
    setEditTasks(temp);
    // saveTasks(temp);
  };

  const checkCurrent = () => {
    if (
      currentTask.task ||
      currentTask.taskNote ||
      currentTask.images.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const selectTask = (index) => {
    if (checkCurrent()) {
      setError("Must add or discard current task before changing");
      return;
    }

    setCurrentTask(editTasks[index]);
    removeTask(index);
  };

  const taskChanged = (a, b) => {
    if (a.task !== b.task) {
      return true;
    }
    if (a.taskDetail !== b.taskDetail) {
      return true;
    }
    if (a.images.length !== b.images.length) {
      return true;
    }
    for (let i in a.images) {
      if (a.images[i] !== b.images[i]) {
        return true;
      }
    }
    return false;
  };

  const [loading, setLoading] = useState(false);

  const hasChanged = () => {
    if (tasks.length !== editTasks.length) {
      return true;
    }
    for (let i in tasks) {
      if (taskChanged(tasks[i], editTasks[i])) {
        return true;
      }
    }

    return false;
  };

  const saveDetails = () => {
    if (!hasChanged()) {
      setEditable(false);
      return;
    }

    if (checkCurrent()) {
      setError("Must add or discard current task before changing");
      return;
    }

    const details = {
      tasks: editTasks,
    };

    let updated = new Date();
    const data = {
      email: user.email,
      propertyId: property._id,
      updated: updated,
      type: "tasks",
      details: details,
    };

    setLoading(true);
    PropertyDataService.editProperty(data)
      .then((response) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const cancel = () => {
    if (tasks) {
      setEditTasks(tasks);
    } else {
      setEditTasks([]);
    }
    setError("");
    setEditable(false);
    setCurrentTask({
      task: "",
      taskNote: "",
      images: [],
    });
  };

  // <div className="m-5 ml-7 sm:ml-20">
  //       <p className="font-inter text-2xl text-dark font-semibold">
  //         Property Details
  //       </p>
  //     </div>

  return (
    <div className="">
      <div className="p-2 m-5 mb-0 sm:mb-5 ml-5 sm:ml-20 flex flex-row space-x-5 items-center">
        <p className="font-inter text-2xl text-dark font-semibold">
          Property Tasks
        </p>
        {editable ? (
          <>
            <button
              className="flex flex-row items-center bg-green-500 text-white font-semibold text-md
                                  px-1 rounded-md font-inter border-solid border-2 border-green-500 hover:bg-white hover:text-green-500 
                                  "
              onClick={() => saveDetails()}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
              ) : null}
              Save
            </button>
            <button
              className=" bg-red text-white font-semibold text-md
                                  px-1 rounded-md font-inter border-solid border-2 border-red hover:bg-white hover:text-red 
                                  "
              onClick={() => cancel()}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            className=" bg-white text-dark font-semibold text-md
                                  px-1 rounded-md font-inter border-solid border-2 border-dark hover:bg-dark hover:text-white 
                                  "
            onClick={() => setEditable(true)}
          >
            Edit
          </button>
        )}
        <p className="font-inter text-red ">{error}</p>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="sm:w-1/2 mx-7 sm:ml-20 space-y-5">
          {editTasks &&
            editTasks.map((val, index) => {
            
              return (
                <PropertyTaskDisplay
                  details={val}
                  editable={editable}
                  remove={() => removeTask(index)}
                  select={() => selectTask(index)}
                />
              );
            })}
        </div>
        <div className="sm:w-1/2 ml-7 sm:ml-5">
          {editable ? (
            <Editor
              currentDetails={currentTask}
              setCurrent={setCurrentTask}
              addTask={addTask}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};


const EmployPropertyProfile = (props) => {
  const { employ } = props;
  const { customerId, propertyId } = useParams();
  const [user, setUser] = useState({});

  const [property, setProperty] = useState({});


  const loadUser = () => {
    PropertyDataService.fetchUser({ id: customerId })
      .then((response) => {
        console.log("Fetching User Information");
        console.log(response.data.user)
        setUser(response.data.user);
        setProp(response.data.user);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadUser()
  }, [])

  const setProp = (user) => {
    for (let i in user.property) {
        let cur = user.property[i];
        if (cur._id == propertyId) {
          setProperty(cur);
        }
      }
  }


  const history = useHistory();

  const goBack = () => {
    history.push({ pathname: `/home/${customerId}` });
  };

  return (
    <div className="flex flex-col  min-h-screen bg-background">
      <div className="flex sm:justify-center">
        <div className="sm:w-3/4 ml-5 mt-10">
          <button
            onClick={() => goBack()}
            className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold flex flex-row items-center"
          >
            <ArrowCircleLeftIcon className="h-5 mr-2" />
            Back to Home
          </button>
        </div>
      </div>

      <div className="flex justify-center mx-5 sm:mx-0">{user && property ?  <div className="flex flex-col max-w-full sm:w-3/4 text-left bg-white sm:pr-10 rounded shadow-lg my-8 pb-12">
          <PropertyDetails property={property} user={user} />
          <PropertyTaskEditor property={property} user={user} />
        </div> : <p>Loading User...</p>}
       
      </div>
    </div>
  );
};

export default EmployPropertyProfile;
