import React from "react";
import {Disclosure, Transition} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/solid/esm";


const StyledDisclosure = (props) => {

    const {question, answer, show} = props;


    return (
        show ?
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex justify-between items-center w-full px-8 py-6 py-2 text-3xl font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">

                        <span>{question}</span>
                        <ChevronUpIcon
                            className={`${
                                open ? 'transform rotate-180' : ''
                            } w-14 h-14 text-blue-500`}
                        />
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >

                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            {answer}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure> : null
    )

};

export default StyledDisclosure;