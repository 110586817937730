import React, { useEffect, useState } from "react";
import RadioButton from "../RadioButton/RadioButton";
import { CheckIcon } from "@heroicons/react/solid";

const SchedulerContact = (props) => {
  const { setContact, presets, nonEditable } = props;

  const [editable, setEditable] = useState(true);

  useEffect(() => {
    if (presets) {
      setEmail(presets["email"]);
      setText(presets["text"]);
      setCall(presets["call"]);
    }
    if (nonEditable) {
      setEditable(false);
    } else {
      setEditable(true);
    }
  }, [nonEditable, presets]);

  const [email, setEmail] = useState(true);
  const [text, setText] = useState(false);
  const [call, setCall] = useState(false);

  const handleCheck = (e, type) => {
    switch (type) {
      case "email":
        setEmail(e);
        setContact({ email: e, text: text, call: call });
        break;
      case "text":
        setText(e);
        setContact({ email: email, text: e, call: call });
        break;
      case "call":
        setCall(e);
        setContact({ email: email, text: text, call: e });
        break;
    }
  };

  const CheckBox = ({ on }) => {
    return (
      <>
        {" "}
        {on ? (
          <CheckIcon className="text-red-700 h-6 border-solid border-2 border-dark" />
        ) : (
          <div className="h-6 border-solid border-2 border-dark w-6"></div>
        )}
      </>
    );
  };

  const active = () => {
    return (
      <div className="flex flex-col">
     
        <div className="sm:mx-5">
          <p className="font-inter text-lg">
            Do you want a <span className="font-bold">text</span> with a link to
            the report?
          </p>

          <div className="flex flex-col">
            {" "}
            <button
              className={
                "whitespace-nowrap focus:outline-none m-2 rounded-lg border-2 border-solid border-gray-300 hover:bg-gray-200 p-2 w-min" +
                (text ? " bg-gray-200" : "")
              }
              onClick={() => handleCheck(true, "text")}
            >
              <span className=" text-green-500 font-semibold">Yes</span>, I want
              to recieve a text message.
            </button>
            <button
              className={
                "whitespace-nowrap focus:outline-none m-2 rounded-lg border-2 border-solid border-gray-300 hover:bg-gray-200 p-2 w-min" +
                (!text ? " bg-gray-200" : "")
              }
              onClick={() => handleCheck(false, "text")}
            >
              <span className=" text-red-500 font-semibold">No</span> , do not
              text me.
            </button>
          </div>
        </div>

        <div className="sm:mx-5">
          <p className="font-inter text-lg">
            Do you want a <span className="font-bold">call</span> when we get to
            your property?
          </p>

          <div className="flex flex-col">
            <button
              className={
                "whitespace-nowrap focus:outline-none m-2 rounded-lg border-2 border-solid border-gray-300 hover:bg-gray-200 p-2 w-min" +
                (call ? " bg-gray-200" : "")
              }
              onClick={() => handleCheck(true, "call")}
            >
              <span className=" text-green-500 font-semibold whi">Yes</span>, I
              want to recieve a call.
            </button>

            <button
              className={
                "whitespace-nowrap focus:outline-none m-2 rounded-lg border-2 border-solid border-gray-300 hover:bg-gray-200 p-2 w-min" +
                (!call ? " bg-gray-200" : "")
              }
              onClick={() => handleCheck(false, "call")}
            >
              <span className=" text-red-500 font-semibold whi">No</span> , do
              not call.
            </button>
          </div>
        </div>
      </div>
    );
  };

  const nonActive = () => {
    return (
      <div className="flex flex-col">
        {/* <div className="mx-5 bg-gray-200 px-2 py-3 rounded-lg mb-3">
        <p className="font-inter ">
          We will always <span className="font-bold">email a confirmation</span> after the report is done and <span className="font-bold">call in case of an emergency</span>, please answer these additional contact questions below:
        </p>
        </div> */}

        <div className="sm:mx-5">
          <p className="font-inter text-lg">
            Do you want a <span className="font-bold">text</span> with a link to
            the report?
          </p>

          <div className="flex flex-col sm:flex-row">
            {text ? (
              <div
                className={
                  "sm:whitespace-nowrap focus:outline-none m-3 rounded-lg border-2 border-solid border-gray-300 p-2 sm:w-min" 
                 
                }
              >
                <span className=" text-green-500 font-semibold">Yes</span>, I
                want to recieve a text message.
              </div>
            ) : (
              <div
                className={
                  "sm:whitespace-nowrap focus:outline-none m-3 rounded-lg border-2 border-solid border-gray-300 p-2 sm:w-min" 
                 
                }
              >
                <span className=" text-red-500 font-semibold">No</span> , do not
                text me.
              </div>
            )}
          </div>
        </div>

        <div className="sm:mx-5">
          <p className="font-inter text-lg">
            Do you want a <span className="font-bold">call</span> when we get to
            your property?
          </p>

          <div className="flex flex-col sm:flex-row">
            {call ? (
              <div
                className={
                  "sm:whitespace-nowrap focus:outline-none m-3 rounded-lg border-2 border-solid border-gray-300 p-2 sm:w-min"
                }
              >
                <span className=" text-green-500 font-semibold whi">Yes</span>,
                I want to recieve a call.
              </div>
            ) : (
              <div
                className={
                  "sm:whitespace-nowrap focus:outline-none m-3 rounded-lg border-2 border-solid border-gray-300 p-2 sm:w-min"
                }
              >
                <span className=" text-red-500 font-semibold whi">No</span> , do
                not call.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <>{editable ? active() : nonActive()}</>;
};

export default SchedulerContact;
