import React from "react";

function LandingStep({ info }) {
  const { number, title, bullets } = info;
  return (
    <div className="lg:w-1/3">


      <div className="bg-white mx-10 px-10 py-5 rounded-2xl">

        <div className="flex flex-row items-center space-x-4 content-center">
          <div>
            <div className="w-10 h-10 md:w-20 md:h-20 rounded-full bg-gradient-to-r from-red to-red-700 flex justify-center items-center">
              <p className="font-bold text-xl md:text-4xl text-center text-white">
                {number}
              </p>
            </div>
          </div>
          <p className="text-xl md:text-3xl font-medium text-center ">
            {title}
          </p>
        </div>

        <div className="flex flex-col leading-relaxed mt-5">
          <ul className="space-y-3">
            {bullets.map((bullet, index) => (
              <li className="" key={index}>
                <p className="font-inter font-semibold">{bullet}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LandingStep;
