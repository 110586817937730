import React, { useEffect, useState } from "react";
import TimeService from "../../services/time";
import FormatSerivce from "../../services/format";
import PropertyDataService from "../../services/property";

import {
  InformationCircleIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import SchedulerContact from "../Scheduler/SchedulerContact";
import Task from "../Task/Task";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import AppointmentSelected from "./AppointmentSelected";
import AppointmentCompleted from "./AppointmentCompleted";

const NoAppointment = ({}) => {
  return (
    <div>
      <p className="text-center font-inter font-bold text-xl text-dark">
        No Appointment Selected
      </p>

      <div className="mt-5 flex flex-row space-x-5 border-solid border-2 p-4 rounded-lg border-blue-400">
        <InformationCircleIcon className="h-10 text-blue-300" />
        <p className="text-dark font-semibold font-inter text-lg">
          Select an appointment by clicking on an upcoming appointment on the
          calendar or by selecting an appointment tile from the list.
        </p>
      </div>
    </div>
  );
};

const AppointmentEditor = (props) => {
  const { currentAppointment, user, currentProperty, type } = props;

  console.log("AppointmentEditor", type)
  
  console.log("currentAppointment, user, currentProperty", currentAppointment, user, currentProperty)

  return (
    <div>
      {currentAppointment ? (
        currentAppointment.completed ? (
          <AppointmentCompleted
            appointment={currentAppointment}
            user={user}
            currentProperty={currentProperty}
          />
        ) : (
          <AppointmentSelected
            appointment={currentAppointment}
            user={user}
            currentProperty={currentProperty}
            userType={type}
          />
        )
      ) : (
        <NoAppointment />
      )}
    </div>
  );
};

export default AppointmentEditor;
