class FormatService {
  // combines a date object with a string of time for storage in the DB
  combineDateTime(date, time) {
    let digs = time.split(" ")[0].split(":");
    let hours = parseInt(digs[0]);
    let mins = parseInt(digs[1]);
    if (hours < 7) {
      hours = hours + 12;
    }
    let newDate = new Date(date);
    newDate.setHours(hours, mins, 0);
    return newDate;
  }

  // returns the time in readable format from a date object
  getTimefromDate(d) {
    let date = new Date(d);

    let hours = date.getHours();

    let AMorPM = hours >= 12 ? "pm" : "am";

    hours = hours % 12 || 12;

    let mins = date.getMinutes();

    mins = mins < 10 ? "0" + mins.toString() : mins.toString();

    return `${hours}:${mins} ${AMorPM}`;
  }

  getFormatedURL(s) {
    let splited = s.split(" ");
    let newString = ""
    for (let i in splited) {
      if (i === 0) {
        newString += splited[i]
      } else {
        newString += `+${splited[i]}`
      }
    }
    console.log("URL STRING is: ", newString)
    return newString
  }

  // returns the date in readable format from a date object
  getFormatedDate(d) {
    let date = new Date(d);
    return `${date.toLocaleString("default", {
      month: "long",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  }

  // returns the date and time in a readable format from a date object
  getFormatedDateTime(d) {
    let time = this.getTimefromDate(d);
    let date = this.getFormatedDate(d);
    return time + " on " + date;
  }

  getFormatedAppointment(d) {
    let date = new Date(d);
    let time = this.getTimefromDate(d);
    let day = date.getDay();
    let weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";
    return `${weekday[day]}, ${date.toLocaleString("default", {
      month: "long",
    })} ${date.getDate()}, ${time}`;
  }

  // formats a string of numbers into a readable phone number
  formatPhoneNumber(phoneNumberString) {
    if (phoneNumberString == undefined) {
      return "";
    }

    let newString = phoneNumberString.substring(1);
    var cleaned = ("" + newString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phoneNumberString;
  }

  // using a property object it returns the address in readable format
  formatAddress(propObj) {
    return (
      propObj.street +
      " " +
      propObj.city +
      ", " +
      propObj.state +
      " " +
      propObj.zip
    );
  }
}

export default new FormatService();
