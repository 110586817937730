import React, { useState } from 'react';
import {Disclosure, Transition} from '@headlessui/react'
import { ChevronUpIcon, SearchIcon } from '@heroicons/react/solid'

import StyledDisclosure from "../../components/StyledDisclosure/StyledDisclosure.js";

//On the left side we shuold have radio groups from https://headlessui.dev/react/radio-group

// and the actual questions and answers will be https://headlessui.dev/react/disclosure

const faqs = [
    {question: "What is Property Watchers?", answer: "We are a private property/ residence managing service. We offer weekly/monthly/bi-monthly house inspections. This includes an inside and outside walkthrough along with a written report detailing general status of property along with specific tasks given by the client. If a service is needed either digital or phone communication will be had with client to work out an option for fixing."},
    {question: "Who do I contact if I need help?", answer: "During your initial visit you will be given a phone number to which you can call during the business day and discuss any additional needs you have. If you have questions about a service that is being provided you may also contact this representative.\n" +
            "If you need help after business hours you can call your representative but will be billed hourly at 25$, and we cannot guarantee emergency service work after hours.\n"},
    {question: "What is included in a property checkup?", answer: "We turn on all the systems and make sure they are behaving normally. We walk around the property and check for visible signs of pest infestation. We also provide light bulbs at discounted charge. (Idk about this one I was just thinking we can do something about small appliances that go out like light bulbs.)\n" +
            "Normal trip will take between 20-30 mins at the property. The scheduled time will be provided before along with contact information for the tech if you want to call them while they are at the house.\n" +
            "Although extra work may be charged for if outside of agreement.\n"},
    {question: "If I need a service how does it work?", answer: "Property Watchers can either provide a service through their contractors or you can hire a 3rd party provider.\n" +
            "We work with our providers to lower your price and also to afford a smoother process in terms of priority scheduling and a trust of quality work.\n" +
            "For this reason if you use our provider we guarantee at the minimum a competitive rate if not discounted. And charge no additional fees apart from the services cost and the monthly subscription. \n" +
            "This will also be billed through our system so no additional financial information needs to be exchanged and you will see the cost reflected in the next monthly charge.\n" +
            "\n" +
            "If you use a 3rd party provider we charge 20$ per visit to the house to facilitate the service and do not organize or handle any payment.\n"},
    {question: "How does the process start after I sign up?", answer: "After a client signs up we will schedule an initial visit. We can accommodate both in person and video walk through however we need access to keys if we are doing inside walkthrough.\n" +
            "During this the owner will show a tech all of the important pieces of the house and make notes of specific unique requirements of checking their house.\n" +
            "This information will be stored on our system and then every tech visiting the house will use it as a check list.\n" +
            "Additional notes may be added by contacting your rep or in the future on our website.\n"},
    {question: "How do I get updates?", answer: "Currently we are providing updates in the form of an email, text, and if you have additional questions you will always be able to speak to the last tech that visited your house via a phone call. We will soon have a website/ application in which you can track all your updates and order services more efficiently."},
    {question: "What is the welcome home bundle?", answer: "Must be a subscribing member. For 150$ we have a cleaning lady and yard person come do your house the week that you will be arriving home."},
    {question: "What services can we recommend?", answer: "Landscaping\n" +
            "Heating and electricity\n" +
            "Water\n" +
            "Sewer\n" +
            "Exterior paint\n" +
            "Pressure washing roofs\n" +
            "Internet service\n" +
            "Replacing appliances\n" +
            "House cleaning\n" +
            "Chimneys \n" +
            "Pool \n"},
];



function FAQ() {

    const [viewable, setViewable] = useState([true,true,true,true,true,true,true,true]);

    const onSearch = (e) => {
        let curString = e.target.value.toLowerCase();
        let curViewable = [];
        for (let i in faqs) {
            if (faqs[i].answer.toLowerCase().includes(curString) || faqs[i].question.toLowerCase().includes(curString)) {
                curViewable.push(true);
            } else {
                curViewable.push(false);
            }
        }
        setViewable(curViewable)

    };

    return (
        <div className="flex flex-col min-h-screen bg-background">

            <div className="flex-grow max-w-full">
                <div className="max-w-full mt-0 pb-8 bg-gradient-to-b from-red to-red-grad">
                    <div className="flex items-center justify-center" >  <p className="text-7xl font-inter text-white pt-5 pl-5">Frequently Asked Questions</p> </div>

                    <div className="flex items-center justify-center" >
                        <div className="p-8 w-1/2">
                            <div className="bg-white flex items-center rounded-full shadow-xl">
                                <input
                                    className="rounded-l-full w-full py-4 px-6 text-gray-700 leading-tight focus:outline-none font-bold text-xl"
                                    id="search" type="text" placeholder="Describe your issue"
                                    onChange={e => onSearch(e)}
                                >
                                </input>
                                <div className="p-4">
                                    <button
                                        className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-400 focus:outline-none w-12 h-12 flex items-center justify-center">
                                        <SearchIcon />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>



                <div className="w-1/2 p-2 mx-auto bg-white rounded-2xl mt-10 space-y-3">
                    {faqs.map((q, index) => {
                          return  <StyledDisclosure show={viewable[index]} question={q.question} answer={q.answer} />
                    }
                    )}
                </div>

            </div>


        </div>
    )

}

export default FAQ