import React, { useState, useEffect } from "react";
import { ChevronUpIcon, SearchIcon } from "@heroicons/react/solid";
import FormatService from "../../services/format";
import TimeService from "../../services/time";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";

const options = [
  { value: "all", label: "All" },
  { value: "upcoming", label: "Upcoming" },
  { value: "completed", label: "Completed" },
];

const appointmentBox = (val, user, setCurent, current) => {
  const setCurrent = setCurent;

  const selected = current && val._id === current._id

  // create a more readable string for type
  let typeString = "";
  switch (val.type) {
    case "initial":
      typeString = "Initial Appt.";
      break;
    case "propertycheck":
      typeString = "Property Checkup";
      break;
  }

  return (
    <li>
      <button className="focus:outline-none w-full text-left" onClick={() => setCurrent(val)}>
      <div className={ (selected ? "bg-gray-200" : "bg-white")  + " rounded flex flex-col border-2 border-solid border-gray-200 p-1 hover:bg-gray-100 mx-10"}>
       
          <p className="text-sm">
            {FormatService.getFormatedAppointment(val.dateTime)}
          </p>
          <p className="text-md">{typeString}</p>
          {val.completed ? (
             
                <p className="font-semibold text-sm">Completed</p>
               
            ) : null}
       
      </div>
      </button>
    </li>
  );
};

const AppointmentViewer = (props) => {
  const { user, currentProperty, appointments, setCurrent, currentSelected } = props;

  const [viewable, setViewable] = useState([]);
  const [appointmentType, setAppointmentType] = useState(options[1]);

  const changeVal = (e) => {
    setAppointmentType(e);
  };

  const displayAppointments = () => {
    let curViewable = [];
    appointments.map((val) => {
      if (val.propId === currentProperty.value) {
        if (
          appointmentType.value === "all" ||
          (appointmentType.value === "upcoming" && !val.completed) ||
          (appointmentType.value === "completed" && val.completed)
        ) {
          curViewable.push(val);
        }
      }
    });
    if (curViewable.length === 0) {
      return <p className="ml-10">No Appointments</p>;
    }

    curViewable.sort((a, b) => TimeService.isBefore(b.dateTime, a.dateTime));

    let renderList = [];
    for (let i in curViewable) {
      renderList.push(appointmentBox(curViewable[i], user, setCurrent, currentSelected));
    }

    return renderList.map((curAppointment) => {
      return curAppointment;
    });
  };

  return (
    <div className="">
      <div className="">
        <Select
          options={options}
          value={appointmentType}
          className="px-10"
          onChange={(e) => setAppointmentType(e)}
        />
      </div>
      <div className="">
        <p className="my-2 mx-10 text-dark font-medium font-inter text-lg">
          Appointments:
          <span className="text-gray-500 font-light ml-2 font-inter">
            (scroll to view)
          </span>
        </p>
        <div className="h-72 overflow-scroll">
          <ol className="space-y-4">{displayAppointments()}</ol>
        </div>
      </div>
    </div>
  );
};

export default AppointmentViewer;
