import React from "react";
import { useAuth0} from "@auth0/auth0-react";

const LoginButton = (props) => {
    let {className} = props;

    if (className === undefined) {
        className = "mb-4 ml-2 py-2 px-4 text-xs font-bold"
    }


    const {loginWithRedirect} = useAuth0();
    return (
        <button
        onClick={() => loginWithRedirect()}
        className={className + " bg-gradient-to-r from-blue-500 to-blue-700 hover:from-white hover:to-background text-white hover:text-blue-500 border border-transparent hover:border-blue-500 rounded transition duration-300"}
        >
            Get Started
        </button>
    );
};

export default LoginButton;