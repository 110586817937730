import React from "react";
import DatePicker from "react-datepicker";
import TimePicker from "../TimePicker/TimePicker";
import Stepper from "react-stepper-horizontal";
import {InformationCircleIcon} from "@heroicons/react/outline/esm";
import SetupStepper from "../SetupStepper/SetupStepper";
import FormatService from "../../services/format";

const PrepareAppointment = (props) => {

    const {appointment} = props;

    let date = new Date(appointment.dateTime);

    let time = FormatService.getTimefromDate(date)

    const showTime = <p className="ml-4 font-medium text-2xl" >time: {" "}
        <span className="font-bold text-3xl">
                        {date.toLocaleString('default', { month: 'long' })} {date.getDate()}, {date.getFullYear()} {" at "} {time}
                    </span>
    </p>;


    return (
        <div>

            <div className="bg-red mx-0 rounded-t-lg ">
                <h2 className="text-white font-bold sm:ml-8 text-4xl pl-12 py-8">You're all set!</h2>

            </div>

            <div className="mb-8 mt-4">

                <SetupStepper step={2}/>
            </div>


            <div className="flex flex-col lg:flex-row">
                <div className="flex flex-col flex-start mx-5 mt-5 sm:mt-0 lg:ml-12 lg:w-1/2">

                    <div className="flex flex-row items-center mx-auto mb-8">
                        <p className="font-bold text-2xl text-dark" >Your Appointment has been confirmed:</p>
                    </div>

                    <div className="flex flex-row items-center mx-auto border-4 mb-8 rounded-lg">
                        <p className="font-bold m-4 text-dark text-2xl" >
                            {date.toLocaleString('default', { month: 'long' })} {date.getDate()}, {date.getFullYear()} {" at "} {time} </p>
                    </div>


                    <div className="ml-12 mb-4">
                        <p className="font-bold text-2xl text-dark" >Preparing for your visit:</p>
                    </div>

                    <div className="flex flex-row justify-center bg-gradient-to-b from-gray-100 to-gray-200 shadow-xl p-4">
                        <ol className="font-medium text-xl text-dark list-disc px-6">
                            <li>Have access to card payment</li>
                            <li>Create list of house specific details that you want your house watcher to know</li>
                            <li>Obtain a key for leaving in lock box on departure.</li>
                        </ol>
                    </div>



                </div>
                <div className="flex flex-col flex-start  mx-5 lg:mx-12 lg:w-1/2 mt-10">

                    <div className="border-4 rounded-lg border-gray-200 pr-5  pb-10">
                        <div className="flex flex-row items-center">
                            <InformationCircleIcon className="w-10 m-4 text-gray-200" />
                            <p className="text-gray-400 font-bold">
                               Keep in mind
                            </p>
                        </div>

                        <p className="font-medium text-gray-500 ml-12 mb-2 ">
                            <span className="font-bold text-gray-600">You only have to complete this process once. </span> Afterwards you will be able to schedule our services for any trip you take either online or over the phone.
                        </p>





                    </div>

                </div>



            </div>
        </div>
    )

};

export default PrepareAppointment;