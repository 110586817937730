import React, { useState, useEffect, forwardRef } from "react";
import SchedulerDateTimeSelector from "./SchedulerDateTimeSelector";
import SchedulerSwitch from "./SchedulerSwitch";
import SchedulerContact from "./SchedulerContact";
import SchedulerDateRangeSelector from "./SchedulerDateRangeSelector";
import SchedulerNotes from "./SchedulerNotes";
import PropertyDataService from "../../services/property";
import FormatService from "../../services/format";
import { InformationCircleIcon, FireIcon } from "@heroicons/react/outline";
import { BookmarkIcon } from "@heroicons/react/solid";
import InformationPopup from "../InformationPopup/InformationPopup";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";

const RowLabel = ({ label, info, infoText, children }) => {
  return (
    <div className=" space-y-5">
      <div className="flex flex-row items-center space-x-3">
        <p className="text-xl font-bold text-gray-600">{label}</p>
        {info ? <InformationPopup>{info}</InformationPopup> : null}
      </div>

      <div>{children}</div>
    </div>
  );
};

const contactInfo = (
  <div className="">
    <p className="font-inter ">
      We will always <span className="font-bold">email a confirmation</span>{" "}
      after the report is done and{" "}
      <span className="font-bold">call in case of an emergency</span>, please
      answer these additional contact questions below:
    </p>
  </div>
);

const bookingInfo = (
  <div className="space-y-2 font-inter">
    <p>
      All appointments made with either option can be edited the same way after
      booking.
    </p>
    <p>
      The difference between the options is just what tools are given to help
      with the booking
    </p>
  </div>
);

const Scheduler = (props) => {
  const [bookingType, setBookingType] = useState("single");
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({
    email: true,
    text: false,
    call: false,
  });
  const [date, setDate] = useState([]);
  const [note, setNote] = useState("");

  const [readyToSave, setReadyToSave] = useState(true);
  const [saveError, setSaveError] = useState(false);

  const [bookingSchedule, setBookingSchedule] = useState([]);

  const [start, setStart] = useState(false);
  const [regionCode, setRegionCode] = useState("");

  const { user, selected, type } = props;

  useEffect(() => {
    for (let i in user.property) {
      if (user.property[i]._id == selected.value) {
        setRegionCode(user.property[i].region);
        retrieveSchedule(user.property[i].region);
      }
    }
  }, []);

  const retrieveSchedule = (region) => {
    PropertyDataService.getBookingScheduleByRegion(region)
      .then((response) => {
        setBookingSchedule(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const history = useHistory();

  const saveDetails = () => {
    if (date.length === 0) {
     
      setSaveError("Fields missing");
      return;
    }
    if (!readyToSave) {
     
      setSaveError("Images must be uploaded or discarded before save.");
      return;
    }

    const created = new Date();

    console.log("user");
    let emailData;
    if (date.length === 1) {
      emailData = {
        address: selected.label,
        name: user.name,
        dateTime: FormatService.getFormatedAppointment(date[0]),
        customerEmail: user.email,
      };
    } else {
      let formatedDates = [];
      for (let i in date) {
        formatedDates.push(FormatService.getFormatedAppointment(date[i]));
      }

      emailData = {
        address: selected.label,
        name: user.name,
        dateTime: formatedDates,
        customerEmail: user.email,
      };
    }

    const data = {
      type: "propertycheck",
      propId: selected.value,
      region: regionCode,
      clientId: user._id,
      dateTime: date,
      created: created,
      contact: contact,
      note: note,
      emailData: emailData,
    };

    setLoading(true);
    PropertyDataService.postAppointment(data)
      .then((response) => {
        // console.log("url", window.location.pathname.split("/"));
        if (type === "customer") {
          let splits = window.location.pathname.split("/");
          let base = `/${splits[1]}/${splits[2]}`;
          history.push({ pathname: `${base}/${response.data.user._id}` });
        }
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      {start ? (
        <div className="my-5 space-y-10">
          {user.referral && user.referral.freeBookings > 0 ? (
            <div className="flex flex-row items-center justify-center">
              <div className=" border-2 border-solid border-gray-400 flex flex-row p-2 rounded-xl">
                <FireIcon className="h-8 text-red-500" />
                <p className="ml-2 text-lg font-inter">
                  You have{" "}
                  <span className="text-xl font-semibold">
                    {user.referral.freeBookings}
                  </span>{" "}
                  free bookings!
                </p>
              </div>
            </div>
          ) : null}

          <RowLabel label="Booking Type" info={bookingInfo}>
            <SchedulerSwitch setBookingType={setBookingType} />
          </RowLabel>

          <RowLabel label="Appointment Details">
            {bookingType === "single" ? (
              <>
                <SchedulerDateTimeSelector
                  setDate={setDate}
                  bookingSchedule={bookingSchedule}
                />
              </>
            ) : (
              <>
                <SchedulerDateRangeSelector
                  setDates={setDate}
                  bookingSchedule={bookingSchedule}
                />
              </>
            )}
          </RowLabel>

          <RowLabel label="Contact Preference" info={contactInfo}>
            <SchedulerContact setContact={setContact} />
          </RowLabel>

          {bookingType === "single" ? (
            <RowLabel label="Appointment Specific Notes">
              <SchedulerNotes
                saveNote={setNote}
                setReadyToSave={setReadyToSave}
              />
            </RowLabel>
          ) : null}
          <RowLabel label="Confirmation">
            {saveError ? (
              <p className="text-red text-lg m-2">{saveError}</p>
            ) : null}
            <button
              className="flex flex-row bg-blue-400 text-white mt-3 font-bold text-lg 
                                py-1 px-3 rounded-md items-center border-solid border-2
                                hover:bg-white hover:text-blue-400 hover:border-blue-400
                                "
              onClick={() => saveDetails()}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
              ) : null}
              Save
            </button>
          </RowLabel>
        </div>
      ) : (
        <div className="flex justify-center flex-col h-52 items-center">
          <button
            className="flex space-x-4 bg-red text-white mt-3 font-bold text-xl 
                                py-3 px-6 rounded-md items-center border-solid border-2
                                hover:bg-white hover:text-red hover:border-red 
                                "
            onClick={() => setStart(true)}
          >
            <p>Schedule visits</p>
            <BookmarkIcon className="h-5 " />
          </button>
        </div>
      )}
    </div>
  );
};

export default Scheduler;
