import React, { useEffect, useState } from "react";
import PricingCard from "../../components/PricingCard/PricingCard";

const getaway = {
  title: "Weekend Getaway",
  price: "30",
  time: "day",
  visits: "1",
};

const vacation = {
  title: "Family Vacation",
  price: "80",
  time: "week",
  visits: "3",
};

const summer = {
  title: "Summer Stay",
  price: "50",
  time: "month",
  visits: "2+",
  moreInfo: [
    <p className="whitespace-wrap">
      hello jkfdlsajl jfdkslafjdklsaj fkdlsfdklsajfdkslafj dskal fjdkslafj
      dklsaj fkdlasjfdklsa
    </p>,
  ],
};

function Pricing() {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div className="flex flex-col min-h-screen bg-background font-inter">
      <div className="flex-grow">
        <div
          id="title"
          className="flex flex-col text-center items-center mt-4 md:mt-8 mx-10 md:mx-40 sm:justify-center"
        >
          <div className="sm:w-2/3 space-y-4  md:space-y-6">
            <p className="font-bold text-2xl md:text-5xl text-center ">
              Simple, straight forward pricing.
            </p>
            <p className="md:text-2xl">
              {" "}
              We use a fixed price we still tailor our product to your
              home specifically. We will accomplish all the tasks that keep your
              home in tip top shape.{" "}
            </p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row mt-10">
          <div className="sm:w-3/5 mx-3 flex justify-center sm:justify-end">
            <div className="sm:w-4/5">
              <p className="mt-6 md:text-2xl">
                {" "}
                We also offer concierge services and on-call house visits while
                your house is in our care. Call our representative, and we can
                answer any questions you may have.{" "}
              </p>
              <p className="md:text-2xl border-t-2 pt-3 border-gray">
                {" "}
                Don’t worry if these plans do not cover your needs. Our
                PropertyWatchers can workout all your requests during the
                initial visit. These rates are general guides to follow for you
                to understand our rates.{" "}
              </p>
            </div>
          </div>
          <div className="sm:ml-14">
            <div className="bg-white rounded-xl p-10 py-10 shadow-md mx-3">
              <h2 className="text-3xl font-bold mb-2 whitespace-nowrap text-center">
                Fixed Rate
              </h2>
              <div className="leading-relaxed flex flew-row flex-end justify-center pb-2">
                <span className="text-4xl">$</span>
                <span className="text-6xl font-bold font-semi">40</span>
                <span className="ml-2">per Visit</span>
              </div>
            </div>
          </div>
        </div>

        <div
          id="gettingStarted"
          className="flex flex-col items-center space-y-8 mx-10 md:mx-20 mt-20"
        >
          <div className="border-b-2 border-gray w-2/4" />
          <p className="font-bold text-xl md:text-4xl text-center ">
            Ready to get started?
          </p>
          <button className="bg-red mb-4 hover:bg-transparent font-bold text-white hover:text-red py-4 px-16 border border-transparent hover:border-red rounded">
            Sign Up
          </button>
        </div>

        {/* <div className="space-y-12">
                        <p className="mt-20 font-bold text-xl md:text-4xl text-center ">Still have questions?</p>
                        <p className="md:text-2xl"> Check out our <span className="text-link">FAQ</span> and our <span className="text-link">How It Works Page</span>!  </p>
                    </div> */}

        {/* <div id="pricingContent"
                     className="flex flex-col text-center items-center mt-4 md:mt-8 space-y-4  md:space-y-8 mx-10 md:mx-40">
                    <p className="font-bold text-xl md:text-4xl text-center ">Plans</p>

                    <div className="flex flex-col md:flex-row md:space-x-16 space-y-8 md:space-y-0">
                        <PricingCard info={getaway}/>
                        <PricingCard info={vacation}/>
                        <PricingCard info={summer}/>
                    </div>
                    <div className="space-y-12">
                       
                    </div>

                   


                </div> */}
      </div>
    </div>
  );
}

export default Pricing;
