import React, { useState, useEffect } from "react";
import FormatService from "../../services/format";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
  } from "@heroicons/react/outline";
  import { get as _get } from 'lodash';
import Images from "../Images/Images";


const TaskDisplay = (props) => {
  const { details } = props;
  console.log(details)
  return (
    <div className=" my-5 rounded-md flex flex-col-reverse sm:flex-row">
      <div className="sm:w-1/2 p-2 rounded-b-md sm:rounded-tl-md sm:rounded-br-none  flex flex-row space-x-3 border-gray-400 border-solid border-2">
        {details.result == "completed" ? <CheckCircleIcon className="h-8 text-green-500" /> : null}
        {details.result == "attention" ? <ExclamationCircleIcon className="h-8 text-yellow-500" /> : null}
        {details.result == "unable" ? <XCircleIcon className="h-8 text-red" /> : null}
        <div className="text-right">
          <p className="font-inter">Time:</p>
          {details.notes.note ? <p className="font-inter">Notes:</p> : null}
          {details.notes.images.length > 0 ? <p className="font-inter">Images:</p> : null}
        </div>
        <div className="">
          <p>{FormatService.getTimefromDate(details.time)}</p>
          <p>{details.notes.note}</p>
          <Images editable={false} presetImages={details.notes.images} />
        </div>
      </div>
      <div className="sm:w-1/2 p-2 rounded-t-md sm:rounded-tl-none sm:rounded-br-md bg-gray-200">
        <p className="font-inter text-lg font-semibold">{details.task.task}</p>
        <p className="font-inter text-md font-normal">
          {_get(details, 'task.taskNote', null) && details.task.taskNote.split("<br>").map((val) => {
            return (
              <>
                {val}
                <br />
              </>
            );
          })}
        </p>
      </div>
    </div>
  );
};

const TaskDisplayList = (props) => {
    const {displayList, title} = props;

  return (
    <div className="mx-10 sm:mx-20 mt-10">
      <p className="sm:mx-5 font-inter font-normal text-lg">{title}</p>
      <div className="sm:mx-5">
        {displayList.map((val) => (
          <TaskDisplay details={val} />
        ))}
      </div>
    </div>
  );
};

const ReportTaskDisplay = (props) => {
  const { title, tasks, type } = props;

  const [general, setGeneral] = useState([]);
  const [property, setProperty] = useState([]);
  const [appointment, setAppointment] = useState([]);
  const [num, setNum] = useState(0);
  console.log(tasks);

  useEffect(() => {
    if (tasks) {
      setDisplay();
    }
  }, [tasks]);

  const setDisplay = () => {
    let gen = [];
    let prop = [];
    let appoint = [];
    for (let i in tasks.general) {
      let current = tasks.general[i];
      if (current.result == type) {
        gen.push(current);
      }
    }
    for (let j in tasks.property) {
      let current = tasks.property[j];
      if (current.result == type) {
        prop.push(current);
      }
    }
    for (let k in tasks.appointment) {
      let current = tasks.appointment[k];
      if (current.result == type) {
        appoint.push(current);
      }
    }

    setGeneral(gen);
    setProperty(prop);
    setAppointment(appoint);

    let tot = gen.length + prop.length + appoint.length;

    setNum(tot);
  };

  return (
    <div>
      {num > 0 ? (
        <div className="">
          <p className="ml-10 sm:ml-20 font-semibold text-2xl text-dark font-inter">
            <span className="font-normal">{num}</span> {title} 
          </p>
          {general.length > 0 ? (
           <TaskDisplayList displayList={general} title="General" />
          ) : null}
          {property.length > 0 ? (
            <TaskDisplayList displayList={property} title="Property" />
          ) : null}

          {appointment.length > 0 ? (
            <TaskDisplayList displayList={appointment} title="Appointment Specifc"/>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default ReportTaskDisplay;
