import DayPicker, { DateUtils } from "react-day-picker";
import React, { useState, useEffect } from "react";
import TimeService from "../../services/time";
import "react-day-picker/lib/style.css";

const ProfileCalendar = (props) => {
  const { selected, setCurrent, current } = props;


  const [currentSelected, setCurrentSelected] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const handleDayClick = (day, {upcoming, completed}) => {
    if (upcoming || completed) {
      let appt = findAppointment(day)
      setCurrent(appt)
    }
    // if (disabled) {
    //   return;
    // }
    // if (selected) {
    //   // const curSelectedDays = selectedDays.concat();
    //   // const selectedIndex = curSelectedDays.findIndex((selectedDay) =>
    //   //   DateUtils.isSameDay(selectedDay, day)
    //   // );
    //   // curSelectedDays.splice(selectedIndex, 1);
    //   // setSelectedDays(curSelectedDays);
    //   // setDates(curSelectedDays);
    //   console.log(day)
    //   console.log(selected)
    //   console.log()



    // } else {
    //   // let newSelected = selectedDays.concat(day);
    //   // setSelectedDays(newSelected);
    //   // setDates(newSelected);
    // }
  };

  const findAppointment = (day) => {
    let dayB = new Date(day)
    for (let i in selected) {
      let dayA = new Date(selected[i].dateTime)
      if (TimeService.isSameDay(dayA, dayB)) {
        return selected[i]
      }
    }
    return null
  }

  useEffect(() => {
    if (current) {
      let newDate = new Date(current.dateTime);
      setCurrentSelected([newDate])
    }
  }, [current]);

  useEffect(() => {
    let curCompleted = [];
    let curUpcoming = [];
    let curSelected = [];
    for (let i in selected) {
      let newDate = new Date(selected[i].dateTime);
      if (selected[i].completed) {
        curCompleted.push(newDate);
      } else {
        curUpcoming.push(newDate);
      }
    //   curSelected.push(newDate);
    }

    setCompleted(curCompleted);
    setUpcoming(curUpcoming);
    setSelectedDays(curSelected);
  }, [selected]);

  const modifiers = {
    completed: completed,
    upcoming: upcoming,
    currentSelected: currentSelected,
  };

  const modifiersStyles = {
    selected: {
      color: "white",
      backgroundColor: "blue",
      borderRadius: "100%",
    },
    currentSelected: {
      color: "white",
      backgroundColor: "blue",
      borderRadius: "100%",
    },
    completed: {
      backgroundColor: "#E4E7EB",
      color: "white",
    },
    upcoming: {
      backgroundColor: "#F44336",
      color: "white",
    },
  };

  return (
    <div className="flex justify-center text-center flex-col">
      <DayPicker
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        todayButton="Go to Today"
        // numberOfMonths={numMonths}
        month={new Date()}
        selectedDays={selectedDays}
        // disabledDays={outOfRange}
        onDayClick={handleDayClick}
        showOutsideDays
      />
      <div className="flex flex-row justify-center items-center space-x-3 my-5 mx-24 border-solid border-2 border-gray-300 shadow-inner py-3 px-2">
      <div className="w-5 h-5 bg-red-light rounded-full"></div>
        <p>Completed</p>
        <div className="w-5 h-5 bg-red rounded-full"></div>
        <p>Upcoming</p>
      </div>
    </div>
  );
};

export default ProfileCalendar;
