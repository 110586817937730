import React, { useEffect, useState } from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import FormatService from "../../services/format";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import PropertyDataService from "../../services/property"

{/* <button className="w-full" onClick={() => goToReport()}>
<div className={(oddBg ? "sm:bg-gray-100" : "") + " flex flex-col sm:flex-row  hover:bg-gray-200 border-solid border-2 border-gray-200 sm:border-0 p-1 sm:p-0 sm:pt-4"}>

  <div className="sm:w-1/4 flex justify-start text-dark font-inter border-solid border-gray-400 sm:border-b-2 items-center">
    {!visited ? <div className="rounded-full bg-blue-500 w-3 h-3 mr-3"></div> : null}
    <p className="sm:my-1">
      {FormatService.getFormatedAppointment(report.scheduledDateTime)}
    </p>
  </div>

  <div className="sm:w-1/4  flex justify-start text-dark font-inter border-solid border-gray-400 sm:border-b-2 ">
    <p className="m-1 ">{report.type}</p>
  </div>

  <div className="sm:w-1/4  flex justify-start text-dark font-inter border-solid border-gray-400 sm:border-b-2 ">
    <p className="m-1 ">{report.completedBy}</p>
  </div>

  <div className="sm:w-1/4 flex sm:justify-center text-dark font-inter border-solid border-gray-400 sm:border-b-2 ">
    <p className="m-1">
      {tasksComplete ? (
        <CheckCircleIcon className="h-7 text-green-500" />
      ) : (
        <ExclamationCircleIcon className="h-7 text-yellow-300" />
      )}
    </p>
  </div>
</div>
</button> */}

const TitleRow = (props) => {
  const { changeDir } = props;
  const [dir, setDir] = useState(true)

  const changeVal = (e) => {
    setDir(e)
    console.log(e)
    changeDir(e)
  }

  return (
    <div className="invisible sm:visible flex flex-row ">
      <div className="w-1/4 sm:text-lg text-blue-500 font-semibold font-inter border-solid border-gray-400  ">
        <button className="flex flex-row focus:outline-none hover:underline focus:font-semibold" onClick={() => changeVal(!dir)}>
          <p className="m-1">Date</p>
          {dir ? <ChevronDownIcon className={ "h-8"} /> : <ChevronUpIcon className="h-8" />}
          
        </button>
      </div>
      <div className="w-1/4 sm:text-lg text-blue-500 font-semibold font-inter  ">
        <p className="m-1 ">Appointment Type</p>
      </div>

      <div className="w-1/4 sm:text-lg text-blue-500 font-semibold font-inter border-solid border-gray-400  ">
        <p className="m-1 ">Completed By</p>
      </div>
     
      <div className="w-1/4 sm:text-lg text-center text-blue-500 font-semibold font-inter border-solid border-gray-400  ">
        <p className="m-1 ">Tasks Completed</p>
      </div>
    </div>
  );
};

const ItemRow = ({ report, index, unviewed }) => {
  const [tasksComplete, setTasksComplete] = useState();

  // console.log("report", report)

  const [visited, setVisited] = useState(true)

  useEffect(() => {
    setTasksComplete(checkComplete());
    for (let i in unviewed) {
      if (unviewed[i] === report.apptId) {
        setVisited(false)
      }
    }
  }, []);


  const markAsViewed = () => {
    const data = {
      userId: report.userId,
      propId: report.propId,
      apptId: report.apptId,
    };

    PropertyDataService.markView(data)
      .then((response) => {
        console.log("marked as Viewed");
        // window.location.reload()
          // hard reload so we can reflect the difference of the viewed report 
        window.location.assign(`${window.location.origin}/reports/${report.propId}/${report.apptId}`)
        return "success"
      })
      .catch((e) => {
        console.log(e);
        return "error"
      });
  };

  const history = useHistory();

  const goToReport = () => {
    if (!visited) {
      markAsViewed()
    } else {
      // soft reroute so no reload and refreshing of data
      history.push({ pathname: `/reports/${report.propId}/${report.apptId}` });
    }
  };

  const checkComplete = () => {
    for (let i in report.tasks.general) {
      let cur = report.tasks.general[i];
      if (cur.result != "completed") {
        return false;
      }
    }
    for (let i in report.tasks.property) {
      let cur = report.tasks.property[i];
      if (cur.result != "completed") {
        return false;
      }
    }
    for (let i in report.tasks.appointment) {
      let cur = report.tasks.appointment[i];
      if (cur.result != "completed") {
        return false;
      }
    }
    return true;
  };

  return (
    <button className="w-full" onClick={() => goToReport()}>
      <div className={"flex flex-col sm:flex-row rounded-lg hover:bg-gray-100 border-solid border-2 border-gray-200 p-1 sm:p-2 mb-3"}>

        <div className="sm:w-1/4 flex justify-start text-dark font-inter border-solid border-gray-400  items-center">
          {!visited ? <div className="rounded-full bg-blue-500 w-3 h-3 mr-3"></div> : null}
          <p className="sm:my-1 text-sm sm:text-base  whitespace-nowrap">
            {FormatService.getFormatedAppointment(report.scheduledDateTime)}
          </p>
        </div>

        <div className="sm:w-1/4  flex justify-start text-dark font-inter border-solid border-gray-400 ">
          <p className="m-1 ml-0 text-lg font-bold sm:text-base sm:font-normal">{report.type}</p>
          <p className="block sm:hidden m-1">
            {tasksComplete ? (
              <CheckCircleIcon className="h-7 text-green-500" />
            ) : (
              <ExclamationCircleIcon className="h-7 text-yellow-300" />
            )}
          </p>
        </div>

        <div className="sm:w-1/4  flex justify-start text-dark font-inter border-solid border-gray-400  ">
          <p className="text-xs sm:text-lg  whitespace-nowrap sm:m-1 ">{report.completedBy}</p>
        </div>
   
        <div className="hidden sm:flex  sm:w-1/4 sm:justify-center text-dark font-inter border-solid border-gray-400  ">
          <p className="m-1">
            {tasksComplete ? (
              <CheckCircleIcon className="h-7 text-green-500" />
            ) : (
              <ExclamationCircleIcon className="h-7 text-yellow-300" />
            )}
          </p>
        </div>
      </div>
    </button>
  );
};

const ReportList = (props) => {
  const { reports, property } = props;
  const [filterDirection, setFilterDirection] = useState(true);

  return (
    <div className="mx-10 ">
      <TitleRow changeDir={setFilterDirection} />
      <div className={ (filterDirection ? "flex-col-reverse" : "flex-col" ) +  " flex" }>
        {reports.map((val, index) => (
          <ItemRow report={val} index={index} unviewed={property.unviewed} />
        ))}
      </div>
    </div>
  );
};
export default ReportList;
