import Images from "../Images/Images";

const Task = ({val}) => {
    return <div className="flex flex-row items-center">
        <div>
            <li className="font-bold text-gray-700 text-lg">{val.task}</li>
            <ol className="list-disc">
                {val.taskNote != "" ? <li className="ml-5 font-medium text-gray-600 text-lg italic">{val.taskNote}</li> : null}
            </ol>
            <Images editable={false} presetImages={val.images} />
        </div>

    </div>
}

export default Task;