class TimeService {
  getSuggestedDates(start, end, schedule) {
    if (schedule.timeFrame === "day") {
      return this.getListofDays(start, end);
    }
    let diff = end.getTime() - start.getTime();
    let numDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    let suggestedDays = [];

    let timeframe = schedule.timeFrame === "week" ? 7 : 30;
    let freq = parseInt(schedule.frequency);

    // number of days in between visits
    let inbetween = Math.floor(timeframe / freq);

    console.log("inbetween", inbetween)

    // use half the number of days in between for the first date
    let nextSug = this.addTime(start, Math.floor(inbetween / 2));
    while (this.isBefore(nextSug, end) > 0) {
      suggestedDays.push(nextSug);
      nextSug = this.addTime(nextSug, inbetween);
    }


    return suggestedDays;
  }

  // addes time to the given date
  addTime(currentDate, days = 0, months = 0, years = 0) {
    let temp = new Date(currentDate);
  
    let addDays = new Date(temp.setDate(temp.getDate() + days));
    let addMonths = new Date(addDays.setMonth(addDays.getMonth() + months));
    let addYears = new Date(
      addMonths.setFullYear(addMonths.getFullYear() + years)
    );

    return addYears;
  }

  // returns a list of the days between the start and end dates
  getListofDays(start, end) {
    let loD = [];
    let cur = this.addTime(start, 1);
    while (!this.isSameDay(cur, end)) {
      loD.push(cur);
      cur = this.addTime(cur, 1);
    }
    return loD;
  }

  // checks if the dates are the same day
  isSameDay(dayA, dayB) {
    if (dayA.getDate() === dayB.getDate()) {
      if (dayA.getMonth() === dayB.getMonth()) {
        if (dayA.getFullYear() === dayB.getFullYear()) {
          return true;
        }
      }
    }

    return false;
  }

  // is day A before day B
  isBefore(dayA, dayB) {
    let a, b;
    if (Object.prototype.toString.call(dayA) === "[object Date]") {
      a = dayA
      b = dayB
    } else {
      a = new Date(dayA)
      b = new Date(dayB)
    }

    if (a.getTime() - b.getTime() > 0) {
      return -1;
    }
    return 1;
  }
}

export default new TimeService();
