import React, { useState, useEffect, forwardRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "react-datepicker";
import TimePicker from "../TimePicker/TimePicker.js";
import PropertyDataService from "../../services/property";
import TimeService from "../../services/time";
import Stepper from "react-stepper-horizontal";
import { InformationCircleIcon } from "@heroicons/react/outline";
import SetupStepper from "../SetupStepper/SetupStepper";
import SchedulerContact from "../Scheduler/SchedulerContact.js";
import FormatService from "../../services/format"

const addMonths = (start, numMonths) => {
  return new Date(start.setMonth(start.getMonth() + numMonths));
};

let date = new Date();
const today = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const getTime = (date, time) => {
  let digs = time.split(" ")[0].split(":");
  let hours = parseInt(digs[0]);
  let mins = parseInt(digs[1]);
  if (hours < 7) {
    hours = hours + 12;
  }
  let newDate = new Date(date);
  newDate.setHours(hours, mins, 0);
  return newDate;
};

const BookInitialAppointment = (props) => {
  const [startDate, setStartDate] = useState(
    TimeService.addTime(new Date(), 1)
  );
  const [day, setDay] = useState(today);
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);
  const [contact, setContact] = useState({
    email: true,
    text: false,
    call: false,
  });

  const [bookingSchedule, setBookingSchedule] = useState({});
  const [daysBookingSchedule, setDaysBookingSchedule] = useState([])

  const [regionCode, setRegionCode] = useState("");

  const { user, curProp } = props;

 

  useEffect(() => {
    for (let i in user.property) {
      if (user.property[i]._id == curProp.value) {
        setRegionCode(user.property[i].region);
        retrieveSchedule(user.property[i].region);
      }
    }
  }, []);

  useEffect(() => {
    if (bookingSchedule) {
      let fullDays = [];
      for (let i in bookingSchedule) {
        if (bookingSchedule[i].length === 19) {
          fullDays.push(new Date(i));
          if (TimeService.isSameDay(new Date(i), startDate)) {
            setStartDate(TimeService.addTime(startDate, 1))
          }
        }
        if (TimeService.isSameDay(new Date(i), startDate)) {
          setDaysBookingSchedule(bookingSchedule[i])
        }
        
      }

      setDisabledDates(fullDays);
    }
  }, [bookingSchedule]);

  const retrieveSchedule = (region) => {
    PropertyDataService.getBookingScheduleByRegion(region)
      .then((response) => {
        setBookingSchedule(response.data);
        
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const changeDate = (date) => {
    setStartDate(date);
    let curDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    // setDay(curDate);
    for (let i in bookingSchedule) {
      if (i === curDate) {
        setDaysBookingSchedule(bookingSchedule[i])
        return
      }
    }
    setDaysBookingSchedule([])
  };

  //TODO: check that the data is valid
  const saveDetails = () => {
    const dateTime = getTime(startDate, time);
    const dateTimeList = [];
    dateTimeList.push(dateTime);
    const created = new Date();

    const emailData = {
      address: FormatService.formatAddress(user.property[0]),
      name: user.name,
      dateTime: dateTimeList,
      customerEmail: user.email,
    };

    const data = {
      type: "initial",
      propId: curProp.value,
      clientId: user._id,
      dateTime: dateTimeList,
      created: created,
      contact: contact,
      region: regionCode,
      emailData: emailData
    };

    // console.log(data);

    setLoading(true);
    PropertyDataService.postAppointment(data)
      .then((response) => {
        // console.log(response.data);
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="bg-red rounded text-white text-xl font-medium p-2"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  

  return (
    <div className="px-3">
      <div className="bg-red mx-0 rounded-t-lg ">
        <h2 className="text-white font-bold text-center text-4xl py-8">
          Schedule Initial Appointment
        </h2>
      </div>

      <div className="mb-8 mt-4">
        <SetupStepper step={1} />
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col flex-start ml-12 md:w-1/2">
          <div className="">
            <p className="font-bold text-2xl text-dark">
              Pick a date and time that works for you.
            </p>
          </div>
          <p className="mt-4 ml-4 text-dark font-medium">Date:</p>
          <div className="flex justify-center mt-8 mb-8">
            <DatePicker
             
              closeOnScroll={true}
              selected={startDate}
              minDate={TimeService.addTime(new Date(), 1)}
              maxDate={addMonths(new Date(), 5)}
              onChange={(date) => changeDate(date)}
              customInput={<CustomInput />}
              excludeDates={disabledDates}
            />
          </div>
          <p className="m-4 text-dark font-medium">Time:</p>
          <div className="">
            <div className="w-max-full">
              <TimePicker setTime={setTime} daySchedule={daysBookingSchedule} />
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-start md:ml-8 md:w-1/2 mt-10 md:mt-0">
          <div className="mb-4 ml-8">
            <p className="font-bold text-2xl text-dark">Your Appointment:</p>
          </div>

          <div className="flex flex-row items-center mx-auto border-4  mb-8 rounded-lg">
            <p className="font-bold m-4 text-dark text-2xl">
              {startDate.toLocaleString("default", { month: "long" })}{" "}
              {startDate.getDate()}, {startDate.getFullYear()} {" at "} {time}{" "}
            </p>
          </div>

          <button
            onClick={() => saveDetails()}
            className="flex flex-row justify-center items-center mb-4 text-lg font-medium py-2 px-4 mx-8 bg-gradient-to-r from-blue-500 to-blue-700 hover:from-white hover:to-background text-white hover:text-blue-500 border border-transparent hover:border-blue-500 rounded transition duration-300"
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
            ) : null}
            Confirm Appointment
          </button>

          <div className="border-4 rounded-lg border-gray-200 m-4 mr-8 pb-10">
            <div className="flex flex-row items-center">
              <InformationCircleIcon className="w-10 m-4 text-gray-200" />
              <p className="text-gray-400 font-bold">
                What happens at the initial visit?
              </p>
            </div>

            <div className="flex flex-row justify-center ">
              <ol className="font-medium text-gray-500 list-disc px-8">
                <li>Setup payment process</li>
                <li>Go over house specific details</li>
                <li>Establish key procedures and entry process</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookInitialAppointment;
