import { InformationCircleIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";

const InformationPopup = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="z-10">
      <button onClick={() => setShow(!show)}
      className="focus:outline-none"
      >
        <InformationCircleIcon className="h-7 mx-3 text-blue-300 hover:text-blue-500" />
      </button>
      {children && show ? (
        <div className="border-2 z-20 border-gray-300 border-solid pt-7 p-2 bg-white shadow-lg rounded-lg absolute left-0 sm:left-auto w-auto">
          <button 
          className="absolute top-1 right-1 focus:outline-none"
          onClick={() => setShow(false)}>
            <XIcon className="h-7 text-red-400 hover:text-red-500" />
          </button>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default InformationPopup;
