import Switch from "react-switch";
import React, { useState, useEffect, forwardRef } from "react";

const SchedulerSwitch = (props) => {
  const [current, setCurrent] = React.useState("single");

  const { setBookingType } = props;

  const handleChange = (cur) => {
   if (current === cur) {
       return
   } else {
       setCurrent(cur)
       setBookingType(cur)
   }
  };

  return (
    <div className="flex justify-center">
      <button
        className={
          (current === "single"
            ? "from-red to-red-700 text-white"
            : "from-gray-100 to-gray-200 text-gray-700") +
          " bg-gradient-to-b p-5 rounded-l-lg text-xl"
        }
        onClick={() => handleChange("single")}
      >
        Single Visit
      </button>
      <button
        className={
          (current === "multiple"
            ? "from-red to-red-700 text-white"
            : "from-gray-100 to-gray-200 text-gray-700") +
          " bg-gradient-to-b p-5 rounded-r-lg text-xl"
        }
        onClick={() => handleChange("multiple")}
      >
        Multiple Visits
      </button>
    </div>
  );
};

export default SchedulerSwitch;
