import banner from "../../assets/Banner.png";
import bannerFlood from "../../assets/Banner_Flood.png";
import car from "../../assets/Car.png";
import clock from "../../assets/Clock.png";
import vacay from "../../assets/Vacation.png";
import Checklist from "../../assets/Checklist.png";
import TextLoop from "react-text-loop";

const ImageText = ({ imgSrc, titleBlue, title, text }) => {
  return (
    <div className="w-1/2 md:w-1/5">
      <div className="flex justify-center">
        <img src={imgSrc} className="h-24" />
      </div>

      <div className="text-center mt-5">
        <p className="font-bold text-lg">
          <span className="text-blue">{titleBlue}</span> {title}
        </p>
        <div className="mr-5">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

const Loop = () => {
  return (
    <div className="text-3xl mt-10 font-inter md:text-6xl ">
      <span className="font-bold text-dark border-4 border-blue rounded p-2">
        <TextLoop
          interval={2000}
          springConfig={{ stiffness: 180, damping: 15 }}
        >
          <p>peace of mind</p>
          <p>scheduled check ups</p>
          <p>constant contact</p>
          <p>simple</p>
          <p>efficient</p>
        </TextLoop>
      </span>
    </div>
  );
};

const TopSection = () => {
  return (
    <>
      {/* Top section */}
      <div className="flex flex-wrap items-center justify-between max-w-screen">
        <div className="max-w-screen-2xl px-8 pt-3 py-2 container mx-auto flex justify-between flex-col ">
          {/* text */}
          <div className="mt-8 md:my-20 text-left">
            <p className="text-3xl font-inter font-semibold sm:text-5xl md:text-6xl ">
              Are you leaving town?
            </p>

            <p className="text-2xl mt-5 font-inter font-bold sm:text-3xl lg:text-4xl ">
              We maintain and care for homes while you are away
            </p>
          </div>
          {/* changing text */}
          <div className="flex justify-end">
            <Loop />
          </div>
        </div>
      </div>

      {/* images and animation */}
      <div className="relative">
        <div className="relative">
          <img src={banner} className="" style={{ width: "100%" }} />
          <div className="car">
            <img src={car} />
          </div>
        </div>
      </div>

      {/* Why property watchers */}
      <div className=" bg-gradient-to-b from-red-light to-white">
        {/* title */}
        <div className="flex justify-center my-14 text-center">
          <p className="font-inter font-light text-5xl">
            Why Property Watchers?
          </p>
        </div>
        {/* Three Items */}
        <div className="flex flex-col md:flex-row items-center space-y-10 md:space-y-0 md:justify-around">
          {/* Flexible Booking - clock */}
          <ImageText
            imgSrc={clock}
            titleBlue={"FLEXIBLE"}
            title="BOOKING"
            text="Book an Watcher anytime you need them. After-hours? Call us
              and we can help you."
          />

          {/* Concierge Care - Checklist */}
          <ImageText
            imgSrc={Checklist}
            titleBlue="CONCIERGE"
            title="CARE"
            text=" Our Watchers can do whatever you need to maintain your home. If we
              can’t, we know someone who can, so you dont have to."
          />

          {/* Enjoy Yourself - Vacay */}
          <ImageText
            imgSrc={vacay}
            titleBlue="ENJOY"
            title="YOURSELF"
            text=" We want you to enjoy your time away from your property. So we look
              after it as if you were here with us."
          />
        </div>
      </div>
    </>
  );
};

export default TopSection;
