import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const NotFoundPage = (props) => {
  const { details } = props;
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (
      window.location.pathname.includes("report") ||
      window.location.pathname.includes("overview")
    ) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  }, []);

  return (
    <div className=" text-center my-40">
      {window.location.pathname.includes("report") ||
      window.location.pathname.includes("overview") ? (
        <p className="font-inter text-5xl">Redirecting to authentication...</p>
      ) : (
        <p className="font-inter text-7xl">404 Not Found</p>
      )}
    </div>
  );
};

export default NotFoundPage;
