import React from "react";
import Step from "../../components/Step/Step";
import { phoneNumbers } from "../../data/data.js";
import signup from "../../assets/Signup.png";

const one = {
  number: "1",
  title: "Sign up",
  bullets: ["Sign up and login to register your property."],
};

const two = {
  number: "2",
  title: "Schedule Initial Visit",
  bullets: [
    `Once you have succesfully logged on, you will be prompted to schedule your initial appointment (Free)`,
    "During this visit our Property Watcher will explain the platform in more detail and show you how to schedule and manage your future appointments.",
  ],
};

const three = {
  number: "3",
  title: "Use the Platform",
  bullets: [
    "After you have left you can view all the scheduled visits to your home.",
    "Schedule more visit or other services from the app or by calling our representatives.",
  ],
};

// const four = {
//     number: "4",
//     title: "Check in on return",
//     bullets: [ "On return if anything needs to be recovered by the cusomter our representative will meet you on the day of your return (weekend included)",
//         "Your profile will stay and save all information for use for next trip out of town. ",
//     ]
// };

function HowItWorks() {
  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-grow ">
        <div
          id="title"
          className="flex flex-col items-center mt-4 md:mt-8 space-y-4  md:space-y-5 mx-10 md:mx-20"
        >
          <p className="font-bold text-4xl text-center ">
            How does the Process Work?
          </p>
          <p className="md:text-xl">
            If you get stuck or have any questions please call us at{" "}
            <span className="tracking-wider font-semibold">
              {phoneNumbers["YUMA"]}
            </span>
          </p>
        </div>

        <div id="mainList" className="mb-16 flex flex-col md:flex-row">
          <Step info={one}>
            <div className="space-y-3 flex flex-row">
             
              <div className="pl-3">
                <ul className="space-y-3 list-none">
                  {one.bullets.map((bullet, index) => (
                    <li className="" key={index}>
                      <p className="font-inter text-xl">{bullet}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Step>
          <Step info={two}>
            
            <div className="space-y-3 flex flex-row">
            
              <div className="pl-3">
                <ul className="space-y-3 list-none">
                  {two.bullets.map((bullet, index) => (
                    <li className="" key={index}>
                      <p className="font-inter text-xl">{bullet}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Step>
          <Step info={three} className="pb-8" >
            <div className="space-y-3 flex flex-row">
            
              <div className="pl-3">
                <ul className="space-y-3 list-none">
                  {three.bullets.map((bullet, index) => (
                    <li className="" key={index}>
                      <p className="font-inter text-xl">{bullet}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Step>
          {/* <Step info={four} className="pb-8"/> */}
        </div>

        <div
          id="gettingStarted"
          className="flex flex-col items-center space-y-8 mx-10 md:mx-20"
        >
          <div className="border-b-2 border-gray w-2/4" />
          <p className="font-bold text-xl md:text-4xl text-center ">
            Ready to get started?
          </p>
          <button className="bg-red mb-4 hover:bg-transparent text-white text-xs hover:text-red py-4 px-16 border border-transparent hover:border-red rounded">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
