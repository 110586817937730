import React, { useEffect, useState } from "react";
import FormatService from "../../services/format";
import { ClipboardListIcon } from "@heroicons/react/outline";
import { PhoneIcon } from "@heroicons/react/outline";
import { CheckIcon } from "@heroicons/react/solid";

const AppointmentContact = (props) => {
  const { phone, makeCall } = props;
  //   const { dateTime, created, contact } = appointment;
  const [madeCall, setMadeCall] = useState(false);

  const labelStyle = "font-bold text-gray-500 sm:text-xl";
  const valStyle = "sm:text-xl";

  const change = (val) => {
      setMadeCall(val)
      makeCall(val)
  }

  return (
    <>
      <div className="flex flex-row items-center ml-5">
        <PhoneIcon className="h-10 w-10" />
        <p className="font-bold text-dark text-xl ml-5 ">Contact</p>
      </div>

      <div className="flex flex-col sm:mx-4 bg-gray-50 sm:bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-4  font-inter">
        <div className="flex flex-col sm:flex-row sm:space-x-4 items-center">
          <p className="text-gray-500 font-bold sm:text-xl">
            Contact Number:{" "}
            <span className="text-black font-semibold">
              {FormatService.formatPhoneNumber(phone)}
            </span>{" "}
          </p>
          <div>
            <button
              className={
                (madeCall ? "border-2 border-solid border-blue-600" : "") +
                " flex flex-row bg-white text-dark font-bold text-lg" +
                " py-1 px-3 rounded-md items-center border-solid border-2 border-white"
              }
              onClick={() => change(!madeCall)}
              // disabled={saved}
            >
              {madeCall ? (
                <CheckIcon className="w-5 h-5 mr-5 text-blue-400 border-dark border-2 border-solid" />
              ) : (
                <div className="h-5 w-5 border-solid border-dark border-2 mr-5"></div>
              )}
              Call Completed
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentContact;
