import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { PlusCircleIcon } from "@heroicons/react/outline";
import FormatService from "../../services/format";
import Images from "../Images/Images";

const TaskCheck = (props) => {
  const { task, index, listName, changeVal } = props;

  const _id = listName + index.toString()

  const [status, setStatus] = useState("");

  const [response, setResponse] = useState("");

  const [responseImages, setResponseImages] = useState([])
  const [imageReady, setImageReady] = useState(true)

  const [error, setError] = useState("");

  const [saved, setSaved] = useState(false);

  const [savedTime, setSavedTime] = useState("");

  const changeSaved = () => {
    if (!imageReady) {
      setError("Please upload or discard the images")
      return;
    }
    if (status === "") {
      setError("Please check a status")
      return;
    } 
    else if ((status === "unable" || status == "attention") && response === "") {
      setError("Please describe why the task could not be completed")
      return;
    } else {
      let newBoo = !saved;
      setSaved(newBoo);
      if (newBoo) {
        let newDate = new Date();
        setError("")
        setSavedTime(newDate);
        let completedDetails = {
          notes: {note:response, images:responseImages},
          result: status,
          task: task,
          time: newDate
        }

        changeVal(index, completedDetails)
      } else {
        setSavedTime("");
        let empty = {
          notes: {note:"", images:[]},
          result: "",
          task: task,
          time: ""
        }
        changeVal(index, empty)
      }
    }
  };

  const displayStatus = (currentStat) => {
      let result = "No status"
      switch (currentStat) {
          case "unable":
              result = "Unable to be completed"
              break
          case "completed":
              result = "Completed"
              break
          case "attention":
            result = "Needs Attention"
            break;

      }

      return result
  }


  return (
    <div id={_id} title={task.task} className="sm:bg-gray-300 p-1 sm:p-4 rounded-md sm:shadow-inner taskcheck">
      <div className="space-y-4 flex flex-col sm:flex-row">
        <div className={saved ? "sm:w-4/5" : "w-full"}>
          <div>
            <p className="text-sm font-inter font-medium text-gray-500">Task</p>
            <p className="font-inter text-dark font-semibold  bg-white p-3 rounded-md ">
              {(task && task.task) || "Task Title"}
            </p>
          </div>
          {saved ? null : task && task.taskNote ? (
            <div>
              <p className="text-sm font-inter font-medium text-gray-500">
                Detail
              </p>
              <p className="font-inter text-dark font-semibold bg-white p-3 rounded-md min-h-24">
                {task.taskNote.split("<br>").map((val) => {
                  return <>{val}<br/></>
                  
                })}
              </p>

            </div>
          ) : null}
           {saved ? null : task.images && task.images.length > 0 ? (
            <div>
              <p className="text-sm font-inter font-medium text-gray-500">
                Images
              </p>
             <Images editable={false} presetImages={task.images} />
            </div>
          ) : null}
        </div>
        {saved ? <div className="text-center m-5 space-y-5">
            <p className={(status === "completed" ? "bg-blue-500" : "bg-red-300") + " py-2 px-3  rounded-md font-semibold text-white font-inter"}>{displayStatus(status)}</p>
            {savedTime ? (
            <p className="font-medium font-inter text-sm text-dark sm:text-white">
              Saved at {FormatService.getFormatedDateTime(savedTime)}
            </p>
          ) : null}
        </div> : null}
      </div>

      <div className=" space-y-4 mt-6">
        {saved ? null : (
          <div className="flex flex-col">
            <p className="text-sm font-inter font-medium text-gray-500">
              Status
            </p>
            <div className="flex flex-col sm:flex-row justify-items-start sm:space-x-5">
              <button
                className={
                  (status === "completed"
                    ? "border-2 border-solid border-blue-600"
                    : "") +
                  " flex flex-row bg-white text-dark mt-3 font-bold text-lg" +
                  " py-1 px-3 rounded-md items-center border-solid border-2 border-white"
                }
                onClick={() => setStatus("completed")}
                disabled={saved}
              >
                {status === "completed" ? (
                  <CheckIcon className="w-5 h-5 mr-5 text-blue-400 border-dark border-2 border-solid" />
                ) : (
                  <div className="h-5 w-5 border-solid border-dark border-2 mr-5"></div>
                )}
                Completed
              </button>
              <button
                className={
                  (status === "unable"
                    ? "border-2 border-solid border-blue-600"
                    : "") +
                  " flex flex-row bg-white text-dark mt-3 font-bold text-lg" +
                  " py-1 px-3 rounded-md items-center border-solid border-2 border-white"
                }
                onClick={() => setStatus("unable")}
                disabled={saved}
              >
                {status === "unable" ? (
                  <CheckIcon className="w-5 h-5 mr-5 text-blue-400 border-dark border-2 border-solid" />
                ) : (
                  <div className="h-5 w-5 border-solid border-dark border-2 mr-5"></div>
                )}
                Unable to Complete
              </button>
              <button
                className={
                  (status === "attention"
                    ? "border-2 border-solid border-blue-600"
                    : "") +
                  " flex flex-row bg-white text-dark mt-3 font-bold text-lg" +
                  " py-1 px-3 rounded-md items-center border-solid border-2 border-white"
                }
                onClick={() => setStatus("attention")}
                disabled={saved}
              >
                {status === "attention" ? (
                  <CheckIcon className="w-5 h-5 mr-5 text-blue-400 border-dark border-2 border-solid" />
                ) : (
                  <div className="h-5 w-5 border-solid border-dark border-2 mr-5"></div>
                )}
                Needs Attention
              </button>
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-inter mt-3 font-medium text-gray-500">
                Notes
              </p>
              <textarea
                rows={3}
                cols={5}
                className={
                  "font-medium w-full mt-3 p-2  rounded-md text-lg resize-none border-solid border-2 shadow-inner border-white"
                }
                onChange={(e) => setResponse(e.target.value)}
                placeholder="'Dog was not at the home.'"
                value={response}
                disabled={saved}
              />
            </div>

            <div className="">
             <Images
              setUploadedImages={setResponseImages}
              changeReadiness={setImageReady}
              presetImages={responseImages}
              editable={true}
            />
            </div>
          </div>
        )}

        <div className="mt-5 flex flex-row space-x-4  items-center">
          <button
            className="flex flex-row bg-dark text-white font-bold text-lg 
                                    py-1 px-3 rounded-md items-center"
            onClick={() => changeSaved()}
          >
            {saved ? "Edit" : "Save"}
          </button>
          {error ? <p className="font-inter text-red font-bold text-lg">{error}</p> : null}
        </div>
      </div>
    </div>
  );
};

export default TaskCheck;
