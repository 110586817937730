import React from "react";
import { PlusCircleIcon, KeyIcon } from "@heroicons/react/outline";
import { QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import Question from "../Question/Question";
import Images from "../Images/Images";

const LockBoxDetails = ({}) => {
  return (
    <div className="mt-5 w-1/2">
      <div className="bg-white rounded-md p-4 px-6">
        <p className="font-bold text-2xl text-dark mb-2 text-center">
          Steps to Storing a lockbox on Premises
        </p>
        <ol className=" list-disc ml-10 text-xl font-medium text-gray-500">
          <li>Finding a good location</li>
          <div className="ml-14">
            <ol className="list-disc text-dark">
              <li>Out of plain view</li>
              <li>Not near water or electrical outlets</li>
              <li>Away from door ways</li>
            </ol>
          </div>
          <li>Setting the lockbox with a combination</li>
          <div className="ml-14">
            <ol className="list-disc text-dark">
              <li>Out of plain view</li>
              <li>Not near water or electrical outlets</li>
              <li>Away from door ways</li>
            </ol>
          </div>
        </ol>
      </div>
    </div>
  );
};

const SetupAccess = (props) => {
  const { setLocNum, setLocDes, setReadyToSave, presetImages, setUploadedImages } = props;

  return (
    <>
      <div className="flex flex-row items-center ml-5">
        <KeyIcon className="h-10 w-10" />
        <p className="font-bold text-dark text-xl ml-5 ">Setup Access</p>
      </div>

      <div className="mx-4 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-4 ">
        <div className="w-full"></div>

        <div className="flex flex-row">
          <LockBoxDetails />
          <Question title="Lockbox Information" containerStyle="w-1/2 m-2 mt-5">
            <p className="font-medium text-dark text-2xl">
              We use an on premises lock box to store keys and gain access to
              the property.
            </p>
            <p className="font-medium text-dark text-2xl">
              This lockbox is setup so that the customer sets and secures their
              key using a combination of their choice.
            </p>
            <p className="font-medium text-dark text-2xl">
              These lock boxes are a specific type which allow us to access them
              with our company work phone. Therefore only employees on the job
              will have access to the keys.
            </p>
          </Question>
        </div>

        <div className="m-4 w-full">
          <div>
            <p className="text-dark text-xl font-bold">Access Details</p>
          </div>

          <div className="m-2 flex flex-col w-1/3">
            <div>
              <p className="font-medium text-lg">Location Description</p>
              <textarea
                rows={2}
                onChange={(e) => setLocDes(e.target.value)}
                className="p-2 w-full resize-none tracking-wide font-medium rounded-md text-lg"
                placeholder="Left side of house, behind third tree to the left"
              />
            </div>
            <div>
              <p className="font-medium text-lg">Location Photo</p>
              <Images
                setUploadedImages={setUploadedImages}
                changeReadiness={setReadyToSave}
                presetImages={presetImages}
                editable={true}
              />
            </div>
            <div>
              <p className=" font-medium text-lg">Lock Number</p>
              <textarea
                rows={1}
                onChange={(e) => setLocNum(e.target.value)}
                className="p-2 w-full resize-none tracking-wide font-medium rounded-md text-lg"
                placeholder="L002"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupAccess;
