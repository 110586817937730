import React, { useEffect, useState } from "react";
import StickyDetails from "../../components/StickyDetail/StickyDetail";
import AppointmentDetails from "../../components/AppointmentDetails/AppointmentDetails";
import {
  PlusCircleIcon,
  CheckIcon,
  ClipboardListIcon,
  ClipboardCheckIcon,
  ClipboardIcon,
  ClipboardCopyIcon,
  ArrowCircleLeftIcon,
} from "@heroicons/react/outline";
import TaskCheck from "../../components/TaskCheck/TaskCheck";
import TaskCheckList from "../../components/TaskCheckList/TaskCheckList";
import StickyTableOfContents from "../../components/StickyTableOfContents/StickyTableOfContents";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { GeneralTaskList } from "../../data/data.js";
import AppointmentAccess from "../../components/AppointmentAccess/AppointmentAccess";
import AppointmentConfirmation from "../../components/AppointmentConfirmation/AppointmentConfirmation";
import AppointmentContact from "../../components/AppointmentContact/AppointmentContact";

const created = new Date("07/20/21 02:30 PM");
const dateTime = new Date("08/20/21 9:00 AM");

const emptyProperty = {
  city: "",
  state: "",
  street: "",
  zip: "",
};

/*
Creating an active index that is stuck on the right while they are scrollig through that lists

Three lists

    - General List
        - (1)       <index>
        - (2)
    - Property List
        - (1)       <index>
        - (2)
    - Appointment List
        - (1)       <index>
        - (2)


*/

const PropertyCheck = (props) => {
  const [generalList, setGeneralList] = useState(GeneralTaskList);
  const [propertyList, setPropertyList] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);
  const [currentProperty, setCurrentProperty] = useState(emptyProperty);

  const [hasCalled, setHasCalled] = useState(false);

  const [complete, setComplete] = useState(false);

  const [details, setDetails] = useState({
    general: { completed: false, details: [] },
    property: { completed: false, details: [] },
    appointment: { completed: false, details: [] },
  });
  const [updateList, setUpdateList] = useState([]);

  const location = useLocation();
  const { customer, appt, employ } = location.state;

  const history = useHistory();

  const goHome = () => {
    history.push({ pathname: `/home/${customer._id}` });
  };

  const makeCall = (val) => {
    let gen = details.general;
    let prop = details.property;
    let app = details.appointment;

    setHasCalled(val);

    if (gen.completed && prop.completed && app.completed && val) {
      setComplete(true);
    } else {
      setComplete(false);
    }
  };

  const changeDetails = (type, val) => {
    let gen = details.general;
    let prop = details.property;
    let app = details.appointment;

    let newObj;

    switch (type) {
      case "general":
        newObj = { general: val, property: prop, appointment: app };
        if (val.completed && prop.completed && app.completed && hasCalled) {
          setComplete(true);
        } else {
          setComplete(false);
        }
        break;
      case "property":
        newObj = { general: gen, property: val, appointment: app };
        if (gen.completed && val.completed && app.completed && hasCalled) {
          setComplete(true);
        } else {
          setComplete(false);
        }
        break;
      case "appointment":
        newObj = { general: gen, property: prop, appointment: val };
        if (gen.completed && prop.completed && val.completed && hasCalled) {
          setComplete(true);
        } else {
          setComplete(false);
        }
        break;
    }

    setDetails(newObj);

    let tempUpdated = [];

    for (let i in newObj.general.details) {
      if (newObj.general.details[i].result) {
        let newUpdated = "general" + i.toString();
        tempUpdated.push({
          id: newUpdated,
          status: newObj.general.details[i].result,
        });
      }
    }
    for (let i in newObj.property.details) {
      if (newObj.property.details[i].result) {
        let newUpdated = "property" + i.toString();
        tempUpdated.push({
          id: newUpdated,
          status: newObj.property.details[i].result,
        });
      }
    }
    for (let i in newObj.appointment.details) {
      if (newObj.appointment.details[i].result) {
        let newUpdated = "appointment" + i.toString();
        tempUpdated.push({
          id: newUpdated,
          status: newObj.appointment.details[i].result,
        });
      }
    }

    setUpdateList(tempUpdated);
  };

  useEffect(() => {
    if (!appt.contact.call) {
      setHasCalled(true);
    }

    if (appt.note.task) {
      setAppointmentList([appt.note]);

      for (let i in customer.property) {
        if (customer.property[i]._id === appt.propId) {
          if (customer.property[i].tasks.length === 0) {
            setDetails({
              general: { completed: false, details: [] },
              property: { completed: true, details: [] },
              appointment: { completed: false, details: [] },
            });
          }
        }
      }
    } else {
      for (let i in customer.property) {
        if (customer.property[i]._id === appt.propId) {
          if (customer.property[i].tasks.length === 0) {
            setDetails({
              general: { completed: false, details: [] },
              property: { completed: true, details: [] },
              appointment: { completed: true, details: [] },
            });
          } else {
            setDetails({
              general: { completed: false, details: [] },
              property: { completed: false, details: [] },
              appointment: { completed: true, details: [] },
            });
          }
        }
      }
    }

    getPropertyList();
  }, []);

  const getPropertyList = () => {
    for (let i in customer.property) {
      if (customer.property[i]._id === appt.propId) {
        setPropertyList(customer.property[i].tasks);
        setCurrentProperty(customer.property[i]);
      }
    }
  };

  // console.log(customer)


  const getReport = () => {
    
    let report = {
      scheduledDateTime: new Date(appt.dateTime),
      completedDateTime: new Date(),
      referral: customer.referral,
      completedBy: employ.name,
      compltedById: employ._id,
      type: "Property Check",
      apptId: appt._id,
      userId: customer._id,
      customerEmail: customer.email,
      propId: currentProperty._id,
      tasks: {
        general: details.general.details,
        property: details.property.details,
        appointment: details.appointment.details,
      },
    };

    return report;
  };

  return (
    <div>
      <StickyDetails customer={customer} />
      <div className="flex flex-col min-h-screen bg-background mx-5">
        <div className="mt-10 mx-5 sm:mx-8">
          <button
            onClick={() => goHome()}
            className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold flex flex-row items-center"
          >
            <ArrowCircleLeftIcon className="h-5 mr-2" />
            Back to Home
          </button>
        </div>

        <div className="bg-white rounded shadow-lg my-8 pb-12">
          <div className="mx-4 sm:mx-8 mt-8 space-y-4">
            <p className="font-bold text-dark text-4xl">Property Check</p>
            <AppointmentDetails
              property={currentProperty}
              appointment={appt}
              makeCall={makeCall}
            />

            {currentProperty.access ? (
              <AppointmentAccess property={currentProperty} />
            ) : null}

            {appt.contact.call ? (
              <AppointmentContact makeCall={makeCall} phone={customer.phone} />
            ) : null}

            <div className="flex flex-row items-center ml-5">
              <ClipboardCheckIcon className="h-10 w-10" />
              <p className="font-bold text-dark text-xl ml-5 ">Task Lists</p>
            </div>
            <div className="flex flex-col sm:mx-4 bg-gray-50 sm:bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-2 sm:p-4 ">
              <div className="flex flex-row">
                <div className="bg-white rounded-lg sm:w-1/5 sm:mr-5">
                  <StickyTableOfContents
                    lists={[generalList, propertyList, appointmentList]}
                    completed={updateList}
                  />
                </div>
                <div className="sm:w-4/5 space-y-3">
                  {generalList.length > 0 ? (
                    <TaskCheckList
                      title={"General List "}
                      taskList={generalList}
                      taskListName={"general"}
                      onValueChange={changeDetails}
                    />
                  ) : null}
                  {generalList.length > 0 ? (
                    <TaskCheckList
                      title={"Property List "}
                      taskList={propertyList}
                      taskListName={"property"}
                      onValueChange={changeDetails}
                    />
                  ) : null}
                  {appointmentList.length > 0 ? (
                    <TaskCheckList
                      title={"Appointment Specific Item "}
                      taskList={appointmentList}
                      taskListName={"appointment"}
                      onValueChange={changeDetails}
                    />
                  ) : null}
                </div>
              </div>
            </div>

            <AppointmentConfirmation
              custId={customer.payment.stripeCustomerId}
              complete={complete}
              customerName={customer.name}
              customerPhone={customer.phone}
              prop={currentProperty}
              contact={appt.contact}
              generateReport={() => getReport()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCheck;
