import React, { useState, useEffect, forwardRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import DatePicker from "react-datepicker";
import TimePicker from "../TimePicker/TimePicker.js";
import PropertyDataService from "../../services/property";
import FormatService from "../../services/format";
import Stepper from "react-stepper-horizontal";

const addMonths = (start, numMonths) => {
  return new Date(start.setMonth(start.getMonth() + numMonths));
};

let date = new Date();
const today = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const BookAppointment = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [day, setDay] = useState(today);
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);

  const [bookingType, setBookingType] = useState("single");

  const { user, curProp } = props;

  const changeDate = (date) => {
    setStartDate(date);
    let curDate = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    setDay(curDate);
  };

  //TODO: check that the data is valid
  const saveDetails = () => {
    const dateTime = FormatService.combineDateTime(startDate, time);
    // console.log(dateTime);
    const data = {
      type: "initial",
      propId: curProp._id,
      clientId: user._id,
      dateTime: dateTime,
    };

    // console.log(data);

    setLoading(true);
    PropertyDataService.postAppointment(data)
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="bg-red rounded text-white text-xl font-medium p-2"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const single = (
    <>
      <div className="my-5">
        <p>Date:</p>
        <div className="flex flex-row justify-center">
          <DatePicker
            closeOnScroll={true}
            selected={startDate}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 5)}
            onChange={(date) => changeDate(date)}
            customInput={<CustomInput />}
          />
        </div>
      </div>

      <div className="my-5">
        <p>Time:</p>
        <div></div>
      </div>

      <div className="my-5">
        <p>Tasks:</p>
        <div></div>
      </div>

      <div className="my-5">
        <p>Form of Contact:</p>
        <div></div>
      </div>
    </>
  );

  const multiple = (
    <>
      {" "}
      <div className="my-5">
        <p>Date Range:</p>
        <div className="flex flex-row justify-around">
          <DatePicker
            closeOnScroll={true}
            selected={startDate}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 5)}
            onChange={(date) => changeDate(date)}
            customInput={<CustomInput />}
          />
          <DatePicker
            closeOnScroll={true}
            selected={new Date}
            minDate={startDate}
            maxDate={addMonths(new Date(), 5)}
            onChange={(date) => changeDate(date)}
            customInput={<CustomInput />}
          />
        </div>
      </div>
      <div className="my-5">
        <p>Frequency:</p>
        <div></div>
      </div>
      <div className="my-5">
        <p>Tasks:</p>
        <div></div>
      </div>
      <div className="my-5">
        <p>Form of Contact:</p>
        <div></div>
      </div>
    </>
  );

  return (
    <div>
      <div className="my-5">
        <p>Booking Type:</p>
        <div className="flex flex-row justify-around">
          <button
            className={
              (bookingType === "single" ? "bg-blue-500" : "bg-indigo-100") +
              " p-5  text-lg font-bold"
            }
            onClick={() => setBookingType("single")}
          >
            Single
          </button>
          <button
            className={
              (bookingType === "multiple" ? "bg-blue-500" : "bg-indigo-100") +
              " p-5 text-lg font-bold"
            }
            onClick={() => setBookingType("multiple")}
          >
            Trip (multiple)
          </button>
        </div>
      </div>

      {bookingType === "single" ? single : multiple}
    </div>
  );
};

export default BookAppointment;
