import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropertyDataService from "../../services/property";

const TimePicker = (props) => {
  const [active, setActive] = useState("0:00");

  const [times, setTimes] = useState([
    ["8:00 AM", true],
    ["8:30 AM", true],
    ["9:00 AM", true],
    ["9:30 AM", true],
    ["10:00 AM", true],
    ["10:30 AM", true],
    ["11:00 AM", true],
    ["11:30 AM", true],
    ["12:00 PM", true],
    ["12:30 PM", true],
    ["1:00 PM", true],
    ["1:30 PM", true],
    ["2:00 PM", true],
    ["2:30 PM", true],
    ["3:00 PM", true],
    ["3:30 PM", true],
    ["4:00 PM", true],
    ["4:30 PM", true],
    ["5:00 PM", true],
  ]);
  const { setTime, daySchedule } = props;

  // big bug with the changing of the date

  useEffect(() => {
    let timesCopy = [
        ["8:00 AM", true],
        ["8:30 AM", true],
        ["9:00 AM", true],
        ["9:30 AM", true],
        ["10:00 AM", true],
        ["10:30 AM", true],
        ["11:00 AM", true],
        ["11:30 AM", true],
        ["12:00 PM", true],
        ["12:30 PM", true],
        ["1:00 PM", true],
        ["1:30 PM", true],
        ["2:00 PM", true],
        ["2:30 PM", true],
        ["3:00 PM", true],
        ["3:30 PM", true],
        ["4:00 PM", true],
        ["4:30 PM", true],
        ["5:00 PM", true],
      ];
    if (daySchedule) {
      for (let i in timesCopy) {
        for (let j in daySchedule) {
          if (timesCopy[i][0] === daySchedule[j]) {
            timesCopy[i][1] = false;
          }
        }
      }
      setTimes(timesCopy);
    }
  }, [daySchedule]);

  const changeActive = (e) => {
    setActive(e.target.value);
    setTime(e.target.value);
  };

  return (
    <>
      {times.map((t) => {
        return t[1] ? (
          <button
            value={t[0]}
            key={`${t[0]}`}
            className={
              (active === t[0] ? "bg-red " : "bg-gray-500 ") +
              " rounded-md  text-white px-2 py-1 m-2"
            }
            onClick={changeActive}
          >
            {t[0]}
          </button>
        ) : (
          <button
            key={t[0]}
            className={
              "disabled:opacity-50 rounded-md bg-gray-200 text-white px-2 py-1 m-2"
            }
            disabled
          >
            {t[0]}
          </button>
        );
      })}
    </>
  );
};

export default TimePicker;
