const Step = ({ num, title, description }) => {
  return (
    <div className="border-solid border-2 border-black flex flex-col lg:flex-row rounded-2xl px-3 py-5 mx-2 lg:mx-0">
      {/* left side */}
      <div className="lg:w-1/2 flex flex-row items-center">
        {/* Number */}
        <div>
          <div className="w-16 h-16 rounded-full bg-blue flex justify-center items-center">
            <p className="font-bold text-2xl lg:text-4xl text-center text-white ">
              {num}
            </p>
          </div>
        </div>

        {/* Title */}
        <div className="ml-3 lg:ml-10 ">
          <p className="font-bold text-2xl lg:text-3xl font-inter ">
            {title}
          </p>
        </div>
      </div>
      {/* Right side */}
      <div className="lg:w-1/2 flex flex-row mx-3 mt-3 lg:mt-0 lg:mx-0">
        
        <p className="text-lg ">{description}</p>
      </div>
    </div>
  );
};

const  HowItWorksSection = () => {
  return (
    <div  className="flex flex-col md:flex-row mt-32 md:items-center justify-center md:space-x-32">
      {/* left content */}
      <div className="flex justify-center  mb-10 md:mb-0 mx-5">
        <div className="text-5xl font-inter  space-y-5">
          <p className="font-light">How it works?</p>
          <p className="font-semibold">its easy</p>
        </div>
      </div>

      {/* right content */}
      <div  className="lg:w-1/2 space-y-8">
        <Step
          num="1"
          title="Setup Account"
          description="Signing up is the first step! Once we know who you are we can get started with taking care of your home whenever you need us to!"
        />
        <Step
          num="2"
          title="Initial Appointment"
          description="Our Free Initial appointment is where we show you everything there is to know about our product, and you show us everything we need to know about your property."
        />
        <Step
          num="3"
          title="Book and Enjoy"
          description="Now you have the power. Book and check in on your property all from your phone. Or give us a call!"
        />
      </div>
    </div>
  );
};
export default HowItWorksSection;
