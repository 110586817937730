import React from "react";
import {
  PlusCircleIcon,
  HomeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { QuestionMarkCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import Images from "../Images/Images";
import { GeneralTaskList } from "../../data/data.js";



const WalkthroughChecklist = (props) => {
  const [newTask, setNewTask] = React.useState("");
  const [newTaskNote, setNewTaskNote] = React.useState([]);

  const [newTaskImages, setNewTaskImages] = React.useState([]);
  const [readyToSave, setReadyToSave] = React.useState(true);
  const [taskError, setTaskError] = React.useState("");

  const [taskList, setTaskList] = React.useState([]);

  const { saveTasks } = props;

  const addTask = () => {
    if (newTask == "") {
      setTaskError("Must have a task name before adding task");
      return;
    }
    if (!readyToSave) {
      setTaskError("Must upload or discard images before adding task");
      return;
    }
    setTaskError("");

    setTaskList(
      taskList.concat({
        task: newTask,
        taskNote: newTaskNote,
        images: newTaskImages,
      })
    );
    saveTasks(
      taskList.concat({
        task: newTask,
        taskNote: newTaskNote,
        images: newTaskImages,
      })
    );
    setNewTaskNote("");
    setNewTask("");
    setNewTaskImages([]);
  };

  const removeTask = (index) => {
    let temp = [...taskList];
    temp.splice(index, 1);
    setTaskList(temp);
    saveTasks(temp);
  };

  const task = (val, i) => {
    return (
      <div className="flex flex-row justify-between items-center">
        <div>
          <li className="font-bold  text-gray-700 text-lg">{val.task}</li>
          <ol className="list-disc">
            {val.taskNote != "" ? (
              <li className="ml-5 font-medium text-lg text-gray-600 italic">
                {val.taskNote}
              </li>
            ) : null}
          </ol>
          <div className="">
            <Images presetImages={val.images} editable={false} />
          </div>
        </div>
        <div>
          <button onClick={() => removeTask(i)}>
            <XCircleIcon className="h-5, w-5 text-red hover:text-gray-400 cursor-pointer" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row items-center ml-5">
        <HomeIcon className="h-10 w-10" />
        <p className="font-bold text-dark text-xl ml-5 ">
          Home Walkthrough and Checklist
        </p>
      </div>
      <div className="mx-4 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner  p-4 ">
        <p className="font-medium text-dark text-xl mx-10">
          During this stage you will walk through the customers home and take
          notes regarding any specific actions needed to take care of the
          property.
        </p>

        <div className="flex flex-row justify-between">
          <div className="mt-5 ml-14 w-1/2">
            <ol className="space-y-4">
              <div>
                <li className="text-xl font-bold font-inter">Detail List</li>
                <div>
                  <div>
                    <ol className="bg-white rounded-md p-4 px-6 m-2 space-y-2">
                      {taskList.map((val, i) => {
                        return task(val, i);
                      })}
                    </ol>
                  </div>
                </div>
              </div>
              <div>
                <li className="text-xl font-bold font-inter">General List</li>
                <ol className=" bg-white rounded-md p-4 px-6 m-2 space-y-2">
                  {GeneralTaskList.map((val) => {
                    return (
                      <div>
                        <div>
                          {/* <p className="text-sm font-inter font-medium text-gray-500">
                            Task
                          </p> */}
                          <p className="font-inter text-dark font-semibold  bg-white p-1 rounded-md ">
                            {(val && val.task) || "Task Title"}
                          </p>
                        </div>
                        {val && val.taskNote ? (
                          <div>
                            {/* <p className="text-sm font-inter font-medium text-gray-500">
                              Detail
                            </p> */}
                            <p className="font-inter text-dark font-semibold bg-white p-2 rounded-md min-h-24">
                              {val.taskNote.split("<br>").map((val) => {
                                return (
                                  <>
                                    {val}
                                    <br />
                                  </>
                                );
                              })}
                            </p>
                            <p></p>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </ol>
              </div>
            </ol>
          </div>

          <div className="m-3 w-1/2 mt-5 ">
            <p className="text-xl font-bold font-inter mb-2">Create task</p>
            <textarea
              rows={2}
              cols={5}
              className="p-2 tracking-wide font-medium w-full rounded-md text-lg resize-none"
              onChange={(e) => setNewTask(e.target.value)}
              placeholder="Task Name"
              value={newTask}
            />
            <textarea
              rows={3}
              cols={5}
              className="p-2 tracking-wide font-medium w-full rounded-md text-lg resize-none"
              onChange={(e) => setNewTaskNote(e.target.value)}
              placeholder="Task Details (optional)"
              value={newTaskNote}
            />
            <Images
              setUploadedImages={setNewTaskImages}
              changeReadiness={setReadyToSave}
              presetImages={newTaskImages}
              editable={true}
            />
            <div className="w-max-full flex flex-col justify-center">
              <div>
                {taskError ? (
                  <div className=" my-5 mx-5 flex flex-row bg-white items-center w-2/3 justify-center rounded-lg">
                    <ExclamationCircleIcon className="text-red-400 h-8" />
                    <p className="p-2 text-red-400 font-inter font-semibold">
                      {taskError}
                    </p>
                  </div>
                ) : null}
              </div>

              <div>
                <button
                  className="flex flex-row bg-blue-400 text-white mt-3 font-bold text-lg 
                                      py-1 px-3 rounded-md items-center border-solid border-2 border-white hover:bg-white hover:text-blue-400 
                                      "
                  onClick={addTask}
                >
                  Add Task
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalkthroughChecklist;
