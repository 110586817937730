import React, { useEffect, useState } from "react";
import logo from "../../assets/logo-white.png";
import { useAuth0 } from "@auth0/auth0-react";
import Popover from "./PopUp.js";

const AppNav = (props) => {
  let getUrl = window.location;
  let home = getUrl.protocol + "//" + getUrl.host + "/overview";

  const { user } = props;
  const { logout } = useAuth0();
  // on first log in we should put them through a stepper to gather their phone number and also first property then show them to this page
  // this way we should track if they have logged in before... If they dont have a property this is when we will gather all the info.

  return user.property.length > 0 ? (
    <nav className="sticky bottom-0 z-30 flex flex-wrap bg-gradient-to-b max-w-full from-red to-red-grad rounded-t-xl">
      <div className="container flex flex-row items-center space-x-5 justify-center max-w-full sm:mx-20 my-5">
        <div className="sm:w-1/3 sm:ml-5 ">
          <button
            className=" focus:outline-none font-extrabold text-white hover:text-red hover:bg-white border-2  sm:border-2 text-lg border-solid border-white font-inter rounded-md p-1 sm:py-2 sm:px-4"
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >
            Logout
          </button>
        </div>

        <div className="sm:w-1/3 flex justify-center">
          <a
            className=""
            href={home}
          >
            <img src={logo} alt={"logo"} className="h-14" />
          </a>
        </div>

        <div className="w-1/3 text-right sm:inline hidden">
          <p className="text-white text-lg">Need answers immediately?</p>
          <p className="text-white text-xl font-medium">+1(928)920-4635</p>
        </div>
      </div>
    </nav>
  ) : null;
};

export default AppNav;
