import React, { useState, useEffect } from "react";
import { ChevronUpIcon, SearchIcon } from "@heroicons/react/solid";
import FormatService from "../../services/format";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import TimeService from "../../services/time";
import PropertyDataService from "../../services/property";
import Scheduler from "../Scheduler/Scheduler";
import AppointmentEditor from "../Profile/AppointmentEditor";
// import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";

const appointmentBox = (val, customer, prop, employInfo, changeToEdit) => {
  let details = {};
  const info = {
    currentAppointment: val,
    user: customer,
    currentProperty: prop._id,
  };

  let today = new Date()
  let appointmentDate = new Date(val.dateTime)
  
  let pastAppointment = TimeService.isBefore(today, appointmentDate)
  let sameDay = TimeService.isSameDay(today, appointmentDate)

  let report = { empty: true };
  if (val.completed) {
    if (val.type === "initial") {
      details = { tasks: prop.tasks, access: prop.access };
    } else {
      for (let i in prop.reports) {
        // console.log(prop.reports[i].apptId, val._id);
        if (prop.reports[i].apptId == val._id) {
          report = prop.reports[i];
        }
      }
      if (report.empty) {
        console.log(
          "ERROR: Completed appointment does not have report on ",
          FormatService.getFormatedAppointment(val.dateTime)
        );
      }
    }
  }

  let typeString = "";
  switch (val.type) {
    case "initial":
      typeString = "Initial Appt.";
      break;
    case "propertycheck":
      typeString = "Property Checkup";
      break;
  }

  const cancel = () => {
    const data = {
      id: val._id,
      canceledBy: employInfo._id,
      dateTime: new Date(),
    };

    PropertyDataService.cancelAppointment(data)
      .then((response) => {
        // console.log(response.data);
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <li>
      <div className={ "rounded flex flex-col sm:flex-row justify-between items-center border-2 border-solid border-gray-200 p-1 mx-5 sm:mx-10 " + (pastAppointment !== 1 ? (sameDay ? " bg-blue-50 " : " bg-red-50") : " ")}>
        <div className="flex flex-col">
          <p className="text-sm">
            {FormatService.getFormatedAppointment(val.dateTime)}
          </p>
          <p className="text-md">{typeString}</p>
          {val.completed ? (
            <p className="font-semibold text-sm">Completed</p>
          ) : null}
        </div>

        <div className="flex flex-row">
          <div>
            <button
              className="p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white mr-5"
              onClick={() => changeToEdit(info)}
            >
              Edit
            </button>
          </div>
          {val.canceled ? null : val.completed ? (
            val.type === "initial" ? (
              <div>
                <Link
                  className="p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
                  to={{
                    pathname: "/initial/confirmation",
                    state: { customer: customer, appt: val, details: details },
                  }}
                >
                  Review
                </Link>
              </div>
            ) : (
              <div>
                <Link
                  className="p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
                  to={{
                    pathname: `/home/${customer._id}/${val._id}/confirmation`,
                    state: { reportData: report, propertyData: prop },
                  }}
                >
                  Review
                </Link>
              </div>
            )
          ) : val.type === "initial" ? (
            <Link
              className="p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
              to={{
                pathname: "/initial",
                state: { customer: customer, appt: val, employ: employInfo },
              }}
            >
              Start
            </Link>
          ) : (
            <Link
              className="p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
              to={{
                pathname: `/home/${customer._id}/${val._id}`,
                state: { customer: customer, appt: val, employ: employInfo },
              }}
            >
              Start
            </Link>
          )}
          {}
          {val.completed ? null : val.canceled ? (
            <div>
              <button
                className="ml-5 p-1 px-2 border-solid border-2 border-red rounded-md text-lg text-red font-semibold hover:text-white hover:bg-red"
                // onClick={() => cancel()}
              >
                Uncancel
              </button>
            </div>
          ) : (
            <div>
              <button
                className="ml-5 p-1 px-2 border-solid border-2 border-red rounded-md text-lg text-red font-semibold hover:text-white hover:bg-red"
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

const options = [
  { value: "all", label: "All" },
  { value: "upcoming", label: "Upcoming" },
  { value: "completed", label: "Completed" },
];

const CustomerAppointments = (props) => {
  const { customer, selectedProperty, employeeInformation, add } = props;


  const [appointmentType, setAppointmentType] = useState(options[1]);

  const [editAppointment, setEditAppointment] = useState(false);
  const [details, setDetails] = useState({
    currentAppointment: {},
    user: {},
    currentProperty: "",
  });

  const changeVal = (e) => {
    setAppointmentType(e);
  };

  const changeToEdit = (info) => {
    setDetails(info);
    setEditAppointment(true);
  };

  const displayAppointments = () => {
    let viewable = [];
    let sortable = [];

    for (let i in customer.schedule[selectedProperty._id]) {
      sortable.push(customer.schedule[selectedProperty._id][i]);
    }

    sortable.sort((a, b) => TimeService.isBefore(b.dateTime, a.dateTime));

    sortable.map((val) => {
      if (
        appointmentType.value === "all" ||
        (appointmentType.value === "upcoming" &&
          !val.completed &&
          !val.canceled) ||
        (appointmentType.value === "completed" && val.completed)
      ) {
        viewable.push(
          appointmentBox(
            val,
            customer,
            selectedProperty,
            employeeInformation,
            changeToEdit
          )
        );
      }
    });
    if (viewable.length === 0) {
      return <p className="ml-5">No Appointments</p>;
    }

    return viewable.map((curAppointment) => {
      return curAppointment;
    });
  };

  // console.log(customer, selectedProperty)

  return (
    <div className="max-w-full pb-5">
      {add ? (
        <div className="mx-3">
          <Scheduler
            user={customer}
            selected={{
              value: selectedProperty._id,
              label: FormatService.formatAddress(selectedProperty),
            }}
            type={"employ"}
          />
        </div>
      ) : (
        <>
          {editAppointment ? (
            <div>
              <div className="mb-3">
                <button
                  className="border-dark text-dark hover:text-white hover:bg-dark hover:border-white border-solid border-2 font-semibold rounded-md text-lg ml-5 p-1 px-2 flex flex-row items-center"
                  onClick={() => setEditAppointment(false)}
                >
                  <ArrowCircleLeftIcon className="h-5" />
                  <p className="ml-3">Return</p>
                  
                </button>
              </div>
              <AppointmentEditor
                currentAppointment={details.currentAppointment}
                user={details.user}
                currentProperty={details.currentProperty}
                type="employ"
              />
            </div>
          ) : (
            <div>
              <div className="">
                <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
                  Appointment Type:
                </p>
                <Select
                  options={options}
                  value={appointmentType}
                  className="px-6"
                  onChange={changeVal}
                />
              </div>
              <div className="">
                <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
                  Results:
                </p>
                <div className="h-72 overflow-scroll">
                  <ol className="space-y-4">
                    {customer && selectedProperty
                      ? displayAppointments()
                      : null}
                  </ol>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerAppointments;
