import React, { useEffect, useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import TimeService from "../../services/time";
import FormatService from "../../services/format";
import "react-day-picker/lib/style.css";
import "./SchedulerCalendar.css";

const SchedulerCalendar = (props) => {
  const { start, end, schedule, setDates, disabledDates, bookingSchedule } =
    props;
  const [numMonths, setNumMonths] = useState(1);
  const [selectedDays, setSelectedDays] = useState([]);

  const [excluded, setExcluded] = useState([]);
  useEffect(() => {
    let currentlyDisabled = [];
    for (let i in disabledDates) {
      let curDate = new Date(disabledDates[i]);
      // console.log(curDate, "current disabled")
      // console.log(start, "current start")
      // console.log(end, "current end")
      if (
        TimeService.isSameDay(start, curDate) ||
        TimeService.isSameDay(end, curDate)
      ) {
        currentlyDisabled.push(curDate);
      } else {
        if (TimeService.isBefore(curDate, start) === 1) {
          continue;
        }
        if (TimeService.isBefore(curDate, end) === 1) {
          currentlyDisabled.push(curDate);
        }
      }
    }
    setExcluded(currentlyDisabled);
  }, [start, end]);

  useEffect(() => {
    let dif = end.getMonth() - start.getMonth();
    if (dif > 0) {
      setNumMonths(2);
    } else {
      setNumMonths(1);
    }

    // let days = TimeService.getSuggestedDates(start, end, schedule);
    // setSelectedDays(days);
    // setDates(days);
    // console.log("i made it to the end");
  }, [start, end, schedule]);

  const handleDayClick = (day, { selected, disabled, excluded }) => {
    if (disabled) {
      return;
    }
    if (excluded) {
      console.log("sorry full schedule that day");
      return;
    }
    if (selected) {
      const curSelectedDays = selectedDays.concat();
      const selectedIndex = curSelectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      curSelectedDays.splice(selectedIndex, 1);
      setSelectedDays(curSelectedDays);
      setDates(curSelectedDays);
    } else {
      let availableTimeSlot;

      let dayFormatted = `${
        day.getMonth() + 1
      }/${day.getDate()}/${day.getFullYear()}`;

      if (dayFormatted in bookingSchedule) {
        console.log("FINDING a TIME that works");
        // TODO : FIX THIS ADD A TIME FINDER
        let times = [
          "8:00 AM",
          "8:30 AM",
          "9:00 AM",
          "9:30 AM",
          "10:00 AM",
          "10:30 AM",
          "11:00 AM",
          "11:30 AM",
          "12:00 PM",
          "12:30 PM",
          "1:00 PM",
          "1:30 PM",
          "2:00 PM",
          "2:30 PM",
          "3:00 PM",
          "3:30 PM",
          "4:00 PM",
          "4:30 PM",
          "5:00 PM",
        ];
        for (let i in times) {
          let timeIsTaken = false;
          for (let j in bookingSchedule[dayFormatted]) {
            if (bookingSchedule[dayFormatted][j] === times[i]) {
              timeIsTaken = true;
            }
          }
          if (!timeIsTaken) {
            console.log("time", times[i])
            availableTimeSlot = FormatService.combineDateTime(day, times[i])
            break
          } 
        }
      } else {
        availableTimeSlot = FormatService.combineDateTime(day, "8:00 AM");
      }

      let newSelected = selectedDays.concat(availableTimeSlot);
      setSelectedDays(newSelected);
      setDates(newSelected);
    }
  };

  const highlighted = {
    from: start,
    to: end,
  };

  const outOfRange = {
    before: start,
    after: end,
  };

  const modifiers = {
    selectedRange: highlighted,
    start: start,
    end: end,
    excluded: excluded,
    // disabled: outOfRange,
  };
  const modifiersStyles = {
    selectedRange: {
      //   color: "red",
      backgroundColor: "#f0f8ff",
      color: "#4a90e2",
      borderRadius: "0",
    },
    // disabled: {
    //   color: "gray",
    // },
    start: {
      backgroundColor: "#4a90e2",
      color: "#f0f8ff",
    },
    end: {
      backgroundColor: "#4a90e2",
      color: "#f0f8ff",
    },
    selected: {
      color: "white",
      backgroundColor: "blue",
      borderRadius: "100%",
    },
    excluded: {
      color: "white",
      backgroundColor: "#FF7F7F",
    },
  };

  return (
    <div>
      <DayPicker
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        numberOfMonths={numMonths}
        month={start}
        fromMonth={start}
        toMonth={end}
        selectedDays={selectedDays}
        disabledDays={outOfRange}
        onDayClick={handleDayClick}
        showOutsideDays
      />
      <div className="flex flex-col justify-center my-5 mx-24 border-solid border-2 border-gray-300 shadow-inner py-3 px-2">
        <div className="flex flex-row space-x-2 items-center">
          <div className="w-5 h-5 bg-blue-600 rounded-full"></div>
          <p>Appointment Date</p>
        </div>
        <div className="flex flex-row space-x-2 items-center">
          <div className="w-5 h-5 bg-red-400"></div>
          <p>Fully booked Date</p>
        </div>
        <div className="flex flex-row space-x-2 items-center">
          <div className="w-5 h-5 bg-blue-400"></div>
          <p>Start / End Date</p>
        </div>
      </div>
    </div>
  );
};

export default SchedulerCalendar;
