import React from "react";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import PhoneInput from "react-phone-input-2";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import PropertyDataService from "../../services/property.js";
import FormatService from "../../services/format";

import SetupStepper from "../../components/SetupStepper/SetupStepper";
import {
  HomeIcon,
  ClipboardListIcon,
  CreditCardIcon,
  KeyIcon,
  AcademicCapIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import Clock from "react-live-clock";
import WalkthroughChecklist from "../../components/WalkthroughChecklist/WalkthroughChecklist";
import SetupAccess from "../../components/SetupAccess/SetupAccess";
import Task from "../../components/Task/Task";
import Images from "../../components/Images/Images";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline/esm";

const StickyDetails = ({ customer }) => {
  return (
    <>
      <div className="sticky top-20 z-50 min-w-full">
        <div className="flex flex-row justify-center bg-blue-100  px-4 pt-4 ">
          <div className="flex flex-col space-y-4">
            <p className="font-medium text-gray-600 text-xl">Customer Name:</p>
          </div>
          <div className="flex flex-col space-y-4 ml-5">
            <p className="font-bold text-xl">{customer.name}</p>
          </div>
          <div className="flex flex-col space-y-4 ml-5">
            <p className="font-medium text-gray-600 text-xl">Phone:</p>
          </div>
          <div className="flex flex-col space-y-4 ml-5">
            <p className="font-bold text-xl">
              {FormatService.formatPhoneNumber(customer.phone)}
            </p>
          </div>
          <div className="flex flex-col space-y-4 ml-5">
            <p className="font-medium text-gray-600 text-xl">Email Contact:</p>
          </div>
          <div className="flex flex-col space-y-4 ml-5">
            <p className="font-bold text-xl">{customer.email}</p>
          </div>
        </div>
        <div className="flex flex-row justify-center bg-blue-100  px-4 py-4 ">
          <div className="flex flex-col space-y-4">
            <p className="font-medium text-gray-600 text-xl">Current Time:</p>
          </div>
          <div className="flex flex-col space-y-4 ml-5">
            <Clock
              format={"h:mm A, dddd, MMMM Mo, YYYY"}
              ticking={true}
              className="font-bold text-xl"
              timezone={"US/Pacific"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const DetailReview = ({ lockNumber, lockDescription, lockImages, tasks }) => {
  return (
    <div className="flex flex-row justify-around rounded-md p-4 px-6 w-full">
      <div className="w-1/3 mx-5">
        <p className="text-xl text-dark font-bold mb-3">Access Details</p>
        <div className="bg-white rounded-md p-4 px-6 w-full">
          <p className="ml-5 font-medium text-lg text-gray-800">
            {" "}
            Location Description:
            {lockDescription === "" ? (
              <span className="text-red-600 ml-2 italic">
                Missing Location Description
              </span>
            ) : (
              <span className="ml-2">{lockDescription}</span>
            )}
          </p>
          <p className="ml-5 font-medium text-lg text-gray-800">
            {" "}
            Location Image:
            <Images editable={false} presetImages={lockImages} />
          </p>
          <p className="ml-5 font-medium text-lg text-gray-800">
            {" "}
            Loxbox Number:
            {lockNumber === "" ? (
              <span className="text-red-600 ml-2 italic">
                Missing Lock Number
              </span>
            ) : (
              <span className="ml-2">{lockNumber}</span>
            )}
          </p>
        </div>
      </div>

      <div className="w-1/3 mx-5">
        <p className="text-xl text-dark font-bold mb-3">Property Task List</p>
        <div className="bg-white rounded-md p-4 px-6 w-full">
          <ol className="pace-y-2 mt-1">
            {tasks &&
              tasks.map((val) => {
                return <Task val={val} />;
              })}
            {tasks && tasks.length === 0 ? (
              <p className="text-gray-500 ml-4 italic">Task List Empty</p>
            ) : null}
          </ol>
        </div>
      </div>
    </div>
  );
};

const InitialConfirmation = (props) => {
  // const { user } = props;

  const { id } = useParams();
  const location = useLocation();
  const { customer, appt, details } = location.state;
  const [property, setProperty] = React.useState("");

  const history = useHistory();

  const goBack = () => {
    history.push({ pathname: `/home` });
  };

  // const [tasks, setTasks] = React.useState([])
  const [loading, setLoading] = useState(false);

  const { lockDescription, lockNumber, lockImages } = details.access;
  const tasks = details.tasks;

  useEffect(() => {
    for (let i in customer.property) {
      if (customer.property[i]._id == appt.propId) {
        setProperty(customer.property[i]);
      }
    }
  }, []);

  // console.log("customer", customer)
  // console.log("appointment", appt)

  // const saveDetails = () => {
  //     let created = new Date();
  //     const data = {
  //         tasks: tasks,
  //         access: { "lockbox": "29382" },
  //         created: created,
  //         email: customer.email,
  //         appointment: appt
  //     };

  //     setLoading(true);
  //     PropertyDataService.setupProperty(data)
  //         .then(response => {
  //             console.log(response.data);
  //             setLoading(false)
  //             window.location.reload();
  //         })
  //         .catch(e => {
  //             setLoading(false)
  //             console.log(e);
  //         });

  // };

  return (
    <div>
      <StickyDetails customer={customer} />
      <div className="flex flex-col min-h-screen bg-background mx-5">
        <div className="flex sm:justify-center ml-5">
          <div className="sm:w-3/4 mt-10">
            <button
              onClick={() => goBack()}
              className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold flex flex-row items-center"
            >
              <ArrowCircleLeftIcon className="h-5 mr-2" />
              Back to Home
            </button>
          </div>
        </div>

        <div className="bg-white rounded shadow-lg my-8 pb-12 mx-5">
          <div className="mx-8 mt-8 space-y-4">
            <p className="font-bold text-dark text-4xl">
              Initial Appointment Confirmation
            </p>

            <DetailReview
              lockDescription={lockDescription}
              lockNumber={lockNumber}
              lockImages={lockImages}
              tasks={tasks}
            />

            <div className="flex flex-row items-center ml-5">
              <AcademicCapIcon className="h-10 w-10" />
              <p className="font-bold text-dark text-2xl ml-5 ">
                Application Guide
              </p>
            </div>
            <p className="font-bold text-dark text-xl ml-5 ">Setup Payment</p>

            <p className="font-bold text-dark text-xl ml-5 ">
              Create or Cancel Appointment
            </p>
            <p className="font-bold text-dark text-xl ml-5 ">
              Schedule Trip with Routine Appointments
            </p>
            <p className="font-bold text-dark text-xl ml-5 ">
              Change Payment Method
            </p>
            <p className="font-bold text-dark text-xl ml-5 ">Book a Service</p>
            <p className="font-bold text-dark text-xl ml-5 ">
              Review Billing History
            </p>
            <p className="font-bold text-dark text-xl ml-5 ">
              Review Service History
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialConfirmation;
