import FormatService from "../../services/format"
import {

    KeyIcon,
  } from "@heroicons/react/outline";
import Images from "../Images/Images";

const AppointmentAccess = (props) => {
    const { property } = props;
    const {lockDescription, lockImages, lockNumber} = property.access

    const labelStyle = "font-bold text-gray-500 sm:text-xl"
    const valStyle = "sm:text-xl"

    return <>
          <div className="flex flex-row items-center ml-5">
              <KeyIcon className="h-10 w-10" />
              <p className="font-bold text-dark text-xl ml-5 ">Access</p>
            </div>

        <div className="flex flex-col sm:flex-row sm:mx-4 bg-gray-50 sm:bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-4 sm:space-x-4">

            <div className="flex flex-col space-y-4">
                <p className={labelStyle}>Lock Number</p>
            </div>
            <div className="flex flex-col space-y-4 ">
                <p className={valStyle}>{lockNumber}</p>
            </div>
            <div className="flex flex-col space-y-4 ">
                <p className={labelStyle}>Location Descirption</p>
            </div>
            <div className="flex flex-col space-y-4">
                <p className={valStyle}>{lockDescription}</p>
            </div>
            <div className="flex flex-col space-y-4 ">
                <p className={labelStyle}>Images</p>
            </div>
            <div className="flex flex-col space-y-4">
            <Images editable={false} presetImages={lockImages} />
            </div>


        </div>
    </>
}

export default AppointmentAccess;