import React, {useEffect} from "react";
import StripeAddPayment from "../StripeAddPayment/StripeAddPayment";
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import PropertyDataService from "../../services/property"
import SetupForm from "../StripeAddPayment/SetupForm";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import PaymentStatus from "../StripeAddPayment/PaymentStatus";

const AddPayment = (props) => {

    const { user } = props;
    const { propertyId } = useParams();

    // console.log(propertyId)
    // const getInfo = () => {
    //     PropertyDataService.getEmployeeInfo()
    //       .then((response) => {
    //         // console.log("All apointments", response.data)
    //         createCustomerDic(response.data);
    //         parseSearch(response.data["users"]);
    //         setSchedule(response.data.schedule);
    //         // if (customerid) {
    //         //     setValue(customerid)
    //         // }
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   };

    // useEffect(() => {
    //     getInfo();
    //   }, []);

    

    return (
        <div>

            <div className="bg-red mx-0 rounded-t-lg ">
                <h2 className="text-white font-bold ml-8 text-4xl pl-12 py-8">Add a Payment method</h2>

            </div>

            <div className="m-5 flex flex-row">
                <div className="w-1/2">
                    {/* <StripeAddPayment apiAddPayment={apiAddPayment}/> */}
                    {propertyId === "updateSuccess" ? <PaymentStatus user={user} /> : <SetupForm />}
                    
                </div>
                <div className="w-1/2">
                    <div className="flex flex-col rounded-lg border-2 border-blue-400 p-3 mr-10 ml-10">
                        <div className='flex flex-row space-x-2 items-center'>
                            <QuestionMarkCircleIcon className="h-6 w-6 text-blue-500" />
                            <p className="font-medium text-blue-500 text-lg">Privacy Note</p>
                        </div>
                        <div className="flex flex-col ml-4 mt-2">
                            <p>
                                Our payment is handled by Stripe. They handle the majority of online payments. 
                            </p>

                        </div>
                    </div>
                </div>


            </div>


        </div>
    )

};

export default AddPayment;