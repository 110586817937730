import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import ReportList from "../../components/ReportList/ReportList";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import Select from "react-select";
import TimeService from "../../services/time";
import PropertyDataService from "../../services/property";
import BillingList from "../../components/BillingList/BillingList";

const options = [
  { label: "Any time", value: "any" },
  { label: "Past week", value: "week" },
  { label: "Past month", value: "month" },
  { label: "Past year", value: "year" },
];

const Billing = (props) => {
  const { user } = props;

  
  const [property, setProperty] = useState({});
  const [charges, setCharges] = useState([])
 
  const [currentOption, setCurrentOption] = useState({
    label: "Any time",
    value: "any",
  });
  // console.log(user, propertyId, reports)

  useEffect(() => {
    if (user.payment) {
        setProperty(user.property)
        getHistory()
    }
  }, []);


  const getHistory = () => {
    console.log("stripeid", user.payment.stripeCustomerId)
    PropertyDataService.getBillingHistory({id: user.payment.stripeCustomerId})
    .then((response) => {
      console.log(response.data)
      setCharges(response.data.billingHistory)
    })
    .catch((e) => {
      console.log(e);
    });
  }

  const filterCharges = (time) => {
    let before = new Date();
    let newtime;
    // 1000 (ms) x 60 (s) x 60 (m) x 24 (hrs) * 365
    let year = 1000 * 60 * 60 * 24 * 365;

    let month = 1000 * 60 * 60 * 24 * 30;

    let week = 1000 * 60 * 60 * 24 * 7;

    switch (time) {
      case "any":
        return;
        break;
      case "week":
        newtime = before - week;
        break;
      case "month":
        newtime = before - month;
        break;
      case "year":
        newtime = before - year;
        break;
    }

    newtime = new Date(newtime);

    let viewable = [];
    console.log(property.reports[0]);
    for (let i in property.reports) {
      if (TimeService.isBefore(property.reports[i].completedTime, newtime)) {
        viewable.push(property.reports[i]);
      }
    }
    console.log(viewable);
  };

  const history = useHistory();

  const goHome = () => {
    history.push({ pathname: `/overview` });
  };

  const makeChange = (e) => {
    setCurrentOption(e);
    // filterReports(e.value);
  };

  return (
    <div className="flex flex-col  min-h-screen bg-background">
      <div className="mt-10 mx-5 sm:mx-8">
        <button
          onClick={() => goHome()}
          className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold flex flex-row items-center"
        >
          <ArrowCircleLeftIcon className="h-5 mr-2" />
          Back to Home
        </button>
      </div>

      <div className="flex justify-center mx-5 sm:mx-8">
        <div className="flex flex-col max-w-full w-full sm:text-left bg-white rounded shadow-lg my-8 pb-12">
          <div className="flex flex-col sm:flex-row sm:items-center">
            <p className="m-10 mb-3 sm:mb-10 font-bold text-2xl text-dark font-inter">
              Billing History
            </p>
            <div className="sm:w-1/3 flex flex-row items-center ml-10 sm:ml-0">
              <p className="font-inter ">Filter:</p>
              <Select
                className="px-6 w-4/5"
                options={options}
                onChange={(e) => makeChange(e)}
                value={currentOption}
              />
            </div>
          </div>

          {charges && charges.length > 0 ? (
            <BillingList charges={charges} user={user} />
          ) : (
            <p className="text-center text-lg text-dark">No Reports</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Billing;
