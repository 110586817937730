import React from "react";
import { useState, useEffect } from "react";
import PropertyDataService from "../../services/property.js";
import CustomerSearch from "../../components/CustomerSearch/CustomerSearch";
import CustomerSelect from "../../components/CustomerSelect/CustomerSelect.js";
import CustomerAppointments from "../../components/CustomerAppointments/CustomerAppointments.js";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { ClipboardListIcon, UserIcon } from "@heroicons/react/outline";
import EmploySchedule from "../../components/EmploySchedule/EmploySchedule.js";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";

const EmployHome = (props) => {
  const { user } = props;
  const [customerInfo, setCustomerInfo] = React.useState("");
  const [searchInfo, setSearchInfo] = React.useState("");
  const [selected, setSelected] = React.useState("");
  const [propSelected, setPropSelected] = React.useState("");
  const { customerId } = useParams();

  const [schedule, setSchedule] = useState([]);

  const [addAppointment, setAddAppointment] = useState(false);
  const [showAppointments, setShowAppointments] = useState(true);

  const [switcher, setSwitcher] = useState("profile");

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (customerInfo) {
      if (customerId) {
        setValue(customerId);
      }
    }
  }, [customerInfo]);

  const setValue = (val) => {
    setSelected(customerInfo[val]);
    setPropSelected(customerInfo[val].property[0]);
  };


  const createCustomerDic = (data) => {
    let custInfo = {};
    for (let i in data["users"]) {
      // create the object in the dictionary
      custInfo[data["users"][i]._id] = data["users"][i];
      // creat a schedule
      custInfo[data["users"][i]._id].schedule = {};
    }
    for (let y in data["schedule"]) {
      let cur = data["schedule"][y];
      
      if (!(cur.clientId in custInfo)) {
        continue
      }
      
      // make sure that the key exists to seperate each property
      if (!(cur.propId in custInfo[cur.clientId].schedule)) {
        custInfo[cur.clientId].schedule[cur.propId] = [];
      }
      custInfo[cur.clientId].schedule[cur.propId].push(cur);
    }

    setCustomerInfo(custInfo);
  };

  const parseSearch = (info) => {
    let newSearchInfo = [];
   
    for (let i in info) {
      let cur = info[i];
      if (cur.type === "client") {
        newSearchInfo.push({
          _id: cur._id,
          name: cur.name,
          phone: cur.phone,
          email: cur.email,
        });
      }
    }
    setSearchInfo(newSearchInfo);
  };

  const getInfo = () => {
    PropertyDataService.getEmployeeInfo()
      .then((response) => {
        // console.log("All apointments", response.data)
        createCustomerDic(response.data);
        parseSearch(response.data["users"]);
        setSchedule(response.data.schedule);
        // if (customerid) {
        //     setValue(customerid)
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="flex flex-col lg:flex-row justify-center">
        <div className="p-4 lg:m-10 lg:w-1/3">
          {searchInfo !== "" ? (
            <CustomerSearch customers={searchInfo} setSelected={setValue} />
          ) : null}
          <div className="bg-white rounded-t-md flex flex-col lg:mt-10">
            <div className="flex flex-row">
              <p className="text-xl text-dark font-bold py-4 pl-4">Schedule</p>
              <button
                className="focus:outline-none"
                onClick={() => setShowAppointments(!showAppointments)}
              >
                {showAppointments ? (
                  <ChevronUpIcon className="h-10 text-gray-500 ml-2 hover:text-gray-700 " />
                ) : (
                  <ChevronDownIcon className="h-10 text-gray-500 ml-2 hover:text-gray-700 " />
                )}
              </button>
            </div>
            {showAppointments ? (
              <EmploySchedule schedule={schedule} customerInfo={customerInfo} />
            ) : null}
          </div>
        </div>

        {selected !== "" ? (
          <div className="lg:w-1/2 flex flex-col mx-3 sm:mx-0">
            {/* <div className="flex flex-row bg-white rounded-t-md mt-10">
              <button
                onClick={() => setSwitcher("profile")}
                className={
                  (switcher === "profile" ? "bg-white" : "bg-gray-200") +
                  " focus:outline-none py-2 px-5 w-1/2 rounded-t-lg font-bold text-xl font-inter text-dark flex justify-center items-center space-x-2"
                }
              >
                <UserIcon className="h-8 text-dark" />
                <p>Profile</p>
              </button>
              <button
                onClick={() => setSwitcher("appointments")}
                className={
                  (switcher === "appointments" ? "bg-white" : "bg-gray-200") +
                  " focus:outline-none py-2 px-5 w-1/2 rounded-t-lg font-bold text-xl font-inter text-dark flex justify-center items-center sm:space-x-2"
                }
              >
                <ClipboardListIcon className="h-8 text-dark" />
                <p>Appointments</p>
              </button>
            </div> */}
            {/* && switcher === "profile" */}
            {selected !== "" ? (
              <div className="rounded bg-white mt-10">
                <div className="bg-white rounded-t-md ">
                  <p className="text-xl text-dark font-bold py-4 pl-4">
                    Customer Profile
                  </p>
                 
                </div>
                <CustomerSelect
                  customer={selected}
                  setProperty={setPropSelected}
                />
               
              </div>
            ) : null}

            {/* switcher === "appointments" */}
            {selected !== "" && propSelected !== "" ? (
              <div className="rounded bg-white mt-3">
                <div className="bg-white rounded-t-md flex flex-row items-center">
                  <p className="text-xl text-dark font-bold py-4 pl-4">
                    Appointments
                  </p>
                  <div>
                    <button
                      className={
                        (addAppointment
                          ? "border-red text-white hover:text-red hover:bg-white bg-red"
                          : "border-dark text-dark hover:text-white hover:bg-dark hover:border-white ") +
                        " border-solid border-2 font-semibold rounded-md text-lg ml-5 p-1 px-2"
                      }
                      onClick={() => setAddAppointment(!addAppointment)}
                    >
                      {addAppointment ? "Cancel" : "Create New"}
                    </button>
                  </div>
                  {selected.payment.paymentError && selected.payment.paymentError.error ?  <p className=" bg-red-50 text-l border-solid rounded-lg border-red-100 border-2 p-2 text-red font-semibold ml-4">
                    Error: Payment Failed
                  </p> : null}
                 
                </div>
                <CustomerAppointments
                  customer={selected}
                  selectedProperty={propSelected}
                  employeeInformation={user}
                  add={addAppointment}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EmployHome;
