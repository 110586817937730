import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BookAppointment from "../../components/BookAppointment/BookAppointment";
import Scheduler from "../../components/Scheduler/Scheduler";
import PropertyCheck from "../PropertyCheck/PropertyCheck";
import Images from "../../components/Images/Images";
import TextLoop from "react-text-loop";
import house from "../../assets/house.jpeg";
import LandingStep from "../../components/LandingStep/LandingStep";
import LoginButton from "../../components/LoginButton/LoginButton";
import { ChartSquareBarIcon } from "@heroicons/react/outline";

const Scratch = (props) => {
  const { user } = props;

  console.log(user);

  return (
    <div className="flex flex-row justify-center min-h-screen bg-background">
      {user ? (
        <div className="bg-red h-48 mt-20 hover:bg-gray-200 rounded-lg shadow-sm">
          <p className="text-white font-bold px-8 py-4">
            {user.name} is the owner of the box
          </p>
          <div className="bg-white border-2 border-solid border-dark mx-5">
            <p>This is the description of the box</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Scratch;
