import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history.js";


ReactDOM.render(
    <BrowserRouter>
        <Auth0ProviderWithHistory
        >
            <App />
        </Auth0ProviderWithHistory>
    </BrowserRouter>, document.getElementById('root')
);

