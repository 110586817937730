export const GeneralTaskList = [
    {task:"Plumbing",
    taskNote:"- Run all faucets and watch for slow draining<br>- Flush toilets for proper flushing<br>- Check laundry room, hot water heater, water softeners, dishwashers, garbage disposals and pool pumps for water leakage "},
    {task:"Exterior",
    taskNote:"- Check for vandalism , property damage , break in attempts , and property theft<br>- Ants , pests, rodents ,visible termite damage , and animal overtake<br>- Landscape irrigation leaks, dead plants , maintenance being done by contractor  is getting done on time , in fashion and correctly to your standards. "},
    {task:"Interior",
    taskNote:"- On the lookout for funny/foul smells<br>- Air filter spot checks<br>- Fire alarm battery tests<br>- Any suspicious standouts that alert our property watchers "},
    {task:"Electrical",
    taskNote:"Make sure there are no shortages or power outages on the property by doing a random spot check"}
]
export const phoneNumbers = {
    "YUMA": "(928)920-4635"
}

export const Regions = [
    {
        code: "YUM",
        zipcodes: ["85364","85365","85367"],
        coverage: 1
    }
]