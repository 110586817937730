import React, { useState, useEffect } from "react";
import { ChevronUpIcon, SearchIcon } from "@heroicons/react/solid";
import FormatService from "../../services/format";
import Select from "react-select";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";

const appointmentBox = (val, customer) => {
  return (
    <div className="rounded bg-gradient-to-b from-gray-100 to-gray-200 shadow-sm m-2 ml-8 p-5">
      <div className="flex flex-row">
        <div>
          <p className="font-medium p-2">
            {val.type.charAt(0).toUpperCase() + val.type.slice(1)} Appointment
          </p>
          <div className="flex flex-row">
            <p className="font-medium ml-2">Date:</p>
            <p className="font-bold ml-4">
              {FormatService.getFormatedDate(val.dateTime)}
            </p>
          </div>
          <div className="flex flex-row">
            <p className="font-medium ml-2">Time:</p>
            <p className="font-bold ml-4">
              {FormatService.getTimefromDate(val.dateTime)}
            </p>
          </div>
        </div>
        <div>
          <Link
            className="p-3 m-4 bg-gradient-to-b from-blue-300 to-blue-600 rounded text-white"
            to={{
              pathname: "/initial/" + val._id.toString(),
              state: { customer: customer, appt: val },
            }}
          >
            Start
          </Link>
          {/* <Link className="p-3 m-4 bg-gradient-to-b from-blue-300 to-blue-600 rounded text-white" href={"/initial/"+val._id.toString()} >Start</Link> */}
        </div>
      </div>
    </div>
  );
};

const CustomerSelect = (props) => {
  const { customer, setProperty } = props;

  const [curProp, setCurProp] = useState(null);

  const [curPropInfo, setCurPropInfo] = useState("");

  const [options, setOptions] = useState("");

  useEffect(() => {
    setOptions(createOptions(customer.property));
  }, [customer]);

  const createOptions = (property) => {
    let newOptions = [];
    for (const prop in property) {
      if (prop == 0) {
        setCurPropInfo(property[prop]);
        setProperty(property[prop]);
      }

      let addy =
        property[prop].street +
        " " +
        property[prop].city +
        ", " +
        property[prop].state +
        " " +
        property[prop].zip;
      newOptions.push({ value: property[prop]._id, label: addy });
    }
    setCurProp(newOptions[0]);

    return newOptions;
  };

  // console.log(curProp)

  const history = useHistory();

  const gotoPropertyProfile = (propId) => {
    history.push({
      pathname: `${window.location.pathname}/edit/${curProp.value}`,
    });
  };

  const changeVal = (e) => {
    setCurProp(e);
    for (let i in customer.property) {
      if (customer.property[i]._id == e.value) {
        setProperty(customer.property[i]);
        setCurPropInfo(customer.property[i]);
      }
    }
  };

  return (
    <div className="flex flex-col sm:flex-row max-w-full">
      <div className="flex flex-row sm:w-1/2">
        <div>
          <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
            Name:
          </p>
          <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
            Email:
          </p>
          <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
            Phone:
          </p>
          <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
            Last Updated:
          </p>
        </div>
        <div>
          <p className="my-2 ml-3 font-bold text-lg"> {customer.name}</p>
          <p className="my-2 ml-3 font-bold text-lg"> {customer.email}</p>
          <p className="my-2 ml-3 font-bold text-lg">
            {" "}
            {FormatService.formatPhoneNumber(customer.phone)}
          </p>
          <p className="my-2 ml-3 font-bold text-lg">
            {" "}
            {FormatService.getFormatedDate(customer.updated)}{" "}
            {FormatService.getTimefromDate(customer.updated)}
          </p>
        </div>
      </div>

      <div className="sm:w-1/2">
        <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
          Select Property:
        </p>
        {curProp && curProp !== null ? (
          <Select
            options={options}
            value={curProp}
            className="ml-4"
            onChange={changeVal}
          />
        ) : null}
        {/* https://www.google.com/maps/dir/?api=1&1850%20W%2014th%20St%20Yuma,%20Arizona%2085364 */}
        {/* <a
          target="_blank"
          style="background: #F44336;  border-radius: 25px; font-weight: bolder; color: white; padding: 15px 25px; text-decoration: none;"
          href="${details.redirectUrl}"
        >
          Get Directions
        </a> */}
        {curProp && curProp !== null ? (
          <div className="flex mt-3">
            {/* {FormatService.getFormatedURL(curProp.label)} */}
            <a
              target="_blank"
              className="ml-5 p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
              href={`https://www.google.com/maps/dir/?api=1&destination=${FormatService.getFormatedURL(curProp.label)}`}
            >
              Get Directions
            </a>
          </div>
        ) : null}
        <div className="flex flex-row mt-3">
          <div>
            <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">
              Setup Complete:
            </p>
          </div>
          <div>
            {curPropInfo ? (
              <p className="my-2 ml-3 font-bold text-lg">
                {" "}
                {curPropInfo.setupComplete.toString()}
              </p>
            ) : null}
          </div>
          <div>
            <button
              className="ml-5 p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
              onClick={() => gotoPropertyProfile()}
            >
              Edit Property
            </button>
          </div>
        </div>
      </div>

      {/* <div>
                <p className="my-2 ml-4 text-dark font-medium font-inter text-lg">Appointments:</p>
                {curPropInfo ? customer.schedule[curPropInfo._id].map((val) => {
                    return appointmentBox(val, customer)
                }) : null}
            </div> */}
    </div>
  );
};

export default CustomerSelect;
