import React, {useState, useEffect} from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import AuthenticationButton from "../AuthenticationButton/AuthenticationButton.js";
import { MenuIcon } from "@heroicons/react/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { PhoneIcon } from "@heroicons/react/solid";

export default function Header({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  let getUrl = window.location;
  let home = getUrl.protocol + "//" + getUrl.host + "/";

  const { loginWithRedirect } = useAuth0();

  
  // const [titleElement, setTitleElement] = useState(null);
  // const [roadmapElement, setRoadmapElement] = useState(null);
  const [howItWorks, setHowItWorks] = useState(null);
  const [pricing, setPricing] = useState(null);


  useEffect(() => {
    setHowItWorks(document.getElementById("howitworks"))
    setPricing(document.getElementById("pricing"))
   
  }, [])

  return (
    <nav className="sticky top-0 z-50 flex flex-wrap items-center justify-between  max-w-screen">
      <div className="max-w-screen-2xl bg-black px-8 pt-3 py-2 container mx-auto flex justify-between flex-row items-start md:items-center">

          <div className="flex flex-row pl-3 items-center space-x-4">
            <PhoneIcon className="text-white h-8" />
            <p className="text-white font-inter mr-4">(928) 920-4635</p>
          </div>

        <div className="flex flex-row pr-3 items-center">
          <p className="text-white font-inter mr-4 hidden md:block">Already a member?</p>
          <button
            className="text-white font-inter font-semibold text-lg"
            onClick={() => loginWithRedirect()}
          >
            Login
          </button>
        </div>
      </div>
      <div className="max-w-screen-2xl  bg-white px-8 pt-3 py-2 rounded-b-xl  container mx-auto flex flex-wrap items-center ">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="flex flex-row items-center">
            <a
              className="text-sm font-bold inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              href={home}
            >
              <img src={logo} className="h-14 relative" />
            </a>
          </div>
          <button
            className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <MenuIcon className="h-10 text-gray-700 hover:text-gray-400" />
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center" +
            (navbarOpen ? " flex" : " hidden")
          }
          id="example-navbar-danger"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto lg:space-x-6">
            <li className="nav-item">
              <div className="group">
                <button
                  className="px-3 pt-2 text-gray md:text-black md:pb-6 flex items-center text-xs border-b-2 border-transparent transition duration-300 lg:hover:border-red lg:hover:text-red-light font-inter font-medium bg-transparent md:w-auto md:inline md:mt-0 md:ml-4 focus:outline-none"
                  onClick={() => howItWorks && howItWorks.scrollIntoView({ behavior: "smooth" })}
                >
                  <span className="mx-2">How It Works</span>
                </button>
              </div>
            </li>

            <li className="nav-item">
              <div className="group">
                <button
                  className="px-3 pt-2 text-gray md:text-black md:pb-6 flex items-center text-xs border-b-2 border-transparent transition duration-300 lg:hover:border-red lg:hover:text-red-light font-inter font-medium bg-transparent md:w-auto md:inline md:mt-0 md:ml-4 focus:outline-none"
                  onClick={() => pricing && pricing.scrollIntoView({ behavior: "smooth" })}
                >
                  <span className="mx-2">Pricing</span>
                </button>
              </div>
            </li>
            <li>
              <AuthenticationButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
