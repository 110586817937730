import React, {useState, useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import PropertyDataService from "../../services/property";
import Stepper from "react-stepper-horizontal";


const SetupStepper = (props) => {

    const {step} = props;

    return (<div className="hidden md:flex flex-col w-max-full">
        <div className="flex flex-row justify-center">
            <p className="text-gray-400 font-medium  font-inter text-lg"> Preparing your home for your future trips.</p>
        </div>
        <Stepper steps={ [{title: 'Add your first Property'}, {title: 'Schedule initial visit'}, {title: 'Prepare for your Appointment'}] }
                 activeStep={ step }
                 completeColor="#34D399"
        />
    </div>)
};

export default SetupStepper;