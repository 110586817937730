import { add } from "lodash";
import React, { useState, useEffect } from "react";
import FileUploader from "./FileUploader";
import { XCircleIcon } from "@heroicons/react/solid";
import axios from "axios";
import { CloudUploadIcon, CheckCircleIcon } from "@heroicons/react/outline";

const ImageContainer = (props) => {
  const { url, numImages, remove, editable, height } = props;

  const uploaded = url.includes("cloudinary")

  // console.log("uploaded")

  return (
    <div className={(numImages > 1 ? "w-1/2" : "") + " relative"}>
      {editable ?   <div className={(uploaded ? "text-green-500" : "text-red") + " absolute top-2 left-2 flex flex-row items-center bg-gray-200 rounded-md"}>
        <p className="font-inter font-semibold">uploaded</p>
        {uploaded ? <CheckCircleIcon className="h-5" /> : <XCircleIcon className="h-5"/>}
      </div> : null}
      {editable ?  <button className="absolute top-2 right-2" onClick={() => remove()}>
        <XCircleIcon className="h-8 text-black hover:text-dark" />
      </button> : null }
      <img src={url} alt={"Image Preview"} className={`max-h-${height}`}  />
    </div>
  );
};

/*

Example usage:
<Images
              setUploadedImages={setNewTaskImages}
              changeReadiness={setReadyToSave}
              presetImages={newTaskImages}
              editable={true}
            />

setUploadedImages => setter of a hook of an array of strings, [URLString, URLString], this stores the cloudinary strings that should be saved

changeReadiness => setter of a hook type boolean, this will change if the user adds a photo but does not upload it

presetImages => this is an array of preset images or the hook constant of the url images. It can be cleared to clear the area

editable => constant (true or false), it allows images to be changed and uploaded

** for only viewing you can give array of images and editable false **

<Images editable={false} presetImages={task.images} />

*/


const Images = (props) => {
  const {setUploadedImages, changeReadiness, presetImages, editable, height} = props;

  const [imageUrls, setImageUrls] = useState([]);
  // const [imageUploadedUrls, setImageUploadedUrls] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  const [loading, setLoading] = useState(false)

  const [needUpload, setNeedUpload] = useState(false)

  const [imageHeight, setImageHeight] = useState(40)

  useEffect(() => {
   if (presetImages) {
     setImageUrls(presetImages)
     setImageFiles(presetImages)
   }
  }, [presetImages])

  useEffect(() => {
   if (height) {
     setImageHeight(height)
   }
  }, [height])

  const handleChange = (file) => {
    if (!file) {
      // console.log("bad image");
      return;
    }
    changeReadiness(false)
    setNeedUpload(true)
    let addImageFiles = [...imageFiles];
    let addImage = [...imageUrls];
    addImage.push(URL.createObjectURL(file));
    addImageFiles.push(file);
    setImageUrls(addImage);
    setImageFiles(addImageFiles);
  };

  const removeImage = (index) => {
    // assigning the list to temp variable
    const temp = [...imageUrls];
    const temp2 = [...imageFiles];

    // removing the element using splice
    temp.splice(index, 1);
    temp2.splice(index, 1);

    // updating the list
    setImageUrls(temp);
    setImageFiles(temp2);

    if (temp.length === 0) {
      changeReadiness(true)
      setNeedUpload(false)
    }
  };

  const multiFileUpload = (files) => {
    
    // Push all the axios request promise into a single array
    const uploadedFileUrl = [];
    setLoading(true)
    const uploaders = files.map((file, index) => {
      
      if (imageUrls[index].includes("cloudinary")) {
        uploadedFileUrl.push(imageUrls[index]);
        return
      }
      // Initial FormData
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("tags", `codeinfuse, medium, gist`);
      formData.append("upload_preset", "pcypuwex"); // Replace the preset name with your own
      // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
      formData.append("timestamp", (Date.now() / 1000) | 0);

      // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
      return axios
        .post(
          "https://api.cloudinary.com/v1_1/property-watchers/image/upload",
          formData,
          {
            headers: { "X-Requested-With": "XMLHttpRequest" },
          }
        )
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url; // You should store this URL for future references in your app
          uploadedFileUrl.push(fileURL);
          // console.log(data, "uploaded one");
        });
    });

    // Once all the files are uploaded
    axios.all(uploaders).then(() => {
      // console.log(uploadedFileUrl);
      // setImageUploadedUrls(uploadedFileUrl);
      setUploadedImages(uploadedFileUrl)
      changeReadiness(true)
      setNeedUpload(false)
      setImageUrls(uploadedFileUrl)
      setLoading(false)
    });

    
  };

  const uploadImage = () => {
    if (imageFiles.length === 0) {
      return;
    }
    const data = new FormData();
    data.append("file", imageFiles[0]);
    data.append("upload_preset", "pcypuwex");
    data.append("cloud_name", "property-watchers");
    fetch("  https://api.cloudinary.com/v1_1/property-watchers/image/upload", {
      method: "post",
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data.url);
        // setImageTest(data.url);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="">
      <div className="flex flex-row">
        {imageUrls.length > 0 ? (
          <ImageContainer
            url={imageUrls[0]}
            numImages={imageUrls.length}
            remove={() => removeImage(0)}
            editable={editable}
            height={imageHeight}
          />
        ) : null}
        {imageUrls.length > 1 ? (
          <ImageContainer
            url={imageUrls[1]}
            numImages={imageUrls.length}
            remove={() => removeImage(1)}
            editable={editable}
            height={imageHeight}
          />
        ) : null}
      </div>
      {editable ? <div className="flex flex-row items-center space-x-5">
        {imageUrls.length > 1 ? (
          <p className="font-inter text-lg font-semibold">
            Remove an Image to add another
          </p>
        ) : (
          <div className="">
            <FileUploader handleFile={handleChange} />
          </div>
        )}
        {(imageUrls.length > 0 && needUpload) ? (
          <button
            className="flex flex-row bg-dark text-white mt-3 font-bold text-lg 
                                py-1 px-3 rounded-md items-center border-solid border-2
                                hover:bg-white hover:text-dark
                                "
            onClick={() => multiFileUpload(imageFiles)}
          >
            {loading ? <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6"/> : <CloudUploadIcon className="w-5 h-5 mr-5" />}
            Upload
          </button>
        ) : null}
      </div> : null}
      
    </div>
  );
};

export default Images;
