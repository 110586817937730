import React from "react";

function Step({ info, background, className, children }) {
  const { number, title, bullets } = info;
  return (
    <div className={className + "  m-5 md:w-1/3 h-auto font-inter"}>
      <div className="flex md:flex-col flex-row justify-center">

        <div className="flex flex-col md:mt-10 mt-4 items-center justify-center w-1/4 md:w-auto">
          <div>
            <div className="w-10 h-10 md:w-20 md:h-20 rounded-full bg-gradient-to-r from-red to-red-grad flex justify-center items-center">
              <p className="font-bold text-xl md:text-4xl text-center text-white ">
                {number}
              </p>
            </div>
          </div>
          <p className="font-semibold text-2xl text-center my-5">{title}</p>
        </div>

        <div className="flex flex-col mx-10 items-center justify-center bg-white rounded-t-xl px-10 py-7 ">
          {/* <ul className="space-y-3 list-none">
            {bullets.map((bullet, index) => (
              <li className="" key={index}>
                <p className="font-">{bullet}</p>
              </li>
            ))}
          </ul> */}
          {children}
        </div>
      </div>
    </div>
  );
}

export default Step;
