import React from "react";
import "./LandingPage.css";

import TopSection from "./TopSection.js"
import HowItWorksSection from "./HowItWorksSection";
import PricingSection from "./PricingSection";

const LandingPage = (props) => {

  const {user} = props;

  return (
    <div className="flex flex-col min-h-screen  bg-white ">
      <TopSection/>
      <div id="howitworks" />
      <HowItWorksSection />
      <div id="pricing" />
      <PricingSection />
    </div>
  );
}

export default LandingPage;
