import React, { useEffect, useState } from "react";
import TimeService from "../../services/time";
import FormatService from "../../services/format";
import PropertyDataService from "../../services/property";

import {
  InformationCircleIcon,
  CheckIcon,
  PlusCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";

import SchedulerContact from "../Scheduler/SchedulerContact";
import Task from "../Task/Task";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import Images from "../Images/Images";
import InformationPopup from "../InformationPopup/InformationPopup";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";

const AppointmentSelected = ({
  userType,
  appointment,
  user,
  currentProperty,
}) => {
  const { type, dateTime, contact, note } = appointment;
  const [currentPropObj, setCurrentPropObj] = useState({});
  const [tasks, setTasks] = useState([]);

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const [loading, setLoading] = useState(false);

  const [editable, setEditable] = useState(false);

  const [editedContact, setEditedContact] = useState(contact);
  const [editedNote, setEditedNote] = useState(note);

  const [imagesReady, setImagesReady] = useState(true);
  const [editError, setEditError] = useState(false);

  const [showPropertyTaskList, setShowPropertyTaskList] = useState(false);

 

  // This effect hook sets the state hook of the current property object so we can use it
  // in the render method below
  useEffect(() => {
    if (user && currentProperty) {
      setCurrentPropObj(getProperty(user, currentProperty));
      if (note && note.task) {
       
        setEditedNote(note);
      } else {
        
        setEditedNote({ task: "", taskNote: "" });
      }
    }
  }, [user, currentProperty, note]);

  const history = useHistory();

  const gotoPropertyProfile = (propId) => {
    history.push({ pathname: `/property/${propId}` });
  };

  const getProperty = (user, currentProperty) => {
    let properties = user.property;
    for (let i in properties) {
      if (currentProperty === properties[i]._id) {
        return properties[i];
      }
    }
    return null;
  };

  const cancel = () => {
    const emailData = {
      address: FormatService.formatAddress(currentPropObj),
      name: user.name,
      note: note,
      dateTime: FormatService.getFormatedAppointment(dateTime),
      customerEmail: user.email,
    };

    const data = {
      id: appointment._id,
      canceledBy: user._id,
      dateTime: new Date(),
      emailData: emailData,
    };

    PropertyDataService.cancelAppointment(data)
      .then((response) => {
        
        history.push({ pathname: `/overview` });
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // this function returns the property from the property array in a user object that
  // matches the id of the currentProperty prop variable

  const changeEdit = (name) => {
    switch (name) {
      case "edit":
        if (editable) {
          // now its save
          if (didChange()) {
            if (!imagesReady) {
              setEditError(true);
              
              return;
            }

           
            const data = {
              id: appointment._id,
              note: editedNote,
              contact: editedContact,
            };

            setLoading(true);
            PropertyDataService.putAppointment(data)
              .then((response) => {
              
                // setLoading(false)
                // setEditable(false)
                // goHomeWithAppointment(appointment._id)
                window.location.reload(false);
              })
              .catch((e) => {
                setLoading(false);
                console.log(e);
              });

            // save
          } else {
            // switch back to normal
            setEditable(false);
          }
        } else {
          setEditable(true);
        }
        break;
      case "cancel":
        setEditable(false);
        if (note) {
          setEditedNote({
            task: note.task || "",
            taskNote: note.taskNote || "",
            images: note.images,
          });
          setEditError(false);
        }

        break;
    }
  };

  const makeChange = (change, type) => {
    switch (type) {
      case "task":
        setEditedNote({
          task: change,
          taskNote: editedNote.taskNote,
          images: editedNote.images,
        });
        break;
      case "taskNote":
        setEditedNote({
          task: editedNote.task,
          taskNote: change,
          images: editedNote.images,
        });
        break;
      case "images":
        setEditError(false);
        setEditedNote({
          task: editedNote.task,
          taskNote: editedNote.taskNote,
          images: change,
        });
        break;
    }
  };

  const didChange = () => {
    console.log(contact);
    console.log(editedContact);
    if (contact.call !== editedContact.call) {
     
      return true;
    }
    if (contact.text !== editedContact.text) {
      
      return true;
    }
    if (contact.email !== editedContact.email) {
      
      return true;
    }
    if (editedNote.task !== note.task) {
      
      return true;
    }
    if (editedNote.taskNote !== note.taskNote) {
     
      return true;
    }
    if (equalLists(editedNote.images, note.images)) {
      
      return true;
    }
    return false;
  };

  const equalLists = (a, b) => {
    if (a.length !== b.length) {
      return false;
    }

    for (let i in a) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    return true;
  };

  const addFortyFive = (time) => {
    let oldTime = new Date(time);
    let fortyFiveMore = 45 * 60 * 1000 + oldTime.getTime();
    let newTime = new Date(fortyFiveMore);
    return newTime;
  };

  let typeString = "";
  switch (type) {
    case "initial":
      typeString = "Initial Appt.";
      break;
    case "propertycheck":
      typeString = "Property Checkup";
      break;
  }

  return (
    <div className="mx-8">
      {showCancelConfirmation ? (
        <ConfirmationModal
          setShowModal={setShowCancelConfirmation}
          message={"Are you sure you want to cancel this appointment?"}
          func={() => cancel()}
        />
      ) : null}

      {userType === "customer" ? (
        <div className="flex flex-col sm:flex-row justify-between sm:space-x-5 space-y-3 sm:space-y-0">
          <div className="border-b-2 border-solid border-gray-100 sm:w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">
              Address
            </p>
            <p className="text-dark font-inter font-medium text-lg">
              {FormatService.formatAddress(currentPropObj)}
            </p>
          </div>
          <div className="border-b-2 border-solid border-gray-100 sm:w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Type</p>
            <p className="text-dark font-inter font-medium text-lg">
              {typeString}
            </p>
          </div>
        </div>
      ) : null}

      {userType === "customer" ? (
        <div className="flex flex-col sm:flex-row justify-between sm:space-x-5 space-y-3 sm:space-y-0">
          <div className="border-b-2 border-solid border-gray-100 sm:w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Date</p>
            <p className="text-dark font-inter font-medium text-lg">
              {FormatService.getFormatedDate(dateTime)}
            </p>
          </div>
          <div className="border-b-2 border-solid border-gray-100 sm:w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Time</p>
            <p className="text-dark font-inter font-medium text-lg">
              {FormatService.getTimefromDate(dateTime)} to{" "}
              {FormatService.getTimefromDate(addFortyFive(dateTime))}
            </p>
          </div>
        </div>
      ) : null}

      {userType === "customer" ? (
        <div className="flex flex-row justify-between space-x-5 mt-3">
          <div>
            <div className=" flex flex-row items-center">
              <p className="text-gray-400 font-inter font-bold text-lg flex flex-row items-center">
                Property Task List
              </p>
              <button
                className="focus:outline-none"
                onClick={() => setShowPropertyTaskList(!showPropertyTaskList)}
              >
                {showPropertyTaskList ? (
                  <ChevronUpIcon className="h-10 text-gray-500 ml-2 hover:text-gray-700 " />
                ) : (
                  <ChevronDownIcon className="h-10 text-gray-500 ml-2 hover:text-gray-700 " />
                )}
              </button>

              <InformationPopup>
                <div className="space-y-2 font-inter">
                  <p className="">
                    Property tasks are those completed at each visit.
                  </p>
                  <p>
                    They can be changed in the property profile, or by clicking
                    edit
                  </p>
                </div>
              </InformationPopup>
              <button
                className="text-blue-300 underline italic ml-1 text-sm font-normal"
                onClick={() => gotoPropertyProfile(currentPropObj._id)}
              >
                Edit
              </button>
            </div>
            {showPropertyTaskList ? (
              <ol className="pace-y-2 mt-1">
                {currentPropObj.tasks &&
                  currentPropObj.tasks.map((val, index) => {
                    return <Task val={val} key={index} />;
                  })}
                {currentPropObj.tasks && currentPropObj.tasks.length === 0 ? (
                  <p className="text-gray-500 ml-4 italic">Task List Empty</p>
                ) : null}
              </ol>
            ) : null}
          </div>
        </div>
      ) : null}
      {userType === "customer" ? (
        <div className="flex justify-center mt-10 mb-5 mx-10 border-b-2 border-solid border-gray-300"></div>
      ) : null}

      {editable ? (
        <button
          className="float-right py-2 px-4 w-24 bg-gray-400 text-white font-bold text-lg 
          rounded-md border-solid border-2 hover:bg-white hover:text-gray-400 hover:border-gray-400"
          onClick={() => changeEdit("cancel")}
        >
          Cancel
        </button>
      ) : null}
      <button
        className={
          (loading ? "flex flex-row w-32 items-center" : "w-20") +
          " float-right py-2 px-4 w-20 mr-2 bg-blue-400 text-white font-bold text-lg rounded-md border-solid border-2 hover:bg-white hover:text-blue-400 hover:border-blue-400"
        }
        onClick={() => changeEdit("edit")}
      >
        {loading ? (
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
        ) : null}
        {editable ? "Save" : "Edit"}
      </button>

      {/* <button
              className="flex flex-row bg-blue-400 text-white font-bold text-lg 
                               rounded-md border-solid border-2
                                hover:bg-white hover:text-blue-400 hover:border-blue-400
                                "
                                onClick={() => changeEdit("edit")}
            >
               {loading ? (
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
        ) : null}
        {editable ? "Save" : "Edit"}
            </button> */}

      <div className="space-y-5">
        <div>
          <p className="text-gray-400 font-inter font-bold text-lg mb-4">
            Appointment Contact Preference
          </p>
          <div className="flex justify-center sm:justify-start">
            <SchedulerContact
              presets={contact}
              setContact={setEditedContact}
              nonEditable={!editable}
            />
          </div>
        </div>
        <div>
          <p className="text-gray-400 font-inter font-bold text-lg mb-4">
            Appointment Specific Task
          </p>
          {editedNote.task === "" && !editable ? (
            <p className="mx-2 font-semibold bg-gray-50 rounded-lg shadow-sm px-1 py-2">
             Add a task using the edit button above.
            </p>
          ) : (
            <>
              {" "}
              <textarea
                rows={2}
                cols={5}
                className="p-2 tracking-wide w-5/6 font-medium rounded-md text-lg resize-none border-solid border-2 border-gray-400"
                onChange={(e) => makeChange(e.target.value, "task")}
                placeholder="Note Subject"
                value={editedNote.task}
                disabled={!editable}
              />
              <textarea
                rows={3}
                cols={5}
                className="p-2 tracking-wide font-medium w-5/6 rounded-md text-lg resize-none border-solid border-2 shadow-inner border-gray-400"
                onChange={(e) => makeChange(e.target.value, "taskNote")}
                placeholder="Details (optional)"
                value={editedNote.taskNote}
                disabled={!editable}
              />
            </>
          )}

          {editError ? (
            <p className="font-inter font-medium text-md text-red m-2 ">
              Upload or discard images before saving.
            </p>
          ) : null}
          <Images
            setUploadedImages={(val) => makeChange(val, "images")}
            changeReadiness={setImagesReady}
            presetImages={editedNote.images}
            editable={editable}
          />
        </div>
      </div>
      {userType === "customer" ? (
        <div className="flex justify-center mt-10 mb-5 mx-10 border-b-2 border-solid border-gray-300"></div>
      ) : null}

      {userType === "customer" ? (
        <div className="flex flex-row justify-center">
          <button
            className="p-1 px-2 border-solid bg-red border-2 border-red rounded-md text-lg text-white font-semibold hover:text-red hover:bg-white"
            // onClick={() => cancel()}
            onClick={() => setShowCancelConfirmation(true)}
          >
            Cancel Appointment
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default AppointmentSelected;
