import React from 'react';
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import PhoneInput from 'react-phone-input-2'
import {
    useParams, Link, useLocation, useHistory
} from "react-router-dom";
import 'react-phone-input-2/lib/style.css'
import { useState, useEffect } from "react";
import PropertyDataService from "../../services/property.js";
import FormatService from "../../services/format"

import SetupStepper from "../../components/SetupStepper/SetupStepper";
import { HomeIcon, ClipboardListIcon, CreditCardIcon, KeyIcon, AcademicCapIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

import WalkthroughChecklist from '../../components/WalkthroughChecklist/WalkthroughChecklist';
import SetupAccess from '../../components/SetupAccess/SetupAccess';
import DetailsAndConfirm from '../../components/DetailsAndConfirm/DetailsAndConfirm';
import StickyDetails from '../../components/StickyDetail/StickyDetail';
import AppointmentDetails from '../../components/AppointmentDetails/AppointmentDetails';

//


const Initial = props => {
    // const { user } = props;

    const { id } = useParams();
    const location = useLocation()
    const { customer, appt, employ } = location.state
    const [property, setProperty] = React.useState("")

    const [tasks, setTasks] = React.useState([])
    const [loading, setLoading] = useState(false);

    const [locDes, setLocDes] = React.useState("");
    const [locImages, setLocImages] = React.useState([]);
    const [locNum, setLocNum] = React.useState("");
    const [readyToSave, setReadyToSave] = React.useState(true);
    // const [locError, setLocError] = React.useState("");

    const [locError, setLocError] = React.useState(false);

    useEffect(() => {
        for (let i in customer.property) {
            if (customer.property[i]._id == appt.propId) {
                setProperty(customer.property[i])
            }
        }
    }, []);

    const history = useHistory();

    const goToInitialConfirm = (customer, appt, details) => {
        history.push({
            pathname: "/initial/confirmation",
            state: {
                customer: customer,
                appt: appt,
                details: details,
            }
        });
    };


    const saveDetails = () => {
        if (locNum === "" || locDes === "") {
            setLocError(true);
            return
        }
        if (!readyToSave) {
            setLocError(true);
            return
        }

        const access = {lockDescription: locDes, lockImages:locImages, lockNumber:locNum}

        let created = new Date();
        const data = {
            tasks: tasks,
            access: access,
            created: created,
            email: customer.email,
            appointment: appt,
            employId: employ._id
        };

        const details = { tasks, access }

        setLoading(true);
        PropertyDataService.setupProperty(data)
            .then(response => {
                // console.log(response.data);
                setLoading(false)
                goToInitialConfirm(customer, appt, details)
            })
            .catch(e => {
                setLoading(false)
                console.log(e);
            });


    };



    // on first log in we should put them through a stepper to gather their phone number and also first property then show them to this page
    // this way we should track if they have logged in before... If they dont have a property this is when we will gather all the info.

    return (
        <div>
            <StickyDetails customer={customer} />
            <div className="flex flex-col min-h-screen bg-background mx-5">
                <div className='bg-white rounded shadow-lg my-8 pb-12 mx-auto'>
                    <div className="mx-8 mt-8 space-y-4">
                        <p className="font-bold text-dark text-4xl">Initial Appointment</p>
                        <AppointmentDetails property={property} appointment={appt} />

                        <WalkthroughChecklist saveTasks={setTasks} />

                        <SetupAccess setLocNum={setLocNum} setLocDes={setLocDes} presetImages={locImages} setUploadedImages={setLocImages} setReadyToSave={setReadyToSave}/>

                        <DetailsAndConfirm tasks={tasks} saveDetails={saveDetails} locImages={locImages} loading={loading} locDes={locDes} locNum={locNum} locError={locError}/>

                    </div>

                </div>
            </div>
        </div>

    )

}

export default Initial;