import React, { useState } from "react";
import PropertyDataService from "../../services/property";
import { XCircleIcon } from "@heroicons/react/outline";

const ConfirmationModal = (props) => {
  const { setShowModal, message, func } = props;

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-3">
            {/*header*/}
            <div className="flex items-start justify-between p-5 rounded-t items-center">
              <h3 className="text-3xl font-semibold">Confirmation</h3>
              <button
                className="p-1 ml-auto bg-transparent text-red float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <XCircleIcon className="h-6" />
              </button>
            </div>

            <div className="px-5">
              <div>
                <p>{message}</p>
              </div>
              <div className="flex flex-row space-x-3 justify-around my-5">
                <div>
                  <button
                  
                  onClick={() => setShowModal(false)}
                  className="px-2 py-1 border-solid border-2 border-black hover:text-gray-800 hover:border-gray-800  rounded-md text-lg font-semibold whitespace-nowrap"
                  >Return</button>
                </div>
                <div>
                  <button
                  onClick={() => func()}
                  className="px-2 py-1 border-2 border-solid border-red-600 bg-red-300 hover:bg-red-600 rounded-md text-lg text-red-600 hover:text-red-300 font-semibold whitespace-nowrap"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-60 bg-black"></div>
    </>
  );
};

export default ConfirmationModal;
