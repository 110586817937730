import React from 'react';
import {
    PlusCircleIcon,
  } from "@heroicons/react/solid";

const FileUploader = props => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };

  return (
    <>
      <button
              className="flex flex-row bg-white text-dark mt-3 font-bold text-lg 
                                      py-1 px-3 rounded-md items-center border-solid border-2 border-dark
                                      hover:bg-dark hover:text-white
                                      "
              onClick={handleClick}
            >
              {<PlusCircleIcon className="w-5 h-5 mr-5" />}
              Image
            </button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        className="hidden"
      />
    </>
  );
}
export default FileUploader;