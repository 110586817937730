import React from "react";
import LogoutButton from "../../components/LogoutButton/LogoutButton";
import PhoneInput from "react-phone-input-2";
import "./FirstLogin.css";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import PropertyDataService from "../../services/property.js";
import { Regions } from "../../data/data.js";
import { InformationCircleIcon } from "@heroicons/react/outline";
import Iubenda from "react-iubenda-policy";
import { CheckIcon } from "@heroicons/react/outline";
import IubendaLinks from "./IubendaLinks";

import SetupStepper from "../../components/SetupStepper/SetupStepper";

const getRegionCode = (zip) => {
  for (let i in Regions) {
    for (let j in Regions[i].zipcodes) {
      if (zip === Regions[i].zipcodes[j]) {
        return Regions[i].code;
      }
    }
  }
  return "Not_Covered";
};

const options = [
  { value: "1850 W 14th St. Yuma Az", label: "1850 W 14th St. Yuma Az" },
  { value: "1990 W 18th Pl. Yuma Az", label: "1990 W 18th Pl. Yuma Az" },
];

const phoneNumberInput = (
  <input
    placeholder="Enter Phone Number"
    className="px-3 py-4 placeholder-blueGray-300
                                       relative ml-3 mt-3
                                       text-blueGray-600 bg-white bg-white rounded text-base border-0
                                       shadow outline-none focus:outline-none focus:ring w-full"
  />
);

const FirstLogin = (props) => {
  const { user } = props;

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [phone, setPhone] = useState("");

  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [regionCode, setRegionCode] = useState("");

  const [validZip, setValidZip] = useState(false);
  const [zipError, setZipError] = useState(false);

  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const [referral, setReferral] = useState("");

  const [check, setCheck] = useState(false);

  const onChangeFirst = (e) => {
    const name = e.target.value;
    setFirst(name);
  };

  const onChangeReferral = (e) => {
    const code = e.target.value;
    setReferral(code);
  };

  const onChangeLast = (e) => {
    const name = e.target.value;
    setLast(name);
  };

  const onChangeStreet = (e) => {
    const val = e.target.value;
    setStreet(val);
  };

  const onChangeCity = (e) => {
    const val = e.target.value;
    setCity(val);
  };

  const onChangeState = (e) => {
    const val = e.target.value;
    if (val === "AZ" || val === "Az" || val === "az") {
      setState("Arizona");
    } else {
      setState(val);
    }
  };

  const onChangeZip = (e) => {
    const val = e.target.value;
    if (val.length === 5 || validZip) {
      console.log("checking region code");
      let newRegionCode = getRegionCode(val);
      if (newRegionCode === "Not_Covered") {
        setValidZip(false);
      } else {
        setValidZip(true);
        setZipError(false);
      }
      setRegionCode(newRegionCode);
    }
    if (val.length > 2) {
      if (!validZip) {
        setZipError(true);
      }
    }

    setZip(val);
  };

  const saveDetails = () => {
    if (checkDetails()) {
      let created = new Date();
      const data = {
        email: user.email,
        first: first,
        phone: phone,
        last: last,
        street: street,
        city: city,
        state: state,
        zip: zip,
        created: created,
        regionCode: regionCode,
        referredBy: referral,
      };

      setLoading(true);
      PropertyDataService.updateUser(data)
        .then((response) => {
          console.log(response.data);
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });

      console.log("CHANGE PAGE AND SUBMIT NEW DETAILS TO DB");
    } else {
      console.log("Problem with details");
    }
  };

  const checkDetails = () => {
    if (
      first === "" ||
      last === "" ||
      street === "" ||
      city === "" ||
      state === "" ||
      zip === "" ||
      phone.length !== 11 ||
      !validZip ||
      !check
    ) {
      setValid(false);
      return false;
    } else {
      setValid(true);
      return true;
    }
  };
  // on first log in we should put them through a stepper to gather their phone number and also first property then show them to this page
  // this way we should track if they have logged in before... If they dont have a property this is when we will gather all the info.

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-grow">
        <div className="flex justify-center">
          <div className="flex flex-col text-left bg-white rounded-xl shadow-lg sm:w-3/4 mx-5">
            <div className="max-w-full bg-red m-0 p-6 pb-4 rounded-md md:text-center">
              <h3 className="text-5xl font-inter font-bold text-white pb-4">
                Welcome
                <span className="hidden md:inline">, let's get started.</span>
              </h3>
            </div>
            <SetupStepper step={0} />

            {/* Information Section */}
            <div className="flex flex-col md:flex-row">
              <div className="p-6 md:w-1/2 md:mt-8">
                <p className="text-3xl font-medium ">Contact Information:</p>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">First Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="px-3 py-4 placeholder-blueGray-300
        relative mt-3
        text-blueGray-600 bg-white rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={first}
                    onChange={onChangeFirst}
                  />
                </div>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="px-3 py-4 placeholder-blueGray-300
        relative mt-3
        text-blueGray-600 bg-white rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={last}
                    onChange={onChangeLast}
                  />
                </div>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">Telephone Number</label>
                  <PhoneInput
                    country={"us"}
                    defaultMask={"(...) ...-...."}
                    containerClass=" mt-3"
                    inputStyle={{ fontSize: "20px" }}
                    inputClass="px-8 py-8 focus:outline-none focus:ring w-full"
                    onlyCountries={["us", "ca", "mx"]}
                    placeholder="Your Phone Number"
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
                {/* Review privacy and ToS */}
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">
                    Review Privacy Policy and Terms of Service
                  </label>
                  <div className="flex flex-row space-x-3 items-center my-2">
                    <div className="flex">
                      <div className=" bg-gray-400 px-2 py-2 rounded-lg text-white">
                      <a target="_blank" href="https://www.iubenda.com/privacy-policy/33755703">Privacy Policy</a>
                      </div>
                    
                    </div>

                    <div className="flex">
                      <div className=" bg-gray-400 px-2 py-2 rounded-lg text-white">
                      <a target="_blank" href="https://www.iubenda.com/terms-and-conditions/33755703">Terms and Conditions</a>
                      </div>
                    </div>
                  </div>
                  <div className="text-s font-light text-blue">
                    <p>The above documents will open in a new tab.</p>
                  </div>
                  <div className="flex flex-row space-x-3 items-center">
                    <p>I have read and understand both the above documents.</p>
                    <button
                      className={
                        " border-solid border-2 border-gray-400 h-8 w-8"
                      }
                      onClick={() => setCheck(!check)}
                    >
                      {check ? <CheckIcon className="text-red" /> : null}
                    </button>
                  </div>
                </div>

                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">
                    Referral Code{" "}
                    <span className="text-sm font-semibold text-gray-600">
                      (optional)
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Referral Code"
                    className="px-3 py-4 placeholder-blueGray-300
        relative mt-3
        text-blueGray-600 bg-white rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={referral}
                    onChange={onChangeReferral}
                  />
                </div>
              </div>

              <div className="p-6 md:w-1/2 md:mt-8">
                <p className="text-3xl font-medium">Property Information:</p>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">Street Address</label>
                  <input
                    type="text"
                    placeholder="Street Address"
                    className="px-3 py-4 placeholder-blueGray-300
        relative  mt-3
        text-blueGray-600 bg-white rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={street}
                    onChange={onChangeStreet}
                  />
                </div>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">City</label>
                  <input
                    type="text"
                    placeholder="City"
                    className="px-3 py-4 placeholder-blueGray-300
        relative  mt-3
        text-blueGray-600 bg-white  rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={city}
                    onChange={onChangeCity}
                  />
                </div>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">State</label>
                  <input
                    type="text"
                    placeholder="State"
                    className="px-3 py-4 placeholder-blueGray-300
        relative  mt-3
        text-blueGray-600 bg-white bg-white rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={state}
                    onChange={onChangeState}
                  />
                </div>
                <div className="mb-3 pt-0 mt-3 mx-3">
                  <label className="font-medium">
                    Zip{" "}
                    {!validZip ? (
                      <span className="flex flex-row shadow-md py-2 px-1 my-1 ">
                        <InformationCircleIcon className="h-7 mr-2 text-blue-400" />
                        <span className="block text-gray-900 font-semibold font-inter text-sm">
                          Sorry, we currently do not cover this area. Check out
                          our coverage areas{" "}
                          <a
                            className="text-blue-400 underline"
                            target="_blank"
                            href={window.location.href}
                          >
                            here
                          </a>
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    type="text"
                    placeholder="Zip"
                    className="px-3 py-4 placeholder-blueGray-300
        relative mt-3
        text-blueGray-600 bg-white bg-white rounded text-base border-0
        shadow outline-none focus:outline-none focus:ring w-full"
                    value={zip}
                    onChange={onChangeZip}
                  />
                </div>
              </div>
            </div>

            {valid ? null : (
              <p className="text-red font-medium text-xl mx-auto my-2">
                Please fill in all values
              </p>
            )}
            <button
              onClick={() => saveDetails()}
              className="bg-red flex flex-row justify-center items-center mb-4 hover:bg-transparent text-white text-lg font-medium hover:text-red py-2 px-4 mx-8 border border-transparent hover:border-red rounded transition duration-300"
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
              ) : null}
              Save Details
            </button>
          </div>
        </div>
      </div>
      <LogoutButton />
    </div>
  );
};

export default FirstLogin;
