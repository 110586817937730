import "./Footer.css";
import React from "react";
import logo from "../../assets/logo.png";
import { PhoneIcon } from "@heroicons/react/solid";
import { MailIcon } from "@heroicons/react/outline";
import logowhite from "../../assets/logo-white.png";
import Iubenda from "react-iubenda-policy";

export default function Footer() {
  return (
    <div>
      <footer
        id="footer"
        className="relative z-50 bg-gradient-to-b from-dark to-dark"
      >
        {/*This is the box that spans the first and second section and creates a white background*/}
        {/* <div className="w-full bg-white">
          <div className="container flex flex-col-reverse lg:flex-row py-8 px-10 justify-between mx-auto items-center">
            <div>
              <div className="flex flex-col justify-center">
                <div>
                  <img src={logo} alt="logo" className="h-14" />
                </div>
                <div className="flex flex-row space-x-4 pt-4">
                  <a className="text-link" href="/privacy">
                    Privacy
                  </a>
                  <a className="text-link" href="/terms">
                    Terms
                  </a>
                </div>
              </div>
            </div>

     

            <div>
              <div className="flex flex-col justify-start">
                <p className="text-sm">Contact Us: </p>
                <p className="text-sm mt-2">inf@propertywatchers.com</p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container py-8 px-5 flex flex-col lg:flex-row justify-between mx-auto items-center space-y-4 lg:space-y-0">
          {/* Contact Information */}
          <div className="flex flex-col lg:flex-row lg:space-x-3 items-center lg:w-1/3">
            <div className="flex flex-row space-x-3 items-center">
              <MailIcon className="text-white h-8" />
              <a className="text-white font-semibold whitespace-nowrap" href="mailto:info@propertywatchers.com">
                info@propertywatchers.com
              </a>
            </div>
            <p className="text-white text-xl font-bold hidden lg:block">•</p>
            <div className="flex flex-row space-x-3 items-center">
              <PhoneIcon className="text-white h-8" />
              <p className="text-white font-semibold whitespace-nowrap">
                (928) 920-4635
              </p>
            </div>
          </div>
          {/* Center logo */}
          <div className="justify-center hidden lg:flex">
            <img src={logowhite} className="h-14" />
          </div>

          {/* Legal Info */}
          <div className="flex flex-col space-y-2 lg:space-y-0 lg:flex-row space-x-3 items-center lg:w-1/3">
            <div className="text-white whitespace-nowrap">
              <Iubenda id={33755703} type="privacy" styling="">
                {" "}
                Privacy Policy
              </Iubenda>
            </div>
            <p className="text-white text-xl font-bold hidden lg:block">•</p>
            <div className="text-white whitespace-nowrap">
              <Iubenda id={33755703} type="terms-and-conditions" styling="">
                {" "}
                Terms and conditions
              </Iubenda>
            </div>
            <p className="text-white text-xl font-bold hidden lg:block">•</p>
            <div className="flex flex-row space-x-3 items-center">
              <p className="text-xs lg:text-medium font-bold leading-none text-white">
                © 2021 Property Watchers LLC. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
