import React, { useState, useEffect, useRef } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";

const useIntersectionObserver = (setActiveId, lists) => {
  const headingElementsRef = useRef({});

  useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: "-250px 0px -40% 0px",
    });

    const headingElements = Array.from(
      document.querySelectorAll("div.taskcheck")
    );
    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [lists]);
};

const StickyTableOfContents = (props) => {
  const { lists, completed } = props;

  const [headings, setHeadings] = useState([]);
  const [activeId, setActiveId] = useState();

  useIntersectionObserver(setActiveId, lists);
  useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("div.taskcheck")
    );
    setHeadings(headingElements);
    // const newNestedHeadings = getNestedHeadings(headingElements);
    // setNestedHeadings(newNestedHeadings);
  }, [lists, completed]);

  const scrollHelper = (id) => {
    const yOffset = -200;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const TableItem = ({ heading, completed }) => {
    let isCompleted = false;
    let type = "";
    for (let i in completed) {
      if (completed[i].id === heading.id) {
        isCompleted = true;
        type = completed[i].status;
      }
    }

    return (
      <li key={heading.id} className="flex flex-row items-center">
        {isCompleted ? (
          type === "completed" ? (
            <CheckCircleIcon className="h-7 font-bold mr-2 text-dark " />
          ) : type === "attention" ? (
            <ExclamationCircleIcon className="h-7 font-bold mr-2 text-dark " />
          ) : (
            <XCircleIcon className="h-7 font-bold mr-2 text-dark " />
          )
        ) : (
          <div className="h-6 w-6 border-solid border-2 mr-2 border-dark rounded-full"></div>
        )}
        <a
          className={heading.id === activeId ? "font-bold" : "" + " font-inter"}
          onClick={(e) => {
            e.preventDefault();
            // document.querySelector(`#${heading.id}`).scrollIntoView({
            //   behavior: "smooth"
            // });
            scrollHelper(heading.id);
          }}
          href={`#${heading.id}`}
        >
          {heading.title.length > 15
            ? heading.title.substring(0, 15)
            : heading.title}
        </a>
      </li>
    );
  };

  return (
    <nav
      aria-label="Table of contents"
      className="hidden sm:flex flex-col sticky top-48 m-5 min-w-full space-y-8"
    >
      <div>
        <p className="font-extrabold text-xl text-dark">General List</p>
        <ol className=" list-none">
          {headings.map((heading) => {
            if (heading.id.includes("general")) {
              return <TableItem heading={heading} completed={completed} />;
            } else {
              return null;
            }
          })}
        </ol>
      </div>
      {lists[1].length > 0 ? (
        <div>
          <p className="font-extrabold text-xl text-dark">Property List</p>
          <ol className=" list-none">
            {headings.map((heading) => {
              if (heading.id.includes("property")) {
                return <TableItem heading={heading} completed={completed} />;
              } else {
                return null;
              }
            })}
          </ol>
        </div>
      ) : null}
      {lists[2].length > 0 ? (
        <div>
          <p className="font-extrabold text-xl text-dark">
            Appointment Specific
          </p>
          <ol className=" list-none">
            {headings.map((heading) => {
              if (heading.id.includes("appointment")) {
                return <TableItem heading={heading} completed={completed} />;
              } else {
                return null;
              }
            })}
          </ol>
        </div>
      ) : null}
    </nav>
  );
};

export default StickyTableOfContents;
