import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import FormatService from "../../services/format";
import TimeService from "../../services/time";
import Select from "react-select";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    className="bg-white rounded text-dark text-2xl font-medium px-4 py-3 border-2 border-solid border-blue-400"
    onClick={onClick}
    ref={ref}
  >
    {value}
  </button>
));

const times = [
  "8:00 am",
  "8:30 am",
  "9:00 am",
  "9:30 am",
  "10:00 am",
  "10:30 am",
  "11:00 am",
  "11:30 am",
  "12:00 pm",
  "12:30 pm",
  "1:00 pm",
  "1:30 pm",
  "2:00 pm",
  "2:30 pm",
  "3:00 pm",
  "3:30 pm",
  "4:00 pm",
  "4:30 pm",
  "5:00 pm",
];

const generateList = (booking, curDate) => {
  let newTimes = [
    { value: "8:00 AM", isDisabled: false },
    { value: "8:30 AM", isDisabled: false },
    { value: "9:00 AM", isDisabled: false },
    { value: "9:30 AM", isDisabled: false },
    { value: "10:00 AM", isDisabled: false },
    { value: "10:30 AM", isDisabled: false },
    { value: "11:00 AM", isDisabled: false },
    { value: "11:30 AM", isDisabled: false },
    { value: "12:00 PM", isDisabled: false },
    { value: "12:30 PM", isDisabled: false },
    { value: "1:00 PM", isDisabled: false },
    { value: "1:30 PM", isDisabled: false },
    { value: "2:00 PM", isDisabled: false },
    { value: "2:30 PM", isDisabled: false },
    { value: "3:00 PM", isDisabled: false },
    { value: "3:30 PM", isDisabled: false },
    { value: "4:00 PM", isDisabled: false },
    { value: "4:30 PM", isDisabled: false },
    { value: "5:00 PM", isDisabled: false },
  ];

  for (let i in newTimes) {
    newTimes[i].label = newTimes[i].value;
    if (curDate in booking) {
      for (let j in booking[curDate]) {
        if (newTimes[i].value === booking[curDate][j]) {
          newTimes[i].isDisabled = true;
        }
      }
    }
  }

  return newTimes;
};

const SchedulerDateTimeSelector = (props) => {
  const [currentDate, setCurrentDate] = useState(
    TimeService.addTime(new Date(), 1)
  );
  const [disabledDates, setDisabledDates] = useState([]);
  const [currentTime, setCurrentTime] = useState(times[0]);
  const [options, setOptions] = useState([]);

  const { setDate, bookingSchedule } = props;

  console.log(bookingSchedule);

  useEffect(() => {
    if (bookingSchedule) {
      let selectedDate = currentDate;
      let curDate = `${
        currentDate.getMonth() + 1
      }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

      while (
        curDate in bookingSchedule &&
        bookingSchedule[curDate].length === 19
      ) {
        selectedDate = TimeService.addTime(selectedDate, 1);
        curDate = `${
          selectedDate.getMonth() + 1
        }/${selectedDate.getDate()}/${selectedDate.getFullYear()}`;
      }

      let l = generateList(bookingSchedule, curDate);
      setOptions(l);
      let selectedTime;
      for (let i in l) {
        if (!l[i].isDisabled) {
          selectedTime = l[i];
          break;
        }
      }

      setCurrentDate(selectedDate);
      setCurrentTime(selectedTime);

      let dateTime = FormatService.combineDateTime(
        selectedDate,
        selectedTime.value
      );
      setDate([dateTime]);
    }
  }, [bookingSchedule, currentDate]);


  useEffect(() => {
    if (bookingSchedule) {
      let fullDays = []
      for (let i in bookingSchedule) {
        if (bookingSchedule[i].length === 19) {
          fullDays.push(new Date(i))
        }
      }
      setDisabledDates(fullDays)
    }
  }, []);

  const changeVals = (val, type) => {
    let dateTime = "";
    switch (type) {
      case "time":
        setCurrentTime(val);
        dateTime = FormatService.combineDateTime(currentDate, val.value);
        setDate([dateTime]);
        break;
      case "date":
        setCurrentDate(val);
        dateTime = FormatService.combineDateTime(val, currentTime.value);
        setDate([dateTime]);
        break;
    }
  };

  const divObject = "flex flex-row items-center space-x-3";

  return (
    <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-3 sm:space-y-0 justify-center">
      <div className={divObject}>
        <p className="text-dark text-xl font-inter font-semibold">Date:</p>
        <DatePicker
          closeOnScroll={true}
          selected={currentDate}
          minDate={TimeService.addTime(new Date(), 1)}
          maxDate={TimeService.addTime(new Date(), 0, 5)}
          onChange={(date) => changeVals(date, "date")}
          customInput={<CustomInput />}
          excludeDates={disabledDates}
        />
      </div>
      <div className={divObject}>
        <p className="text-dark text-xl font-inter font-semibold">Time:</p>
        <Select
          options={options}
          value={currentTime}
          className=" w-40 text-2xl text-dark"
          onChange={(e) => changeVals(e, "time")}
        />
      </div>
    </div>
  );
};

export default SchedulerDateTimeSelector;
