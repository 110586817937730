import React, {useState} from "react";
import PropertyDataService from "../../services/property"


const AddPropertyModal = (props) => {

    const {setShowModal, user} = props;

    // console.log(user);

    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(false);


    const onChangeStreet = e => {
        const val = e.target.value;
        setStreet(val);
    };

    const onChangeCity = e => {
        const val = e.target.value;
        setCity(val);
    };

    const onChangeState = e => {
        const val = e.target.value;
        if (val === "AZ" || val === "Az" || val === "az") {
            setState("Arizona")
        } else {
            setState(val);
        }

    };

    const onChangeZip = e => {
        const val = e.target.value;
        setZip(val);
    };

    const saveDetails = () => {
        if (checkDetails()) {
            let created = new Date();
            const data = {
                email: user.email,
                street: street,
                city: city,
                state: state,
                zip: zip,
                created: created,
            };
            // console.log("Sending addProperty Data: ",data)
            setLoading(true);
            PropertyDataService.addProperty(data)
                .then(response => {
                    // console.log(response.data);
                    window.location.reload();
                })
                .catch(e => {
                    console.log(e);
                });

        } else {
         console.log("Problem with details");
        }
    };

    const checkDetails = () => {
        if (street === "" || city === "" || state === "" || zip === "") {
            setValid(false);
            return false
        } else {
            setValid(true);
            return true;
        }
    };

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                Add Property
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                            <div className="my-4 text-blueGray-500 text-lg leading-relaxed">
                                <div className="mb-3 pt-0 mt-3 mx-3">
                                    <label className="font-medium">Street Address:</label>
                                    <input type="text" placeholder="1898 S 24th St."
                                           className="px-3 py-4 placeholder-blueGray-300
                                       relative ml-3 mt-3
                                       text-blueGray-600 bg-white bg-white rounded text-base border-0
                                       shadow outline-none focus:outline-none focus:ring w-full"
                                           value={street}
                                           onChange={onChangeStreet}/>
                                </div>
                                <div className="mb-3 pt-0 mt-3 mx-3">
                                    <label className="font-medium">City:</label>
                                    <input type="text" placeholder="Yuma"
                                           className="px-3 py-4 placeholder-blueGray-300
                                       relative ml-3 mt-3
                                       text-blueGray-600 bg-white bg-white rounded text-base border-0
                                       shadow outline-none focus:outline-none focus:ring w-full"
                                           value={city}
                                           onChange={onChangeCity}
                                    />
                                </div>
                                <div className="mb-3 pt-0 mt-3 mx-3">
                                    <label className="font-medium">State:</label>
                                    <input type="text" placeholder="Arizona"
                                           className="px-3 py-4 placeholder-blueGray-300
                                       relative ml-3 mt-3
                                       text-blueGray-600 bg-white bg-white rounded text-base border-0
                                       shadow outline-none focus:outline-none focus:ring w-full" value={state}
                                           onChange={onChangeState}
                                    />
                                </div>
                                <div className="mb-3 pt-0 mt-3 mx-3">
                                    <label className="font-medium">Zip:</label>
                                    <input type="text" placeholder="85364"
                                           className="px-3 py-4 placeholder-blueGray-300
                                       relative ml-3 mt-3
                                       text-blueGray-600 bg-white bg-white rounded text-base border-0
                                       shadow outline-none focus:outline-none focus:ring w-full" value={zip}
                                           onChange={onChangeZip}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="text-red background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button>
                            <button
                                className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => saveDetails(false)}
                            >
                                {loading ? <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6"/> : null}
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-60 bg-black"></div>
        </>
    )

};

export default AddPropertyModal;