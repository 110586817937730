
import React, { useEffect, useState } from "react";
import TimeService from "../../services/time";
import FormatSerivce from "../../services/format";
import PropertyDataService from "../../services/property";

import {
  InformationCircleIcon,
  CheckIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import SchedulerContact from "../Scheduler/SchedulerContact";
import Task from "../Task/Task";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import AppointmentSelected from "./AppointmentSelected";


const AppointmentCompleted = ({ appointment, user, currentProperty }) => {
    const { type, completedDateTime } = appointment;
    const [currentPropObj, setCurrentPropObj] = useState({});
  
    const history = useHistory();
  
  
    const getProperty = (user, currentProperty) => {
      let properties = user.property;
      for (let i in properties) {
        if (currentProperty === properties[i]._id) {
          return properties[i];
        }
      }
      return null;
    };
  
    const goToReport = () => {
      history.push({
        pathname: `/reports/${currentProperty}/${appointment._id}`,
      });
    };
  
    useEffect(() => {
      if (user && currentProperty) {
        setCurrentPropObj(getProperty(user, currentProperty));
      }
    }, [user, currentProperty]);
  
    let typeString = "";
    switch (type) {
      case "initial":
        typeString = "Initial Appt.";
        break;
      case "propertycheck":
        typeString = "Property Checkup";
        break;
    }
  
    return (
      <div className="mx-8">
        <div className="flex flex-row justify-between space-x-5">
          <div className="border-b-2 border-solid border-gray-100 w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Address</p>
            <p className="text-dark font-inter font-medium text-lg">
              {FormatSerivce.formatAddress(currentPropObj)}
            </p>
          </div>
          <div className="border-b-2 border-solid border-gray-100 w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Type</p>
            <p className="text-dark font-inter font-medium text-lg">
              {typeString}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between space-x-5">
          <div className="border-b-2 border-solid border-gray-100 w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Date</p>
            <p className="text-dark font-inter font-medium text-lg">
              {FormatSerivce.getFormatedDate(completedDateTime)}
            </p>
          </div>
          <div className="border-b-2 border-solid border-gray-100 w-2/5">
            <p className="text-gray-400 font-inter font-bold text-lg">Time</p>
            <p className="text-dark font-inter font-medium text-lg">
              {FormatSerivce.getTimefromDate(completedDateTime)}
            </p>
          </div>
        </div>
        {type === "propertycheck" ? (
          <div className="flex flex-row justify-center mt-5">
            <button
              className="ml-5 py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold border-solid border-blue-500 border-2"
              onClick={() => goToReport()}
            >
              Go To Report
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  export default AppointmentCompleted