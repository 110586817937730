import React from "react";
import { useAuth0} from "@auth0/auth0-react";
import { LogoutIcon } from "@heroicons/react/outline";


const LogoutButton = () => {
    const {logout} = useAuth0();

    return (
        <button
        className=" focus:outline-none font-extrabold text-white hover:text-red hover:bg-white border-2  sm:border-2 text-lg border-solid border-white font-inter rounded-md p-1 sm:py-2 sm:px-4"
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      >
        Logout
      </button>
    );
};

export default LogoutButton;