import React from 'react';

function Referral() {
    return (
        <div className="flex flex-col min-h-screen bg-background">



            <div className="flex-grow mx-20">
                <p className="text-7xl mt-10 font-inter">Referrals</p>
            </div>

        </div>
    )

}

export default Referral