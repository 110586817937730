import FormatService from "../../services/format";
import React, { useState } from "react";
import { ReceiptTaxIcon, CheckIcon } from "@heroicons/react/outline";
import PropertyDataService from "../../services/property"
import {
  useParams, Link, useLocation, useHistory
} from "react-router-dom";

const CheckAndLabel = ({ onChange, label }) => {
  const [check, setCheck] = useState(false);
  

  const change = (val) => {
    if (label.includes("doors")) {
      onChange("doors", val);
    } else {
      onChange("lights", val);
    }
    setCheck(val);
  };

  return (
    <button
      className=" focus:outline-none bg-gray-300 py-2 px-4 rounded-md"
      onClick={() => change(!check)}
    >
      <div className="flex flex-row items-center">
        {check ? (
          <CheckIcon className="w-7 h-7 mr-5 text-blue-400 border-dark border-2 border-solid bg-white" />
        ) : (
          <div className="h-7 w-7 border-solid border-dark border-2 mr-5 bg-white"></div>
        )}
        <p className="font-bold text-lg text-dark font-inter"> {label}</p>
      </div>
    </button>
  );
};

const AppointmentConfirmation = (props) => {
  const { complete, generateReport, prop, customerName, customerPhone, contact, custId } = props;
  const history = useHistory();
  const [doors, setDoors] = useState(false);
  const [lights, setLights] = useState(false);
  const [loading, setLoading] = useState(false);

  const { customerId, appointmentId } = useParams();
  
  const saveDetails = () => {
    
    const report = generateReport()
    const data = {report : report, name: customerName, address: FormatService.formatAddress(prop), phone: customerPhone, text: contact.text, customerId: custId}
    setLoading(true);
    PropertyDataService.propertyCheck(data)
        .then(response => {
            setLoading(false)
            history.push({
              pathname: `/home/${customerId}/${appointmentId}/confirmation`,
              state: {
                reportData: report,
                propertyData: prop
            }
          });

        })
        .catch(e => {
            setLoading(false)
            console.log(e);
        });


};

  const changeComplete = (type, val) => {
    switch (type) {
      case "lights":
        setLights(val);
        if (val && doors) {
          setAllComplete(true);
        } else {
          setAllComplete(false);
        }
        break;
      case "doors":
        setDoors(val);
        if (val && lights) {
          setAllComplete(true);
        } else {
          setAllComplete(false);
        }
        break;
    }
  };

  const [allComplete, setAllComplete] = useState(false);

  return (
    <div>
      <div className="flex flex-row items-center ml-5 mb-2">
        <ReceiptTaxIcon className="h-10 w-10" />
        <p className="font-bold text-dark text-xl ml-5 ">Confirmation</p>
      </div>

      <div className="flex justify-center mx-4 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-4 ">
        {complete ? (
          <div className="flex flex-col space-y-5">
            <CheckAndLabel
              onChange={changeComplete}
              label={
                "I have closed and locked all the doors I opened and unlocked."
              }
            />
            <CheckAndLabel
              onChange={changeComplete}
              label={"I have turned off all the lights I turned on."}
            />
            <div className="flex justify-center">
              <button
                className={
                  (allComplete
                    ? " bg-gradient-to-b from-blue-300 to-blue-500"
                    : "bg-gray-500") +
                  "  py-2 px-4 mt-5 flex justify-center items-center w-1/2 focus:outline-none rounded-md"
                }
                disabled={!allComplete}
                onClick={() => saveDetails()}
              >
                {loading ? (
                  <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" />
                ) : null}
                <p className="text-white text-lg font-inter font-semibold">Submit Details</p>
              </button>
            </div>
          </div>
        ) : (
          <p className="text-2xl text-dark font-inter font-semibold">
            Mark All Tasks {contact.call ? "and Call the Customer" : ""} Before Submitting 
          </p>
        )}
      </div>
    </div>
  );
};

export default AppointmentConfirmation;
