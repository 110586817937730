import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import FormatService from "../../services/format";
import TimeService from "../../services/time";
import Select from "react-select";
import SchedulerCalendar from "./SchedulerCalendar";
import SchedulerFrequency from "./SchedulerFrequency";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button
    className="bg-white rounded text-dark text-2xl font-medium px-4 py-3 border-2 border-solid border-blue-400"
    onClick={onClick}
    ref={ref}
  >
    {value ? value : "Return Date"}
  </button>
));

const SchedulerDateRangeSelector = (props) => {
  const [startDate, setStartDate] = useState(
    TimeService.addTime(new Date(), 1)
  );
  const [disabledDates, setDisabledDates] = useState([
  ]);
  const [endDate, setEndDate] = useState("");
  const { setDates, bookingSchedule } = props;
  const [schedule, setSchedule] = useState({
    frequency: "1",
    timeFrame: "week",
  });

  useEffect(() => {
    if (bookingSchedule) {
      let fullDays = []
      for (let i in bookingSchedule) {
        if (bookingSchedule[i].length === 19) {
          fullDays.push(new Date(i))
        }
      }
      setDisabledDates(fullDays)
    }
  }, []);

  const changeDate = (date) => {
    setStartDate(date);
    let newEnd = TimeService.addTime(date, 1);
    setEndDate(newEnd);
  };

  const divObject = "flex flex-row items-center space-x-4";

  return (
    <div className="space-y-10">
      <div className="flex flex-col sm:flex-row sm:space-x-5 space-y-3 sm:space-y-0 justify-center">
        <div className={divObject}>
          <p className="text-dark text-xl font-inter font-semibold w-12 sm:w-auto">From:</p>
          <DatePicker
            closeOnScroll={true}
            selected={startDate}
            minDate={TimeService.addTime(new Date(), 1)}
            maxDate={TimeService.addTime(new Date(), 0, 5)}
            onChange={(date) => changeDate(date)}
            customInput={<CustomInput />}
          />
        </div>
        <div className={divObject}>
          <p className="text-dark text-xl font-inter font-semibold w-12 sm:w-auto">To:</p>
          <DatePicker
            closeOnScroll={true}
            selected={endDate}
            minDate={TimeService.addTime(startDate, 1)}
            maxDate={TimeService.addTime(startDate, 0, 10)}
            onChange={(date) => setEndDate(date)}
            customInput={<CustomInput />}
          />
        </div>
      </div>

      {/* {endDate != "" ? (
        <div className={"flex space-y-3 sm:space-y-0 flex-col sm:flex-row sm:space-x-4 sm:justify-center justify-start"}>
          <SchedulerFrequency setSchedule={setSchedule} />
        </div>
      ) : null} */}

      {endDate != "" ? (
        <div className=" border-solid border-gray-100 border-2 flex justify-center shadow-inner">
          <SchedulerCalendar
            start={startDate}
            end={endDate}
            schedule={schedule}
            setDates={setDates}
            disabledDates={disabledDates}
            bookingSchedule={bookingSchedule}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SchedulerDateRangeSelector;
