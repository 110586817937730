import React from "react";
import Select from "react-select";
import "./Home.css";
import PropertyDataService from "../../services/property.js";
import { useState, useEffect } from "react";
import FirstLogin from "../FirstLogin/FirstLogin";
import AddPropertyModal from "../../components/AddPropertyModal/AddPropertyModal.js";
import PrepareAppointment from "../../components/PrepareAppointment/PrepareAppointment";
import BookInitialAppointment from "../../components/BookAppointment/BookInitialAppointment";
import AddPayment from "../../components/AddPayment/AddPayment";
import Scheduler from "../../components/Scheduler/Scheduler";
import ProfileCalendar from "../../components/Profile/ProfileCalendar";
import AppointmentViewer from "../../components/Profile/AppointmentViewer";
import AppointmentWrapper from "../../components/AppointmentWrapper/AppointmentWrapper";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/outline";
import PaymentError from "../../components/PaymentError/PaymentError";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import InformationPopup from "../../components/InformationPopup/InformationPopup";
import PlatformPreview from "../../components/PlatformPreview/PlatformPreview"

const renderProp = (user, curProp, appointments) => {
  if (user.payment.paymentError && user.payment.paymentError.error) {
    // console.log(user.payment.paymentError)
    // return (<div > paymenyt</div>)
    return <PaymentError user={user} details={user.payment.paymentError} />;
  }
  for (let i in user.property) {
    if (user.property[i]._id === curProp.value) {
      if (user.property[i].setupComplete) {
        if (user.payment.setupComplete) {
          return (
            <AppointmentWrapper
              user={user}
              currentProperty={curProp}
              appointments={appointments}
            />
          );
        }
        return (
          <div className="flex flex-col max-w-full lg:w-3/4 lg:text-left bg-white rounded shadow-lg my-8 pb-12 mx-7">
            <AddPayment user={user} />
          </div>
        );
      } else {
        for (let x in appointments) {
          if (
            appointments[x].propId === curProp.value &&
            appointments[x].type === "initial"
          ) {
            return (
              <div className="flex flex-col max-w-full lg:w-3/4 lg:text-left bg-white rounded shadow-lg my-8 pb-12 mx-7">
                <PrepareAppointment appointment={appointments[x]} />
                <PlatformPreview />
              </div>
            );
          }
        }
        return (
          <div className="flex flex-col max-w-full lg:w-3/4 lg:text-left bg-white rounded shadow-lg my-8 pb-12 mx-7">
            <BookInitialAppointment user={user} curProp={curProp} />
          </div>
        );
      }
    }
  }
};

// TODO https://headlessui.dev/react/dialog this is good for pop ups for info like a little circle with a quesiton mark in it

const Home = (props) => {
  const { user } = props;
  // console.log(user)
  const [showModal, setShowModal] = React.useState(false);
  let names = user.name.split(" ");

  const [curProp, setCurProp] = useState(null);
  const [options, setOptions] = useState("");
  const [appointments, setAppointments] = useState("");
  const [initial, setInitial] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  const [numUnviewed, setNumUnviewed] = useState(0);

  // const location = useLocation()
  // const { customer, appt, details } = location.state
  const { propertyId, appointmentId } = useParams();

  const history = useHistory();

  useEffect(() => {
    // console.log("origin", window.location.origin)
    if (user.property.length > 0) {
      setOptions(createOptions(props.user.property));
      getAppointments(user._id);
    }
  }, []);

  useEffect(() => {
    if (curProp) {
      for (let i in user.property) {
        if (user.property[i]._id === curProp.value) {
          // console.log("found property", user.property[i]);
          if (user.property[i].unviewed) {
            setNumUnviewed(user.property[i].unviewed.length);
          } else {
            setNumUnviewed(0);
          }
        }
      }
    }
  }, [curProp]);

  const changeVal = (e) => {
    setCurProp(e);
    history.push({ pathname: `/overview/${e.value}` });
  };

  const gotoPropertyProfile = (propId) => {
    history.push({ pathname: `/property/${propId}` });
  };

  const getAppointments = (id) => {
    PropertyDataService.getAppointmentsById(id)
      .then((response) => {
        setAppointments(response.data);
        for (let i in response.data) {
          if (response.data[i].type === "initial") {
            setInitial(response.data[i]);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const startSession = () => {
    PropertyDataService.pushSession({ id: user.payment.stripeCustomerId })
      .then((response) => {
        window.location.href = response.data.redirect;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const goToReports = (propId) => {
    history.push({ pathname: `/reports/${propId}` });
  };

  const goToBilling = () => {
    history.push({ pathname: `/billing` });
  };

  const createOptions = (property) => {
    let newOptions = [];
    for (const prop in property) {
      let addy =
        property[prop].street +
        " " +
        property[prop].city +
        ", " +
        property[prop].state +
        " " +
        property[prop].zip;
      newOptions.push({ value: property[prop]._id, label: addy });
    }
    setCurProp(newOptions[0]);
    // TODO need to match the prop id to the appoitnment IDif (user.payment.setupComplete) {

    if (!(propertyId && appointmentId)) {
      if (user.payment.setupComplete) {
        history.push({ pathname: `/overview/${newOptions[0].value}` });
      }
    }

    return newOptions;
  };

  let mobileButton =
    "px-2 py-1 bg-white hover:bg-blue-100 rounded-md text-lg text-blue-500 font-semibold whitespace-nowrap";

  // setCurrentAppointment(appt);
  // setSwitcherRight("editor");
  // let appointmentString = appt._id;
  // history.push({ pathname: `/overview/${appointmentString}` });

  return user.property.length > 0 ? (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-grow md:mx-20">
        <div className="mt-8  flex flex-col ml-7 items-baseline">
          <p className="font-bold text-4xl">Hello {names[0]}</p>
          <div className="flex flex-row items-center">
            <p className="text-sm font-inter">Referral Code:</p>
            <p className="shadow-sm p-1 bg-gray-100 text-dark tracking-wide font-semibold text-sm ">
              {(user.referral && user.referral.code) || ""}
            </p>
            <InformationPopup>
              <div className="font-inter">
                <p className="font-semibold p-1 mb-3">How Referrals work?</p>
                <div className='px-3 pb-7'>
                  <p className="px-5">
                    If you refer a friend, for every two Property Checks they
                    book, you get a free one!
                  </p>
                  <p>**Promotion works for the first 6 visits.</p>
                </div>
              </div>
            </InformationPopup>
          </div>
          {/* <div className="flex flex-row items-center">
            <p className="text-sm font-inter">Free Bookings:</p>
            <p className="shadow-sm p-1 bg-gray-100 text-dark tracking-wide font-semibold text-sm ">
              {(user.referral && user.referral.freeBookings) || "0"}
            </p>
          
          </div> */}
        </div>

        {user.property.length > 1 ||
        (user.property[0].setupComplete && user.payment.setupComplete) ? (
          <div className="mt-5 flex flex-row justify-between">
            <div className="flex flex-col lg:flex-row lg:w-2/3 items-center sm:ml-7">
              <div className="flex flex-row items-center ml-7 sm:ml-0">
                <p className="hidden sm:flex">Your Property:</p>
                <div className="w-1/3 flex flex-row">
                  {curProp && curProp !== null ? (
                    <div>
                      <Select
                        options={options}
                        value={curProp}
                        className="px-6 sm:w-72 w-60"
                        onChange={changeVal}
                      />
                    </div>
                  ) : null}
                  <button
                    className="lg:hidden focus:outline-none"
                    onClick={() => setShowOptions(!showOptions)}
                  >
                    {showOptions ? (
                      <ChevronUpIcon className="h-10 text-gray-500 hover:text-gray-700 " />
                    ) : (
                      <ChevronDownIcon className="h-10 text-gray-500 hover:text-gray-700 " />
                    )}
                  </button>
                </div>
              </div>

              <div className="hidden lg:flex flex-col sm:flex-row items-center mt-3 sm:mt-0 space-y-3 sm:space-y-0">
                <button
                  className={"outline-none focus:outline-none"}
                  onClick={() => setShowModal(true)}
                >
                  <PlusCircleIcon className="h-10 hidden sm:block" />
                  <p className="sm:hidden p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white">
                    Add Property
                  </p>
                </button>
                <button
                  className="sm:ml-5 p-1 px-2 border-solid border-2 border-dark rounded-md text-lg text-dark font-semibold hover:text-white hover:bg-dark hover:border-white"
                  onClick={() => gotoPropertyProfile(curProp.value)}
                >
                  Edit Property
                </button> 
              </div>

              {showModal ? (
                <AddPropertyModal setShowModal={setShowModal} user={user} />
              ) : null}
            </div>

            <div className="sm:space-x-5 space-y-3 sm:space-y-0 items-center sm:mr-10 mt-0 hidden lg:flex flex-col sm:flex-row justify-center">
              <button
                onClick={() => goToBilling()}
                className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold whitespace-nowrap"
              >
                Billing History
              </button>
              <button
                onClick={() => startSession()}
                className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold whitespace-nowrap"
              >
                Payment Details
              </button>
              <button
                onClick={() => goToReports(curProp.value)}
                className="relative ml-5 py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold"
              >
                Reports
                {numUnviewed > 0 ? (
                  <span class="absolute bg-red-600 px-2 py-1 text-xs font-bold rounded-full text-white -top-3 -right-3">
                    +{numUnviewed}
                  </span>
                ) : null}
              </button>
            </div>
          </div>
        ) : null}

        {/* {showOptions ? (
          <div className="lg:hidden flex flex-row mt-3 justify-center space-x-3">
            <div className=" flex flex-col space-y-3">
              <div>
                <button
                  className={"outline-none focus:outline-none"}
                  onClick={() => setShowModal(true)}
                >
                  <p className={mobileButton}>
                    Add Property
                  </p>
                </button>
              </div>

              <div>
                <button
                  className={mobileButton}
                  onClick={() => gotoPropertyProfile(curProp.value)}
                >
                  Edit Property
                </button>
              </div>
            </div>
            <div className=" flex flex-col space-y-3">
              <div>
                <button
                  onClick={() => goToBilling()}
                  className={mobileButton}
                >
                  Billing History
                </button>
              </div>

              <div>
                <button
                  onClick={() => startSession()}
                  className={mobileButton}
                >
                  Payment Details
                </button>
              </div>

              <div>
                <button
                  onClick={() => goToReports(curProp.value)}
                  className={mobileButton + " relative" }
                >
                  Reports
                  {numUnviewed > 0 ? (
                    <span class="absolute bg-red-600 px-2 py-1 text-xs font-bold rounded-full text-white -top-3 -right-3">
                      +{numUnviewed}
                    </span>
                  ) : null}
                </button>
              </div>
            </div>
          </div>
        ) : null} */}

        {showOptions ? (
          <div className="lg:hidden flex flex-row mt-3 justify-center space-x-3 mx-7">
            <div className="w-1/2 flex flex-col space-y-3">
              <button
                className={"outline-none focus:outline-none"}
                onClick={() => setShowModal(true)}
              >
                <p className={mobileButton}>Add Property</p>
              </button>

              <button
                className={mobileButton}
                onClick={() => gotoPropertyProfile(curProp.value)}
              >
                Edit Property
              </button>
            </div>
            <div className="w-1/2 flex flex-col space-y-3">
              <button onClick={() => goToBilling()} className={mobileButton}>
                Billing History
              </button>

              <button onClick={() => startSession()} className={mobileButton}>
                Payment Details
              </button>

              <button
                onClick={() => goToReports(curProp.value)}
                className={mobileButton + " relative"}
              >
                Reports
                {numUnviewed > 0 ? (
                  <span class="absolute bg-red-600 px-2 py-1 text-xs font-bold rounded-full text-white -top-3 -right-3">
                    +{numUnviewed}
                  </span>
                ) : null}
              </button>
            </div>
          </div>
        ) : null}

        <div className="flex flex-col justify-center items-center  max-w-full">
          {/*Schedule an appointment*/}

          {appointments
            ? curProp && curProp !== null
              ? renderProp(user, curProp, appointments)
              : null
            : null}

          {/*Calendar*/}

          {/* <div className="flex flex-col lg:w-2/5 lg:text-left ">
          <div className="">
          <div className="bg-white rounded shadow-lg my-8 mx-7 w-4/5 flex flex-col justify-center"> */}
          {/* <div className="container flex min-w-full">
            <div className="mx-7 bg-white rounded shadow-lg w-full">
              <div className="flex flex-col p-4 ">
                <div className="flex flex-row space-x-4">
                  <SearchIcon className="h-8" />
                  <p className="py-3 font-bold text-xl font-inter text-dark">
                    Search for a service
                  </p>
                </div>

                <div className="ml-14 font-inter text-center">
                  <p className="text-3xl">
                    Book and manage all your home services in one place.
                  </p>
                  <p className="text-2xl font-semibold">Coming Soon!</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  ) : (
    <FirstLogin user={user} />
  );
};

export default Home;
