import { CreditCardIcon } from '@heroicons/react/outline'
import Task from '../Task/Task';
import Images from '../Images/Images';

const DetailsAndConfirm = (props) => {

    const { tasks, saveDetails, loading, locDes, locNum, locImages, locError } = props;

    return <>
        <div className="flex flex-row items-center ml-5">
            <CreditCardIcon className="h-10 w-10" />
            <p className="font-bold text-dark text-xl ml-5 ">Review Details and Confirm</p>
        </div>

        <div className="flex flex-col mx-4 bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-4 ">
            <p className="text-dark text-xl font-bold m-5">Save details after confirming them below.</p>

            <div className="flex flex-row justify-around rounded-md p-4 px-6 w-full">

                <div className="w-1/3 mx-5">
                    <p className="text-xl text-dark font-bold mb-3">Access Details</p>
                    <div className="bg-white rounded-md p-4 px-6 w-full">

                        <p className="ml-5 font-medium text-lg text-gray-800"> Location Description:

                            {locDes === "" ? <span className="text-red-600 ml-2 italic">Missing Location Description</span> :
                                <span className="ml-2">{locDes}</span>}</p>
                        <p className="ml-5 font-medium text-lg text-gray-800"> Location Image:
                        <Images editable={false} presetImages={locImages} /> </p>
                        <p className="ml-5 font-medium text-lg text-gray-800"> Loxbox Number:{locNum === "" ?
                            <span className="text-red-600 ml-2 italic">Missing Lock Number</span> :
                            <span className="ml-2">{locNum}</span>}</p>
                    </div>
                </div>

                <div className="w-1/3 mx-5">
                    <p className="text-xl text-dark font-bold mb-3">Specific Tasks</p>
                    <div className="bg-white rounded-md p-4 px-6 w-full">

                        <ol className="pace-y-2 mt-1">
                            {tasks.map((val) => {
                                return <Task val={val} />
                            })}
                            {tasks.length === 0 ? <p className="text-gray-500 ml-4 italic">Task List Empty</p> : null}
                        </ol>
                    </div>
                </div>


            </div>
            <div className="flex justify-center flex-col w-full text-center">
                <div>
                    <button className="mt-10 bg-gradient-to-b w-1/2 text-white mt-3 font-bold text-lg 
                py-1 px-3 from-blue-300 to-blue-400 rounded-md items-center"
                        onClick={() => saveDetails()}
                    >
                        {loading ? <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-8 w-8 mr-6" /> : null}
                        Save Details
                    </button>
                </div>

                {locError ? <p className="text-red text-lg mt-5">Missing Information or Lock Images need to be uploaded</p> : null}
            </div>

        </div>

    </>
}

export default DetailsAndConfirm;