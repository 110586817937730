import React from "react";

import LoginButton from "../LoginButton/LoginButton.js";
import LogoutButton from "../LogoutButton/LogoutButton.js";

import { useAuth0 } from "@auth0/auth0-react";

const AuthenticationButton = () => {
    const { isAuthenticated } = useAuth0();
    // console.log(`User Authenticated: ${isAuthenticated}`);
    return isAuthenticated ? <LogoutButton /> : <LoginButton />;
};

export default AuthenticationButton;