import "./App.css";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LandingPage from "./views/LandingPage/LandingPage";
import LandingPageV2 from "./views/LandingPage/LandingPageV2";
import Pricing from "./views/Pricing/Pricing";
import FAQ from "./views/FAQ/FAQ";
import BusinessInquiries from "./views/BusinessInquiries/BusinessInquiries";
import HowItWorks from "./views/HowItWorks/HowItWorks";
import AboutUs from "./views/AboutUs/AboutUs";
import Referral from "./views/Referral/Referral";
import Scratch from "./views/Scratch/Scratch";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home.js";
import { useAuth0 } from "@auth0/auth0-react";
import AppNav from "./components/AppNav/AppNav.js";
import PropertyDataService from "./services/property.js";
import EmployHome from "./views/EmployHome/EmployHome.js";
import loadinggif from "./assets/loadingLogo.gif";
import ReactPlaceholder from "react-placeholder";
import EmployeeHeader from "./components/EmployeeHeader/EmployeeHeader";
import Initial from "./views/Initial/Initial";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InitialConfirmation from "./views/InitialConfirmation/InitialConfirmation";
import PropertyCheck from "./views/PropertyCheck/PropertyCheck";
import Reports from "./views/Reports/Reports";
import Report from "./views/Report/Report";
import PropertyProfile from "./views/PropertyProfile/PropertyProfile";
import NotFoundPage from "./views/NotFoundPage/NotFoundPage";
import PropertyCheckConfirmation from "./views/PropertyCheckConfirmation/PropertyCheckConfirmation";
import EmployPropertyProfile from "./views/EmployPropertyProfile/EmployPropertyProfile";
import Billing from "./views/Billing/Billing";

const Unauthenticated = ({ user, userLoaded }) => {
  return (
    <div>
      <Header />
      <div className="h-min-screen pb-12">
        <Switch>
          {/* <Route path="/" component={LandingPage} exact /> */}
          <Route
            exact
            path="/"
            render={(props) => <LandingPage {...props} user={userLoaded} />}
          />
          {/* <Route path="/v2" component={LandingPageV2} exact /> */}
          {/* <Route
            path="/businessInquiries"
            component={BusinessInquiries}
            exact
          /> */}
          {/* <Route path="/pricing" component={Pricing} /> */}
          {/* <Route path="/faq" component={FAQ} /> */}
          {/* <Route path="/howitworks" component={HowItWorks} /> */}
          {/* <Route path="/aboutus" component={AboutUs} /> */}
          {/* <Route path="/referral" component={Referral} /> */}
          <Route component={NotFoundPage} details={user} />
          {/* <Route path="/scratch" component={PropertyCheck} /> */}
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

const Authenticated = ({ userLoaded, showHeader }) => {
  return (
    <div>
      <div className="h-min-screen bg-background pb-12">
        <Switch>
          <Route exact path="/" component={() => <Redirect to="/overview" />} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/howitworks" component={HowItWorks} />
          {/* <Route exact path="/scratch" component={Scratch} /> */}
          <Route
            exact
            path="/scratch"
            render={(props) => <Scratch {...props} user={userLoaded} />}
          />
          <Route
            path="/property/:propertyId"
            render={(props) => <PropertyProfile {...props} user={userLoaded} />}
          />
          <Route
            path="/overview/:propertyId?/:appointmentId?"
            render={(props) => <Home {...props} user={userLoaded} />}
          />
          <Route
            path="/reports/:propertyId"
            exact
            render={(props) => <Reports {...props} user={userLoaded} />}
          />
          <Route
            path="/reports/:propertyId/:reportId"
            render={(props) => <Report {...props} user={userLoaded} />}
          />
          <Route
            path="/billing"
            render={(props) => <Billing {...props} user={userLoaded} />}
          />
        </Switch>
      </div>
      {showHeader ? <AppNav user={userLoaded} /> : null}
    </div>
  );
};

const loading = (
  <div className="flex justify-center mt-40">
    <img alt={"loading"} src={loadinggif} />
  </div>
);

const employee = (userLoaded) => (
  <div>
    <EmployeeHeader />
    <div className="h-min-screen bg-background pb-12">
      <Switch>
        <Route exact path="/" component={() => <Redirect to="/home" />} />
        <Route
          exact
          path="/home/:customerId?"
          render={(props) => <EmployHome {...props} user={userLoaded} />}
        />
        <Route
          exact
          path="/home/:customerId?/edit/:propertyId?"
          render={(props) => (
            <EmployPropertyProfile {...props} employ={userLoaded} />
          )}
        />
        <Route path="/scratch" component={Scratch} />
        <Route exact path="/initial/" component={Initial} />
        <Route
          // path="/check/"
          exact
          path="/home/:customerId?/:appointmentId"
          render={(props) => <PropertyCheck {...props} user={userLoaded} />}
        />
        <Route
          // path="/check/"
          exact
          path="/home/:customerId?/:appointmentId/confirmation"
          render={(props) => (
            <PropertyCheckConfirmation {...props} user={userLoaded} />
          )}
        />
        <Route path="/initial/confirmation" component={InitialConfirmation} />
      </Switch>
    </div>
  </div>
);

const stripePromise = loadStripe(
  "pk_test_51J4sx6LoI3AxvBHdVAsECc8dWakVtznOF1pUO7joaJMfWI4YwlBcACkqksRoJFHX4Ylv0ie06dPEYvrWVf8BwLts00srtut1mY"
);

function App() {
  const { isAuthenticated, isLoading, user, error, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [userLoaded, setUserLoaded] = React.useState(null);
  const [showHeader, setShowHeader] = useState(true);

  const history = useHistory();


  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname.includes("report")) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    });
  }, [history]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }

    if (user) {
      const getUserMetadata = async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            audience: 'https://propertywatchers.com/api/v1/property',
            scope: "read:current_user",
          });

          // store access token in local storage 
          localStorage.setItem('accessToken', accessToken);
          console.log("Stored data in storage")
          loadUser(user, accessToken)
        } catch (e) {
         
          console.log(e)
          console.log(user)
          console.log(e.message);
        }
      };
    
      getUserMetadata();
    }
  }, [user, getAccessTokenSilently]);

  const loadUser = (user, accessToken) => {
    PropertyDataService.fetchUser({ email: user.email }, accessToken)
      .then((response) => {
        // console.log("Fetching User Information");
        
        setUserLoaded(response.data.user);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      {isAuthenticated ? (
        !isLoading && userLoaded ? (
          userLoaded && userLoaded.type === "client" ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret:
                  (userLoaded.payment &&
                    userLoaded.payment.setupIntent.client_secret) ||
                  "",
              }}
            >
              <Authenticated userLoaded={userLoaded} showHeader={showHeader} />
            </Elements>
          ) : userLoaded && userLoaded.type === "employ" ? (
            employee(userLoaded)
          ) : (
            <Unauthenticated userLoaded={user} />
          )
        ) : (
          loading
        )
      ) : !isLoading ? (
        <Unauthenticated userLoaded={user} />
      ) : (
        loading
      )}
    </div>
  );
}

export default App;
