import React from "react";
import logo from "../../assets/logo-white.png";
import LogoutButton from "../LogoutButton/LogoutButton";

const EmployeeHeader = (props) => {
  let getUrl = window.location;
  let home = getUrl.protocol + "//" + getUrl.host + "/";

  return (
    <nav className="sticky top-0 z-50 px-2 py-3 bg-gradient-to-b max-w-full from-red to-red-grad h-20">
      <div className="flex flex-row justify-center sm:justify-start max-w-full">
        <div className="sm:w-1/3 flex justify-center">
          <a href={home}>
            <img src={logo} alt={"logo"} className="h-14 relative" />
          </a>
        </div>

        <div className="hidden w-1/3 sm:flex justify-center items-center">
          <p className="text-xl font-bold text-white">Employee Home</p>
        </div>

        <div className="sm:w-1/3 flex justify-center">
          <div className="my-auto mx-10 sm:mx-0">
            <LogoutButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default EmployeeHeader;
