import { useState, useEffect } from "react";
import { PlusCircleIcon, HomeIcon } from "@heroicons/react/outline";
import Images from "../Images/Images";

const SchedulerNotes = (props) => {
  const { saveNote, setReadyToSave } = props;
  const [note, setNote] = useState("");
  const [noteDetail, setNoteDetail] = useState("");
  const [taskImages, setTaskImages] = useState([])

  const makeChange = (change, type) => {
    switch (type) {
      case "title":
        setNote(change);
        saveNote({ task: change, taskNote: noteDetail, images: taskImages });
        break;
      case "detail":
        setNoteDetail(change);
        saveNote({ task: note, taskNote: change, images: taskImages });
        break;
      case "images":
        setTaskImages(change);
        saveNote({ task: note, taskNote: noteDetail, images: change})
        break;
    }
  };

  return (
    <div>
      <p className="text-xl font-bold font-inter mb-2"></p>
      <textarea
        rows={2}
        cols={5}
        className="p-2 tracking-wide w-5/6 font-medium rounded-md text-lg resize-none border-solid border-2 border-gray-400"
        onChange={(e) => makeChange(e.target.value, "title")}
        placeholder="Note Subject"
        value={note}
      />
      <textarea
        rows={3}
        cols={5}
        className="p-2 tracking-wide font-medium w-5/6 rounded-md text-lg resize-none border-solid border-2 shadow-inner border-gray-400"
        onChange={(e) => makeChange(e.target.value, "detail")}
        placeholder="Details (optional)"
        value={noteDetail}
      />
    <Images
              setUploadedImages={(val) => makeChange(val, "images")}
              changeReadiness={setReadyToSave}
              presetImages={taskImages}
              editable={true}
            />
    </div>
  );
};

export default SchedulerNotes;
