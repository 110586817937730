import Clock from 'react-live-clock';
import FormatService from "../../services/format"

const StickyDetails = (props) => {
    const {customer} = props;
    const {name, phone, email} = customer;
    return <>
        <div className="sticky top-20 z-50 min-w-full">
            <div className="flex flex-row justify-center bg-blue-100  sm:px-4 pt-4 ">

                <div className="hidden sm:flex flex-col space-y-4">
                    <p className="font-medium text-gray-600 text-xl">Customer Name:</p>
                </div>
                <div className="flex flex-col space-y-4 ml-5">
                    <p className="font-bold sm:text-xl text-sm">{name||"customerName"}</p>
                </div>
                <div className="hidden sm:flex flex-col space-y-4 ml-5">

                    <p className="font-medium text-gray-600 text-xl">Phone:</p>
                </div>
                <div className="flex flex-col space-y-4 ml-5">

                    <p className="font-bold sm:text-xl text-sm">{phone && FormatService.formatPhoneNumber(phone) || "customerPhone"}</p>
                </div>
                <div className="hidden sm:flex flex-col space-y-4 ml-5">
                    <p className="font-medium text-gray-600 text-xl">Email Contact:</p>

                </div>
                <div className="flex flex-col space-y-4 ml-5">
                    <p className="font-bold sm:text-xl text-sm">{email || "customerEmail"}</p>

                </div>

            </div>
            <div className="flex flex-row justify-center bg-blue-100  px-4 py-4 ">

                <div className="hidden sm:flex flex-col space-y-4">
                    <p className="font-medium text-gray-600 text-xl">Current Time:</p>
                </div>
                <div className="flex flex-col space-y-4 ml-5">
                    <Clock format={'h:mm A, dddd, MMMM Mo, YYYY'} ticking={true} className="font-bold  sm:text-xl" timezone={'US/Pacific'} />
                </div>


            </div>
        </div>
    </>

}

export default StickyDetails;