import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';


const Question = (props) => {
    const {title} = props;
    return <div className={props.containerStyle}>
        <div className="flex flex-col rounded-lg border-2 border-blue-400 p-3">
            <div className='flex flex-row space-x-2 items-center'>
                <QuestionMarkCircleIcon className="h-6 w-6 text-blue-500" />
                <p className="font-medium text-blue-500 text-lg">{title}</p>
            </div>
            <div className="flex flex-col ml-4 mt-2 space-y-1">
                {props.children}
            </div>
        </div>
    </div>

}

export default Question;