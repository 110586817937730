import React, { useEffect, useState } from "react";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import FormatService from "../../services/format";
import { useParams, Link, useLocation, useHistory } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import PropertyDataService from "../../services/property";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

{
  /* <button className="w-full" onClick={() => goToReport()}>
<div className={(oddBg ? "sm:bg-gray-100" : "") + " flex flex-col sm:flex-row  hover:bg-gray-200 border-solid border-2 border-gray-200 sm:border-0 p-1 sm:p-0 sm:pt-4"}>

  <div className="sm:w-1/4 flex justify-start text-dark font-inter border-solid border-gray-400 sm:border-b-2 items-center">
    {!visited ? <div className="rounded-full bg-blue-500 w-3 h-3 mr-3"></div> : null}
    <p className="sm:my-1">
      {FormatService.getFormatedAppointment(report.scheduledDateTime)}
    </p>
  </div>

  <div className="sm:w-1/4  flex justify-start text-dark font-inter border-solid border-gray-400 sm:border-b-2 ">
    <p className="m-1 ">{report.type}</p>
  </div>

  <div className="sm:w-1/4  flex justify-start text-dark font-inter border-solid border-gray-400 sm:border-b-2 ">
    <p className="m-1 ">{report.completedBy}</p>
  </div>

  <div className="sm:w-1/4 flex sm:justify-center text-dark font-inter border-solid border-gray-400 sm:border-b-2 ">
    <p className="m-1">
      {tasksComplete ? (
        <CheckCircleIcon className="h-7 text-green-500" />
      ) : (
        <ExclamationCircleIcon className="h-7 text-yellow-300" />
      )}
    </p>
  </div>
</div>
</button> */
}

const TitleRow = (props) => {
  const { changeDir } = props;
  const [dir, setDir] = useState(true);

  const changeVal = (e) => {
    setDir(e);
    console.log(e);
    changeDir(e);
  };

  return (
    <div className="invisible sm:visible flex flex-row ">
      <div className="w-1/4 sm:text-lg text-blue-500 font-semibold font-inter border-solid border-gray-400  ">
        <button
          className="flex flex-row focus:outline-none hover:underline focus:font-semibold"
          onClick={() => changeVal(!dir)}
        >
          <p className="m-1">Date</p>
          {dir ? (
            <ChevronDownIcon className={"h-8"} />
          ) : (
            <ChevronUpIcon className="h-8" />
          )}
        </button>
      </div>
      <div className="w-1/4 sm:text-lg text-blue-500 font-semibold font-inter  ">
        <p className="m-1 ">Amount</p>
      </div>

      <div className="w-1/4 sm:text-lg text-blue-500 font-semibold font-inter border-solid border-gray-400  ">
        <p className="m-1 ">Address</p>
      </div>

      <div className="flex flex-row justify-around w-1/4 sm:text-lg text-blue-500 font-semibold font-inter border-solid border-gray-400  ">
        <div>
          <p className="m-1 ">Receipt</p>
        </div>

        <div>
          <p className="m-1 ">Report</p>
        </div>
      </div>
    </div>
  );
};

const ItemRow = ({ charge, unviewed, id, dateTime, property }) => {
  const markAsViewed = () => {
    const data = {
      userId: id,
      propId: charge.data.propertyId,
      apptId: charge.data.appointmentId,
    };

    PropertyDataService.markView(data)
      .then((response) => {
        // console.log("marked as Viewed");
        // window.location.reload()
        // hard reload so we can reflect the difference of the viewed report
        window.location.assign(
          `${window.location.origin}/reports/${charge.data.propertyId}/${charge.data.appointmentId}`
        );
        return "success";
      })
      .catch((e) => {
        console.log(e);
        return "error";
      });
  };

  const history = useHistory();

  const goToReport = () => {
    if (unviewed) {
      markAsViewed();
    } else {
      // soft reroute so no reload and refreshing of data
      history.push({
        pathname: `/reports/${charge.data.propertyId}/${charge.data.appointmentId}`,
      });
    }
  };
  // console.log("property", property);

  return (
    <div className="w-full">
      <div
        className={
          "flex flex-col sm:flex-row rounded-lg hover:bg-gray-100 border-solid border-2 border-gray-200 p-1 sm:p-2 mb-3"
        }
      >
        <div className="sm:w-1/4 flex justify-center sm:justify-start text-dark font-inter border-solid border-gray-400  items-center">
          {/* {!visited ? <div className="rounded-full bg-blue-500 w-3 h-3 mr-3"></div> : null} */}
          <p className="sm:my-1 text-sm sm:text-base  whitespace-nowrap overflow-ellipsis">
            {FormatService.getFormatedAppointment(dateTime)}
          </p>
        </div>

        <div className="sm:hidden flex justify-center text-dark font-inter border-solid border-gray-400  items-center">
          {/* {!visited ? <div className="rounded-full bg-blue-500 w-3 h-3 mr-3"></div> : null} */}
          <p className="sm:my-1 text-sm sm:text-base  whitespace-nowrap">
            {FormatService.formatAddress(property)}
          </p>
        </div>

        <div className="sm:w-1/4  flex justify-center sm:justify-start text-dark font-inter border-solid border-gray-400 ">
          <p className="m-1 ml-0 text-lg font-bold sm:font-semibold ">
            ${(charge && charge.amount / 100) || ""}{' '}
            <span className="font-light text-sm">on</span>{' '}
            <span className="text-sm ">{charge.descriptionType}</span>{' '}
            <span className="font-light text-sm"> ending in</span>{' '}
            <span className="shadow-sm p-1 bg-gray-100 text-dark tracking-wide font-semibold text-sm ">{charge.descriptionNumber}</span>
          </p>
        </div>

        <div className="hidden sm:flex sm:w-1/4  justify-start text-dark font-inter border-solid border-gray-400  items-center">
          {/* {!visited ? <div className="rounded-full bg-blue-500 w-3 h-3 mr-3"></div> : null} */}
          <p className="sm:my-1 text-sm sm:text-base  whitespace-nowrap overflow-hidden">
            {FormatService.formatAddress(property)}
          </p>
        </div>

        <div className="sm:w-1/4 items-center flex flex-row justify-center sm:justify-around">
          <div className="flex justify-start text-dark font-inter border-solid border-gray-400 items-center ">
            <p className="sm:hidden text-xs sm:text-lg  whitespace-nowrap sm:m-1 mr-2 ">
              Receipt:
            </p>
            <a
              target="_blank"
              className="text-xs sm:text-lg  whitespace-nowrap sm:m-1 underline text-blue-600"
              href={(charge && charge.receipt) || ""}
            >
              Stripe.com
            </a>

            <p className="sm:hidden text-xs sm:text-lg  whitespace-nowrap sm:m-1 ml-4 mr-2 ">
              Report:
            </p>
            <button
              onClick={() => goToReport()}
              className="sm:hidden text-xs sm:text-lg  whitespace-nowrap sm:m-1 underline text-blue-600"
            >
              Report
            </button>
          </div>

          <div className="hidden sm:flex sm:justify-center text-dark font-inter border-solid border-gray-400  ">
            <button
              onClick={() => goToReport()}
              className="py-2 px-5 bg-white hover:bg-blue-100 rounded-md text-xl text-blue-500 font-semibold flex flex-row items-center"
            >
              Report
              <ArrowCircleRightIcon className="h-6 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BillingList = (props) => {
  const { charges, user } = props;
  const [filterDirection, setFilterDirection] = useState(true);

  return (
    <div className="mx-10 ">
      <TitleRow changeDir={setFilterDirection} />
      <div
        className={
          (filterDirection ? "flex-col-reverse" : "flex-col") + " flex"
        }
      >
        {charges.map((val, index) => {
          let unviewed = false;
          let dateTime;
          let property;
          for (let i in user.property) {
            if (val.data.propertyId === user.property[i]._id) {
              // console.log("found property")
              property = user.property[i];
              if (user.property[i].unviewed.includes(val.data.appointmentId)) {
                unviewed = true;
              }
              for (let j in user.property[i].reports) {
                // console.log("found report")
                if (
                  val.data.appointmentId === user.property[i].reports[j].apptId
                ) {
                  dateTime = user.property[i].reports[j].scheduledDateTime;
                }
              }
            }
          }
          return (
            <ItemRow
              charge={val}
              unviewed={unviewed}
              id={user._id}
              dateTime={dateTime}
              property={property}
            />
          );
        })}
      </div>
    </div>
  );
};
export default BillingList;
