import React, { useState, useEffect } from "react";
import { ChevronUpIcon, SearchIcon } from '@heroicons/react/solid'
import FormatService from "../../services/format"
import { useParams, Link, useLocation, useHistory } from "react-router-dom";

const CustomerSearch = (props) => {

    const { customers, setSelected } = props;

    const [active, setActive] = React.useState("");
    const [viewable, setViewable] = React.useState(customers);

    const history = useHistory();

    const onSelect = (data) => {
        setActive(data)
        setSelected(data)
        // console.log("setting", data)
        history.push({ pathname: `/home/${data}` });
    }

    const tableRow = (data, onSelect) => <a onClick={() => onSelect(data._id)}>
    <div className={active === data._id ? "bg-gray-200 flex cursor-pointer flex-row rounded shadow-md my-2 px-3 py-2" : "bg-white flex cursor-pointer flex-row rounded shadow-md my-2 px-3 py-2"}>
        <p className="w-1/3 text-md font-medium text-gray-500">{data.name}</p>
        <p className="w-1/3 text-md font-medium text-gray-500">{FormatService.formatPhoneNumber(data.phone)}</p>
        <p className="w-1/3 text-md font-medium text-gray-500 overflow-hidden">{data.email}</p>
    </div>
</a>

    const onSearch = (e) => {
        let curString = e.target.value.toLowerCase();
        let curViewable = [];
        for (let i in customers) {
            let cur = customers[i];
            if (cur.name.toLowerCase().includes(curString) || cur.phone.toLowerCase().includes(curString) || cur.email.toLowerCase().includes(curString)) {
                curViewable.push(cur);
            }
        }
        setViewable(curViewable)
    };

    return (
        <div className="flex flex-col justify-center">
            <div className="bg-white flex items-center rounded-full shadow-xl">
                <input
                    className="rounded-l-full w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none font-medium text-lg ml-10"
                    id="search" type="text" placeholder="search by name, email, or phone"
                    onChange={e => onSearch(e)}
                >
                </input>
                <div className="p-4">
                    <button
                        className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-400 focus:outline-none w-9 h-9 flex items-center justify-center">
                        <SearchIcon />
                    </button>
                </div>

            </div>

            <div className="mt-6">
                <div className="flex flex-row text-center">
                    <p className="w-1/3 text-lg font-bold text-dark">Name</p>
                    <p className="w-1/3 text-lg font-bold text-dark">Phone</p>
                    <p className="w-1/3 text-lg font-bold text-dark">Email</p>
                </div>
                {viewable.map((q, index, active) =>
                    index < 5 ? tableRow(q, onSelect) : null
                )}

            </div>

        </div>
    )
};

export default CustomerSearch;