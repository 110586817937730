import ReactPlayer from "react-player";
import appointment from "../../assets/appointment.mp4";
import billing from "../../assets/billing.mp4";

const PreviewRow = ({video, title, desc}) => {

    return (
        <div className="flex flex-col lg:flex-row mb-10 font-inter">
        {/* Left/Top */}
        <div className="lg:w-1/2">
            <p className="font-semibold text-lg">{title}</p>
            <div>
                <p className="font-light text-md">{desc}</p>
            </div>
        </div>
        <div className="lg:ml-5 flex justify-center items-center">
          <ReactPlayer
            url={video}
            playing={true}
            controls={false}
            loop={true}
            width="auto"
          />
        </div>
      </div>
    )
}

const PlatformPreview = () => {

    const apptTitle = "Booking Appointments"
    const apptDesc = "Book, edit and cancel appointments. You can update the tasks that you want your watcher to accomplish."
    const billDesc = "Recieve home reports instantly, with quick notifications if anything is wrong. You can also easily track billing of your visits and change information if needed."

  return (
    <div className="mt-10 lg:mt-16 mx-5 lg:mx-12">
      <p className="text-2xl font-bold mb-10">Platform Preview</p>

      {/* Row */}
      <PreviewRow video={appointment} title={apptTitle} desc={apptDesc} />

      <PreviewRow video={billing} title="Billing and Reports" desc={billDesc} />
     
    </div>
  );
};

export default PlatformPreview;
