import FormatService from "../../services/format";
import { ClipboardListIcon } from "@heroicons/react/outline";

const AppointmentDetails = (props) => {
  const { property, appointment } = props;
  const { dateTime, created, contact } = appointment;

  const labelStyle = "font-bold text-gray-500 sm:text-xl";
  const valStyle = "sm:text-xl";

  return (
    <>
      <div className="flex flex-row items-center ml-5">
        <ClipboardListIcon className="h-10 w-10" />
        <p className="font-bold text-dark text-xl ml-5 ">Details</p>
      </div>

      <div className="flex flex-col sm:mx-4 bg-gray-50 sm:bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg shadow-inner p-4  font-inter">
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <div className="flex flex-col space-y-4">
            <p className={labelStyle}>Address</p>
          </div>
          <div className="flex flex-col space-y-4">
            <p className={valStyle}>{FormatService.formatAddress(property)}</p>
          </div>
          <div className="flex flex-col space-y-4">
            <p className={labelStyle}>Appointment Time</p>
          </div>
          <div className="flex flex-col space-y-4">
            <p className={valStyle}>
              {FormatService.getFormatedAppointment(dateTime)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetails;
